export const environment = {
  production: false,
  configFile: 'assets/config/dev-config.json',
  auth0: {
    domain: 'dev-owox3a3azliixgz4.us.auth0.com',
    clientId: 'Iz7aLBvoReWFQgCBYRviHtH7nrVoUboO',
    audience: 'https://dev-api.trivita.com/auth0',
    scope: 'read:current_user openid profile email',
    logoutReturn: 'https://trivitabackup.com/'
  },
};
