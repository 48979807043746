import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { IHtmlTemplate } from '@models/html-template.model';
import { AffiliateService } from '@services/affiliate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-printed-material',
  templateUrl: './printed-material.component.html',
  styleUrls: ['./printed-material.component.scss'],
})
export class PrintedMaterialComponent implements OnInit {
  @Input() panelCollapsed!: boolean;

  public isLoading!: false;
  public materials: IHtmlTemplate[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getPrintedMaterials();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get printed materials from API using service
  private getPrintedMaterials(): void {
    this.affiliateService
      .getPrintedMaterials()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          this.materials = response;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }
}
