import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { ArpOrder, Product } from '@models/arp-order.model';

@Injectable()
export class ArpOrderService {
  private _availableProducts!: Product[];
  private _arpOrderOptions!: IntervalOptions;

  constructor(
    private httpService: HttpService,
    private authService: UserService
  ) {}

  get userToken(): string {
    return this.authService.user.token
  }

  // add or edit arp order
  addArpOrder(
    memberId: any,
    countryId: number,
    arpOrder: ArpOrder,
    previewOnly: boolean
  ): Observable<any> {
    arpOrder.memberId = +memberId;
    arpOrder.orderId = 0;
    arpOrder.countryId = countryId;
    arpOrder.previewOnly = previewOnly;
    if(arpOrder.payment){
      arpOrder = {...arpOrder, payment: {...arpOrder.payment, cardExpMonth: arpOrder.payment.cardExpMonth.toString()}}
    }
    return this.httpService.post(
      `api/arp/UpsertARPOrder?id=${memberId}`,
      'account',
      arpOrder,
      this.userToken
    );
  }

  // for cancelling an ARP order
  cancelArpOrder(memberId: string, orderId: number): Observable<any> {
    return this.httpService.put(
      `api/arp/deleteOrder?id=${memberId}&orderId=${orderId}`,
      'account',
      null,
      this.userToken
    );
  }

  // edit arp order
  editArpOrder(arpOrder: ArpOrder): Observable<any> {
    console.log(arpOrder);
    return this.httpService.post(
      `api/arp/UpsertARPOrder?id=${arpOrder.memberId}`,
      'account',
      {...arpOrder, payment: {...arpOrder.payment, cardExpMonth: arpOrder.payment.cardExpMonth.toString()}},
      this.userToken
    );
  }

  // get arp orders
  getArpOrders(memberId: any): Observable<{ arpOrders: ArpOrder[] }> {
    return this.httpService.get(`api/arp/getArpOrders?id=${memberId}`, 'account', this.userToken);
  }

  // get all available product from api
  getAvailableProducts(countryId: number): Observable<Product[]> {
    return this.httpService
      .get(`products/?countryId=${countryId}`, 'account')
      .pipe(
        map((products): Product[] => {
          products.forEach((productItem: Product) => {
            productItem.cartImage = productItem.cartImage.replace(
              '-sm.',
              '-lrg.'
            );
          });
          this.setAvailableProducts(products);
          return products;
        })
      );
  }

  // get all available product from variable
  get availableProducts(): Product[] {
    return this._availableProducts;
  }

  // get all available product from variable
  setAvailableProducts(products: Product[]): void {
    this._availableProducts = products;
  }

  // TODO: use this function in app instead hardcode
  // get options to defining frequency order
  setConfigurationOptions(): Observable<IntervalOptions> {
    return this.httpService.get(`api/arp/getarporderdisplayinfo`, 'account', this.authService.user.token).pipe(
      map((options): IntervalOptions => {
        this._arpOrderOptions = options;
        return options;
      })
    );
  }

  // set arp order options
  get arpOrderOptions(): IntervalOptions {
    return this._arpOrderOptions;
  }

  // for skipping an entire order
  skipArpOrder(memberId: string, orderId: number): Observable<any> {
    return this.httpService.put(
      `api/arp/${memberId}/skiparp/${orderId}`,
      'account',
      null,
      this.userToken
    );
  }

  // for skipping an item in an order
  skipItemInArpOrder(
    memberId: number,
    orderId: number,
    productId: number
  ): Observable<any> {
    return this.httpService.put(
      `api/arp/${memberId}/skiparp/${orderId}/product/${productId}`,
      'account',
      null,
      this.userToken
    );
  }
}

export interface IntervalOptions {
  1: string;
  2: string;
  3: string;
}
