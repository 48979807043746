import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ArpOrder } from '@models/arp-order.model';
import { Country } from '@models/country.model';
import { UserInfo } from '@models/user-info.model';
import { ArpOrderService } from '@services/arp-order.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-arp-order',
  templateUrl: './arp-order.component.html',
  styleUrls: ['./arp-order.component.scss'],
})
export class ArpOrderComponent {
  @Input() country!: Country;
  @Input() user!: UserInfo;
  @Input() orderList!: ArpOrder[];

  @Output() onToggleWizardDialog = new EventEmitter<boolean>();

  private skippingOrderId!: number | null;
  private cancelingOrderId!: number | null;

  public editArpOrderForm!: FormGroup | null;
  public editableOrder!: ArpOrder | null;
  public isCreditCardError = false;
  public isSkippingConfirmation = false;
  public isCancelingConfirmation = false;
  public isProductManagement = false;
  public isPaymentManagement = false;
  public orderEditingDialogIsOpen!: boolean;
  public productsListMode = 'view';
  public typeOfAddress!: string | null;
  public isLoading!: boolean;
  public spinnerText!: string;

  constructor(
    private arpOrderService: ArpOrderService,
    private fb: FormBuilder,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  public onAddOrderClick(): void {
    this.onToggleWizardDialog.emit(true);
  }

  /**
   * Cancel arp order by id
   * @param orderId - order id
   */
  public cancelOrder(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';

    // find index of canceled arp order
    const indexOfCanceledOrder = this.orderList.findIndex((item) => {
      return item.orderId === this.cancelingOrderId;
    });
    this.arpOrderService
      .cancelArpOrder(this.user.memberId, this.cancelingOrderId!)
      .subscribe({
        next: (response) => {
          this.orderList.splice(indexOfCanceledOrder, 1);
          this.wizardHelperService.setOrderList(this.orderList);
          this.isLoading = false;
          this.spinnerText = '';
          this.cancelingOrderId = null;
          this.isCancelingConfirmation = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Order was canceled',
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.spinnerText = '';
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }

  // close confirmation dialog
  public closeConfirmationDialog(): void {
    this.isSkippingConfirmation = false;
    this.isCancelingConfirmation = false;
    this.skippingOrderId = null;
    this.cancelingOrderId = null;
  }

  // show canceling confirmation dialog
  public isCancelingConfirmationShow(orderId: number): void {
    this.isCancelingConfirmation = true;
    this.cancelingOrderId = orderId;
  }

  // show skipping confirmation dialog
  public isSkippingConfirmationShow(orderId: number): void {
    this.isSkippingConfirmation = true;
    this.skippingOrderId = orderId;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  public openAddressManagement({ order, typeOfAddress }: any): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = order;
    this.typeOfAddress = typeOfAddress;
  }

  public openProductManagement(editingOrder: ArpOrder): void {
    this.typeOfAddress = null;
    this.isProductManagement = true;
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
  }

  /**
   * Open address management dialog
   * @param editingOrder - order object
   */
  public openPaymentManagement(editingOrder: ArpOrder): void {
    this.resetArpOrderEditing();
    this.orderEditingDialogIsOpen = true;
    this.editableOrder = editingOrder;
    this.isPaymentManagement = true;
  }

  /**
   * Open order options editing form
   * @param order - order object
   */
  public openEditOrderOptions(order: ArpOrder): void {
    this.editableOrder = order;
    this.productsListMode = 'edit';
    this.buildForm();
  }

  private buildForm(): void {
    this.editArpOrderForm = this.fb.group({
      memberId: this.editableOrder?.memberId,
      orderId: this.editableOrder?.orderId,
      countryId: this.editableOrder?.countryId,
      products: this.fb.array(
        this.editableOrder?.products?.map((product) => this.fb.group(product))!
      ),
      dayOfMonthToShip: this.editableOrder?.dayOfMonthToShip,
      interval: this.editableOrder?.interval,
      autoApplyVitaPoints: this.editableOrder?.autoApplyVitaPoints,
      shippingAddressId: this.editableOrder?.shippingAddress?.addressId,
      billingAddressId: this.editableOrder?.billingAddress?.addressId,
      payment: this.editableOrder?.payment,
      previewOnly: false,
    });
  }

  // update arp order
  public saveArpOrder(): void {
    this.isCreditCardError = false;
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.editArpOrder(this.editArpOrderForm?.value).subscribe({
      next: ({ arpOrders }: { arpOrders: ArpOrder[] }) => {
        this.sendGaEvent(arpOrders);
        this.wizardHelperService.setOrderList(arpOrders);
        this.resetArpOrderEditing();
        this.isLoading = false;
        this.spinnerText = '';
        this.messageService.add({
          severity: 'success',
          summary: 'Order was updated',
        });
      },
      error: (error: any) => {
        if (error === 'AEC0053') {
          this.isCreditCardError = true;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: error.error_description,
          });
        }
        this.isLoading = false;
        this.spinnerText = '';
      },
    });
  }

  /**
   * Send event to GA with updated order Estimated Total
   * @param arpOrders - updated arp orders list
   */
  private sendGaEvent(arpOrders: ArpOrder[]): void {
    // Count estimated total sum and send it to Google Analytics
    const updatedOrder: ArpOrder = arpOrders.find(
      (order) => order.orderId === this.editArpOrderForm?.value.orderId
    ) as ArpOrder;

    if (updatedOrder) {
      let esitmatedShipping = updatedOrder.estimatedShipping ?? 0;
      let estimatedTax = updatedOrder.estimatedTax ?? 0;
      let prodTotal = 0;
      if (updatedOrder.products) {
        prodTotal = updatedOrder?.products
          ?.map((product) => product.price * product.quantity)
          .reduce((prevValue, currValue) => prevValue + currValue);
        const total = esitmatedShipping + estimatedTax + prodTotal ?? 0;
      }
    }
  }

  // cancel editing arp order
  public resetArpOrderEditing(): void {
    this.editableOrder = null;
    this.editArpOrderForm = null;
    this.productsListMode = 'view';
  }

  // skip next order
  public skipNextOrder(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService
      .skipArpOrder(this.user.memberId, this.skippingOrderId as number)
      .subscribe({
        next: (response) => {
          this.arpOrderService.getArpOrders(this.user.memberId).subscribe({
            next: (orders) => {
              this.wizardHelperService.setOrderList(orders.arpOrders);
              this.isLoading = false;
              this.spinnerText = '';
              this.skippingOrderId = null;
              this.isSkippingConfirmation = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Next shipment date was changed',
              });
            },
            error: (error: HttpErrorResponse) => {
              this.isLoading = false;
              this.spinnerText = '';
              this.messageService.add({
                severity: 'error',
                summary: error.message,
              });
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.spinnerText = '';
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }

  // show success message after updating order
  public onProductListApply(order: ArpOrder): void {
    this.editArpOrderForm?.removeControl('products');
    this.editArpOrderForm?.addControl(
      'products',
      this.fb.array(order.products.map((product) => this.fb.group(product)))
    );
  }

  public showNotification({ error, message }: any): void {
    if (error) {
      this.messageService.add({ severity: 'error', summary: message });
    } else {
      this.messageService.add({ severity: 'success', summary: message });
    }
  }

  public onCloseDialog(): void {
    this.isPaymentManagement = false;
    this.orderEditingDialogIsOpen = false;
    this.isProductManagement = false;
  }
}
