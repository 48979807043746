import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, interval, Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';
import { HttpService } from './http.service';
import { AuthService } from '@auth0/auth0-angular';
import { UserInfo } from '@models/user-info.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserService {
  private userChangedSub: BehaviorSubject<UserInfo> = new BehaviorSubject(
    {} as UserInfo
  );
  public userChanged$ = this.userChangedSub.asObservable();

  private ocentureSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public firstStoreCreatedSubject$ = new Subject<boolean>();

  public user!: UserInfo;

  public isAuthenticated$ = this.auth.isAuthenticated$;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private httpService: HttpService,
    private auth: AuthService
  ) {
    localStorage.setItem('referral', document.referrer);
    this.getCurrentUser();
  }

  get ocentureStream(): Observable<UserInfo> {
    return this.ocentureSubject.asObservable();
  }

  logout() {
    localStorage.clear();
    this.setCurrentUser(null);
    localStorage.removeItem('user');
    //this.store.dispatch(clearStore());
    this.auth.logout({
      logoutParams: {
        returnTo: this.user?.billingAddress?.countryId === 1 ? environment.auth0.logoutReturnUS : environment.auth0.logoutReturnCA
      },
    });
  }

  getCurrentUser() {
    console.log(this.user);
    if (this.user) {
      return this.user;
    }

    let stringuser = localStorage.getItem('user');
    if (!stringuser || stringuser == '' || stringuser == 'undefined') {
      this.router.navigate(['login']);
    } else {
      let user = JSON.parse(stringuser);
      this.setCurrentUser(user);
    }
    return this.user;
  }

  setCurrentUser(user: UserInfo | null): void {
    if (user == null) return;
    this.user = JSON.parse(JSON.stringify(user));
    localStorage.setItem('user', JSON.stringify(this.user));
    this.userChangedSub.next(this.user);

    // Other service, like config service need to be ready before call api

    setTimeout(() => {
      this.setOcenture(this.user);
    }, 2000);

    if (this.isNewRegisteredUser()) {
      this.router.navigate(['/update-profile']);
    }
  }

  public isNewRegisteredUser(): boolean {
    if (!this.user?.shippingAddress?.postalCode) return true;
    if (!this.user?.billingAddress?.postalCode) return true;

    return false;
  }

  setOcenture(user: UserInfo): void {
    const token = this.user.token;
    if (!token) return;
    this.httpService
      .post(
        '/PMP/Status',
        'trivita_ocenture',
        { CustID: user?.memberId },
        token
      )
      .subscribe((response) => {
        console.log(response);
        localStorage.setItem('ocenture', JSON.stringify(response));
        this.ocentureSubject.next(response);
      });
  }

  acceptPNP(user: UserInfo) {
    const token = this.user.token;
    if (!token) return;

    return this.httpService.post(
      '/PMP/AcceptedPNP',
      'trivita_ocenture',
      { CustID: user?.memberId, Accepted: 2 },
      token
    );
  }

  getCurrentUserByToken(token: string): Observable<UserInfo> {
    return this.httpService.get(`profile/getprofile`, 'global', token).pipe(
      map((response) => {
        let responseParsed = JSON.parse(response);
        return responseParsed;
      })
    );
  }

  /**
   * Gets from API vita points quantity from profile info
   */
  updateUserInfo(): Observable<any> {
    const userToken = this.user.token;
    const memberId = this.getCurrentUser().memberId;
    return this.httpService.get(`profile/${memberId}`, 'cart', userToken);
  }

  public cancelPMPsubscription(): Observable<any> {
    return this.httpService.post(
      '/PMP/Cancel',
      'trivita_ocenture',
      { CustID: this.user?.memberId },
      this.user.token
    );
  }

  resetPassword() {}

  isAffiliate() {
    return this.user?.memberlevel >= 38 && this.user?.memberlevel <= 600;
  }
}
