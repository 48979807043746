import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Address, ArpOrder } from "@models/arp-order.model";
import { Country } from "@models/country.model";
import { WizardHelperService } from "@services/wizard-helper.service";
import { Subscription } from "rxjs";


@Component({
  selector: "app-add-edit-billing-address-step",
  templateUrl: "./add-edit-billing-address-step.component.html",
  styleUrls: ["./add-edit-billing-address-step.component.scss"],
})
export class AddEditBillingAddressStepComponent implements OnInit, OnDestroy {
  @Input() arpOrder!: ArpOrder;
  @Input() country!: Country;
  @Input() form!: FormGroup;
  @Input() user: any;

  @Output() onChangeStepTitle = new EventEmitter<string>();
  @Output() onClickNextBtn = new EventEmitter<string>();

  public editableAddress!: Address;
  public dialogContentMaxWidth = window.innerWidth - 300;

  private addressItems!: Address[];
  private sub!: Subscription;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event: any) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private wizardHelperService: WizardHelperService) {}

  ngOnInit() {
    this.getAddressEditing();
    this.getAddressList();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // get address list from service
  private getAddressList(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe(
      (addresses: Address[]) => {
        this.addressItems = addresses;
      }
    );
  }

  // get address editing from service
  private getAddressEditing(): void {
    this.editableAddress = this.wizardHelperService.addressEditing;
    this.editableAddress
      ? this.onChangeStepTitle.emit("Edit A Billing Address")
      : this.onChangeStepTitle.emit("Add A Billing Address");
  }

  /**
   * Finish adding or editing address process
   */
  public endAddEditAddress(): void {
    this.wizardHelperService.setAddressEditing(undefined);
    this.onClickNextBtn.emit("selectBillingAddress");
  }
}
