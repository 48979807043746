<div class="address-form-wrapper  no-padding container payment-mng-wrapp">
  <form *ngIf="form && form.controls['payment']" [formGroup]="form" class="container  no-padding">
    <div class="row w-100 d-flex justify-content-center" style="padding-top: 4px;" formArrayName="payment">
      <div class='form-group col-11'>
        <input pInputText
               class='form-control'
               type="text"
               formControlName="nameOnCard"
               placeholder="Name on Card">
      </div>

      <div class='form-group col-11 card-info-dropdown'
           [ngClass]="{ 'selected': getPaymentFormGroup().controls['cardType'].valid }">
        <p-dropdown
          placeholder="Select Card Type"
          [options]="creditCardsTypes"
          formControlName="cardType"
          (onChange)="onCardTypeChange($event)"
          [ngClass]="{'dropdown-placeholder': controlIsNotValid('cardType')}">
        </p-dropdown>
        <div *ngIf="showError('cardType')" class="error-message">
          Please select a card type.
        </div>
      </div>

      <div class='form-group col-11'>
        <input pInputText
               class='form-control'
               type="text"
               formControlName="cardNumber"
               placeholder="Credit Card Number">
        <div *ngIf="showError('cardNumber')" class="error-message">
          Please enter a valid credit card number ('{{cardNumberExample}}').
        </div>
      </div>
      <div class='form-group col-11 card-info-dropdown'
           [ngClass]="{ 'selected': getPaymentFormGroup().controls['cardExpMonth'].valid }">
        <p-dropdown
          placeholder="Month"
          [options]="months"
          formControlName="cardExpMonth"
          [ngClass]="{'dropdown-placeholder': controlIsNotValid('cardExpMonth')}">
        </p-dropdown>
        <div *ngIf="showError('cardExpMonth')" class="error-message">
          Please select the expiration month of the credit card.
        </div>
      </div>
      <div class='form-group col-11 card-info-dropdown'
           [ngClass]="{ 'selected': getPaymentFormGroup().controls['cardExpYear'].valid }">
        <p-dropdown
          placeholder="Year"
          [options]="years"
          formControlName="cardExpYear"
          [ngClass]="{'dropdown-placeholder': controlIsNotValid('cardExpYear')}">
        </p-dropdown>
        <div *ngIf="showError('cardExpYear')" class="error-message">
          Please select the expiration year of the credit card.
        </div>
      </div>

      <div class='row no-padding form-group col-11'>
        <div class='form-group col-6'>
          <input pInputText
                 type="text"
                 class='form-control'
                 placeholder="CVV"
                 formControlName="cardCvvCode">
          <div *ngIf="showError('cardCvvCode')" class="error-message">
            Please enter a properly formatted CCV code.
          </div>
        </div>
        <div class='form-group col-6 app-cvv-help-wrapper text-center'>
          <app-cvv-help></app-cvv-help>
        </div>
      </div>

      <div class="row w-100 col-11 no-padding address-form-btn-block">
        <div class='col-md-6 col-12'>
          <button
            class="cancel-btn pointer"
            (click)="backToPreviousStep()">
            <span *ngIf="!isPaymentManagement">Back</span>
            <span *ngIf="isPaymentManagement">Cancel</span>
          </button>
        </div>
        <div class='col-md-6 col-12'>
          <button
            class="submit-btn bg-color pointer"
            [disabled]="getPaymentFormGroup().invalid"
            (click)="onSubmit()">
            <span *ngIf="!isPaymentManagement">Next</span>
            <span *ngIf="isPaymentManagement">Save</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
