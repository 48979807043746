<p-dialog
  *ngIf="viewOrder"
  [header]="dialogTitle"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [(visible)]="visible"
  width="900"
  [blockScroll]="true"
  (onHide)="closeDialog()"
>
  <div
    class="content"
    #content
    [ngStyle]="{
      height: dialogContentMaxHeight + 'px',
      'overflow-y': 'auto',
      'overflow-x': 'hidden'
    }"
  >
    <div *ngIf="dialogTitle === 'Your Order'" class="container">
      <div class="row w-100">
        <div class="col-12 row block-item">
          <p class="title-block col-12">Order info</p>
          <div class="col-6 text-left">Order: {{ viewOrder.orderId }}</div>
          <div class="col-6 text-left">Account: {{ viewOrder.memberId }}</div>
          <div class="col-12 text-left">
            Name: {{ viewOrder?.firstName }} {{ viewOrder.lastname }}
          </div>
          <div class="col-12 text-left">
            Date: {{ viewOrder.orderDate | date }}
          </div>
        </div>

        <div class="col-6 row block-item">
          <p class="title-block col-12">Bill To:</p>
          <div class="col-12 text-left">
            {{ viewOrder.billingAddress.addressName }}
          </div>
          <div class="col-12 text-left">
            {{ viewOrder.billingAddress.address1 }}
          </div>
          <div
            *ngIf="viewOrder.billingAddress.address2"
            class="col-12 text-left"
          >
            {{ viewOrder.billingAddress.address2 }}
          </div>
          <div class="col-12 text-left">
            {{ viewOrder.billingAddress.city }} ,
            {{ viewOrder.billingAddress.state | stateName : country.states }} ,
            {{ viewOrder.billingAddress.postalCode }}
          </div>
          <div class="col-12 text-left">{{ country.displayName }}</div>
        </div>

        <div class="col-6 row block-item">
          <p class="title-block col-12">Ship To:</p>
          <div class="col-12 text-left">
            {{ viewOrder.shippingAddress.addressName }}
          </div>
          <div class="col-12 text-left">
            {{ viewOrder.shippingAddress.address1 }}
          </div>
          <div
            *ngIf="viewOrder.shippingAddress.address2"
            class="col-12 text-left"
          >
            {{ viewOrder.shippingAddress.address2 }}
          </div>
          <div class="col-12 text-left">
            {{ viewOrder.shippingAddress.city }} ,
            {{ viewOrder.shippingAddress.state | stateName : country.states }} ,
            {{ viewOrder.shippingAddress.postalCode }}
          </div>
          <div class="col-12 text-left">{{ country.displayName }}</div>
        </div>

        <!-- products table -->
        <div
          *ngIf="viewOrder.products && viewOrder.products.length"
          class="product-list-wrapper"
        >


          <!-- <p-dataTable [value]="viewOrder.products" [responsive]="true">
            <p-column
              field="itemNumber"
              header="Item #"
              [style]="{ width: '25%' }"
            ></p-column>
            <p-column
              field="productName"
              header="Products"
              [style]="{ width: '30%' }"
            ></p-column>
            <p-column field="price" header="Price"></p-column>
            <p-column field="quantity" header="Quantity"></p-column>
            <p-column header="Total">
              <ng-template
                let-product="rowData"
                let-i="rowIndex"
                pTemplate="body"
              >
                <div>
                  {{
                    product.quantity * product.price | currency : "USD" : true
                  }}
                </div>
              </ng-template>
            </p-column>
          </p-dataTable> -->
        </div>

        <!-- subtotal line -->
        <div class="row w-100 block-item subtotal-line">
          <div class="col-md-7">
            <div class="row w-100" >
              <div class="col-1"></div>
              <div class="col-6 title-block">Product</div>
              <div class="col-1 title-block">Qty</div>
              <div class="col-4 title-block text-right">Amount</div>
              <ng-container *ngFor="let product of viewOrder?.products">
                <div class="col-1 text-left">
                  <img
                    style="max-width: 32px"
                    src="{{ product.cartImage }}"
                    alt=""
                  />
                </div>
                <div class="col-6 text-left">{{ product.productName }}</div>
                <div class="col-1 text-center">{{ product.quantity }}</div>
                <div class="col-4 text-right">
                  {{ product.price | currency : "USD" : true }}
                </div>

              </ng-container>

            </div>
            <div class="row w-100">
              <div class="col-8 text-right">Subtotal:</div>
              <div class="col-4 text-right">
                {{ subtotal | currency : "USD" : true }}
              </div>
            </div>

            <div class="row w-100">
              <div class="col-8 text-right">Tax:</div>
              <div class="col-4 text-right">
                {{ viewOrder.tax | currency : "USD" : true }}
              </div>
            </div>

            <div class="row w-100">
              <div class="col-8 total-line text-right">Total:</div>
              <div class="col-4 total-line text-right">
                {{ subtotal + viewOrder.tax | currency : "USD" : true }}
              </div>
            </div>
          </div>

          <div class="col-md-5 text-left ">
            <div class="row w-100 payment-details">
              <div class="col-9 text-left row">
                <p class="title-block col-12">Payments</p>
                <div
                  *ngFor="let payment of viewOrder.payments"
                  class="col-11 payment-item"
                >
                  <div>{{ payment.paymentDescription }}</div>
                </div>
              </div>
              <div class="col-3 text-left row">
                <p class="title-block col-12">Amount</p>
                <div
                  *ngFor="let payment of viewOrder.payments"
                  class="col-11 payment-item"
                >
                  <div>
                    {{ payment.paymentAmount | currency : "USD" : true }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- tracking dialog -->
    <div *ngIf="dialogTitle === 'Package Tracking'" class="container">
      <div
        *ngFor="let packageItem of viewOrder.packages; let i = index"
        class="row w-100 block-item-package"
      >
        <div class="col-md-12 title-block">Package {{ i + 1 }}</div>
        <div class="col-md-5 col-12 margin-bottom">
          Provider: {{ packageItem.provider }}
        </div>
        <div class="col-md-7 col-12 margin-bottom">
          Tracking Number: {{ packageItem.packageNumber }}
        </div>
        <div class="col-3">
          <a
            [href]="packageItem.trackingURL"
            target="_blank"
            (click)="onTrackPackageClick()"
          >
            <div class="select-btn bg-color pointer margin-bottom">
              Track Package
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</p-dialog>

<div class="order-panel-wrapper">
  <p-panel
    class="order-table"
    id="order-history"
    header="Order History"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    (onAfterToggle)="onPanelToggle($event)"
    [toggler]="'header'"

  >
    <div class="row w-100 panel-block">
      <div class="col-xs-12 col-sm-2 panel-label">Orders</div>
      <div class="col-xs-12 col-sm-9 row">
        <div class="col-xs-12 col-sm-4 calendar-input-wrapper">
          <p-calendar
            [(ngModel)]="paymentsDatesRange[0]"
            dateFormat="M d, yy"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="calendarInputYearRange"
            (onSelect)="filter(paymentsDatesRange)"
          >
          </p-calendar>
        </div>
        <div class="col-xs-12 col-sm-4 calendar-input-wrapper">
          <p-calendar
            [(ngModel)]="paymentsDatesRange[1]"
            dateFormat="M d, yy"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="calendarInputYearRange"
            (onSelect)="filter(paymentsDatesRange)"
          >
          </p-calendar>
        </div>
      </div>
    </div>
    <!-- table -->
    <div class="orders__table" *ngIf="orderItems && filteredOrders">
      <p-table
        [value]="filteredOrders"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50, 75, 100]"
        [rows]="10"
        [rowHover]="true"
        [paginator]="true"
        [filterDelay]="0"
        [loading]="isLoading"
        [responsive]="true"

      >
        <ng-template pTemplate="header">
          <tr>
            <th class="order-header">ORDER#</th>
            <th class="order-header">DATE</th>
            <th class="order-header">TOTAL</th>
            <th class="order-header">STATUS</th>
            <th class="order-header">SHIPPING</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>{{ order.orderId }}</td>
            <td>{{ order.orderDate }}</td>
            <td>{{ order.orderTotal }}</td>
            <td>{{ order.orderStatus || "-" }}</td>
            <td *ngIf="order.packages[0].provider === 'Interoffice'">
              {{ order.packages[0].provider }}
            </td>
            <td *ngIf="order.packages[0].provider !== 'Interoffice'">
              <a
                target="_blank"
                rel="noopener noreferrer"
                [href]="order.packages[0].trackingURL"
                >{{ order.packages[0].provider }}</a
              >
            </td>
            <td>
              <div class="actions">
                <a
                  class="text-color dialog-link pull-right pointer"
                  (click)="openViewOrderDialog(order)"
                >
                  <div class="col-sm-1 pointer"></div>
                  View
                </a>
                <a
                  *ngIf="order.productQuantityString"
                  class="text-color dialog-link pull-right pointer"
                  (click)="onBuyAgainClick(order)"
                >
                  <div class="col-sm-1 pointer"></div>
                  Buy Again
                </a>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
