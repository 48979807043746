import { MainGuard } from "./main.guard";
import { Routes } from "@angular/router";

import { MainComponent } from "./main/main.component";
import { SubscriptionGuard } from "./subscription.guard";
import { AccountManagmentComponent } from "@modules/account-managment/account-managment.component";
import { SubscriptionComponent } from "@modules/account-managment/subscription/subscription.component";
import { AffiliateOfficeComponent } from "@modules/affiliate-office/affiliate-office.component";


export const mainRoutes: Routes = [
  {
    path: "main",
    component: MainComponent,
    canActivate: [MainGuard],
    children: [
      {
        path: "",
        redirectTo: "account-management",
        pathMatch: "full",
      },
      {
        path: "account-management",
        component: AccountManagmentComponent,
      },
      {
        path: "subscription",
        component: SubscriptionComponent,
        canActivate: [SubscriptionGuard],
        runGuardsAndResolvers: "always",
      },
      {
        path: "affiliate-office",
        component: AffiliateOfficeComponent,
      },
    ],
  },
];
