<div class="panel-wrapper" *ngIf="tutorials?.length">
  <p-panel
    header="Tutorials"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
  >
    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let tutorial of tutorials">
        <div class="tutorial-block">
          <h5 class="title">{{ tutorial.Title }}</h5>
          <div [innerHTML]="tutorial.Html | safeHtml"></div>
        </div>
      </div>
    </div>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
