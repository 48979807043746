import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MyAccountQueryParams} from '../models/my-account-query-params.model';


@Injectable()
export class StoreParamsService {
  private params!: MyAccountQueryParams;

  constructor(private route: ActivatedRoute) {
  }

  getQueryParameterByName(name: string): string{
    const url = window.location.href;
    name = name.replace(/[[]]/g, '\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);

    if (!results) {
      let value = localStorage.getItem(name);
      if(value){
        return value;
      }else{
        return "";
      }
    }

    if (!results[2]) {
      return "";
    }

    const value: string = decodeURIComponent(results[2].replace(/\+/g, ' '));
    this.setQueryParameterByName(name, value);
    return value;
  }

  setQueryParameterByName(name: string, value: string): void {
    localStorage.setItem(name, value);
  }
}

