import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { mainRoutes } from './main.routes';
import { SharedModule } from '@modules/shared/shared.module';
import { AccountManagmentModule } from '@modules/account-managment/account-managment.module';
import { SubscriptionGuard } from './subscription.guard';
import { MainGuard } from './main.guard';
import { AffiliateOfficeModule } from '@modules/affiliate-office/affiliate-office.module';



@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    AccountManagmentModule,
    AffiliateOfficeModule,
    CommonModule,
    RouterModule.forChild(mainRoutes),
    //TextMaskModule,
    SharedModule,
  ],
  providers: [MainGuard, SubscriptionGuard],
})
export class MainModule { }
