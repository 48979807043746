import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { IMediaLibraryVideo } from '@models/media-library-video.model';
import { AffiliateService } from '@services/affiliate.service';
import { UserService } from '@services/user.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
})
export class MediaLibraryComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed!: boolean;
  @Output() panelToggled = new EventEmitter();

  private destroy$ = new Subject<void>();

  public memberId!: string;
  public dialogsVisibility = {
    emailDialogIsVisible: false,
    embedCodeDialogIsVisible: false,
    viewBannerDialogVisible: false,
  } as any;
  public emailDialogVideo!: IMediaLibraryVideo | null;
  public embedVideoCode!: string;
  public isLoading!: false;
  public videos: IMediaLibraryVideo[] = [];

  constructor(
    private affiliateService: AffiliateService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.memberId = this.userService.getCurrentUser().memberId;
    this.getVideos();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get videos from API using service
  private getVideos(): void {
    this.affiliateService
      .getVideos(this.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (videos) => {
          this.videos = videos;
        },
        error: (error: HttpErrorResponse) => {
          console.log(error)
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }

  public onMessageSent({ severity, summary }: any): void {
    this.messageService.add({
      severity,
      summary,
    });
  }

  /**
   * Sets dialogs visibility to false
   * @param dialogVisibilityProperty
   */
  public onDialogHide(dialogVisibilityProperty: string): void {
    this.dialogsVisibility[dialogVisibilityProperty] = false;

    this.emailDialogVideo = null;
    this.embedVideoCode = '';
  }

  /**
   * Parse embed code string and open embed code dialog
   */
  public openEmbedVideoCodeDialog(videoUrl: string): void {
    this.embedVideoCode = `<iframe src="${videoUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;
    this.dialogsVisibility.embedCodeDialogIsVisible = true;
  }

  /**
   * Open email video message dialog
   */
  public openEmailVideoMessageDialog(video: IMediaLibraryVideo): void {
    this.emailDialogVideo = JSON.parse(JSON.stringify(video));
    this.dialogsVisibility.emailDialogIsVisible = true;
  }
}
