<app-spinner
  *ngIf="isLoading"
  [spinnerText]="'Loading...'"
  [disableFullScreen]="true"
></app-spinner>

<div class="panel-wrapper">
  <p-panel
    class="create-first-store"
    id="createFirstStore"
    header="Create My First Store"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      <div>
        <p class="text-center">You dont have any store yet. Lets create one.</p>
        <p class="text-center"><a target="_blank" href="http://trivita.cdn.s3.amazonaws.com/US/files/MWSCompensation.pdf">Why should create a store?</a></p>

        <button (click)="openCreateStoreModal()" class="submit-btn bg-color">
          Create Store
        </button>
      </div>
    </div>
  </p-panel>
</div>

<div *ngIf="showCreateStoreModal" class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showCreateStoreModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
  >
    <p-header>
      <div class="row w-100">
        <div class="col-12 col-sm-7">
          <span> Create Store</span>
        </div>
      </div>
    </p-header>
    <form [formGroup]="storeCreateSettingsFormGroup">
      <div class="form-group">
        <label for="websitetitle"
          >Title <span style="color: red">*</span>
        </label>
        <input
          type="text"
          class="form-control"
          id="websitetitle"
          placeholder="Enter your website title"
          formControlName="Title"
        />
      </div>
      <div class="form-group">
        <label for="websiteAddress"
          >Site Address <span style="color: red">*</span></label
        >
        <div style="display: flex">
          <p style="line-height: 3">https://www.mywellnessstore.com/</p>
          <input
            type="text"
            class="form-control"
            [ngClass]="{ error: isSiteAddressUsedError }"
            id="websiteAddress"
            placeholder="Enter website address"
            formControlName="SiteAddress"
          />
        </div>
      </div>
      <div class="form-group">
        <label for="websiteStory"
          >Story <span style="color: red">*</span></label
        >
        <input
          type="text"
          class="form-control"
          id="websiteStory"
          placeholder="Your webiste story"
          formControlName="Story"
        />
      </div>
      <div class="form-group">
        <label for="websiteColor"
          >Theme Color <span style="color: red">*</span></label
        >
        <select
          formControlName="Color"
          name="color"
          id="websiteColor"
          class="form-control"
        >
          <option value="orange">Orange</option>
          <option value="red">Red</option>
          <option value="green">Green</option>
          <option value="yellow">Yellow</option>
        </select>
      </div>
    </form>

    <div class="text-center form-check" style="margin: 2rem auto">
      <input
        [(ngModel)]="isTermsAccepted"
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        style="margin-top: -9px"
      />
      <label class="form-check-label" for="flexCheckDefault">
        I agree to <a href="http://trivita.cdn.s3.amazonaws.com/US/files/MyWellnessStore%20Terms%20and%20Conditions.pdf">terms and conditions</a>
      </label>
    </div>

    <div class="errors">
      <div class="error" *ngFor="let error of storeCreateErrorMessages">
        {{ error }}
      </div>
    </div>


    <button [disabled]="!isTermsAccepted" (click)="createStore()" class="submit-btn bg-color">Submit</button>
  </p-dialog>
</div>
