<div
  *ngIf="addMemberFormGroup"
  class="address-form-wrapper add-member-form container"
>
  <form [formGroup]="addMemberFormGroup">
    <div class="row w-100 d-flex justify-content-center" style="padding-top: 4px">
      <div class="form-group col-md-6">
        <input
          pInputText
          type="text"
          class="form-control"
          placeholder="First name"
          formControlName="FirstName"
        />
      </div>
      <div class="form-group col-md-6">
        <input
          pInputText
          type="text"
          class="form-control"
          placeholder="Last name"
          formControlName="LastName"
        />
      </div>

      <div class="form-group col-md-6">
        <input
          pInputText
          type="email"
          class="form-control"
          placeholder="Email"
          formControlName="Email"
        />
      </div>
      <div class="form-group col-md-6">
        <input
          pInputText
          type="text"
          class="form-control"
          placeholder="Phone"
          formControlName="Phone"
          [dropSpecialCharacters]="false"
          [showMaskTyped]="true"
          [mask]="phoneMask"
        />
      </div>
      <div class="form-group col-12">
        <input
          pInputText
          type="text"
          class="form-control"
          placeholder="Address"
          formControlName="Address"
        />
      </div>

      <div class="form-group col-12">
        <input
          pInputText
          type="text"
          class="form-control"
          placeholder="City"
          formControlName="City"
        />
      </div>

      <div
        *ngIf="country"
        class="form-group col-12"
        [ngClass]="{ selected: addMemberFormGroup.controls['State'].valid }"
      >
        <p-dropdown
          placeholder="Select State"
          [options]="country.states"
          formControlName="State"
        >
        </p-dropdown>
      </div>

      <div class="form-group col-12">
        <input
          pInputText
          appInputUppercase
          type="text"
          class="form-control"
          placeholder="Postal Code"
          formControlName="Zipcode"
          [dropSpecialCharacters]="false"
          [showMaskTyped]="true"
          [mask]="postalCodeMask"
        />
      </div>

      <!-- <div class='form-group col-12'>
        <input pInputText
               readonly
               type="text"
               class='form-control readonly-input'
               formControlName="countryId">
      </div> -->

      <div class="row w-100 col-12 no-padding address-form-btn-block">
        <div class="col-md-6 col-12">
          <button class="cancel-btn pointer" (click)="cancelAddEditAddress()">
            Cancel
          </button>
        </div>
        <div class="col-md-6 col-12">
          <button
            class="submit-btn bg-color pointer"
            [disabled]="
              !addMemberFormGroup?.valid || addMemberFormGroup?.pristine || submitted
            "
            (click)="onSubmit()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
