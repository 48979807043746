<p-dialog
  *ngIf="pointsSummaryDialogVisible"
  header="Order details"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [(visible)]="pointsSummaryDialogVisible"
>
  <div>Here should be the summary of how they earned points.</div>
</p-dialog>

<div *ngIf="dashboardDetails && typeOfMember" class="cointainer">
  <div class="row w-100 basic-info-block d-flex justify-content-between">
    <!-- TODO: add hash links to defined panels -->
    <div class="col-md-3 col-sm-6">
      <div class="details-title text-uppercase">Your Orders</div>
      <div class="details">
        {{ dashboardDetails.recentOrderCount }} recent orders
      </div>
      <div class="link-block">
        <a
          class="text-color anchor-link text-uppercase pointer"
          routerLink="/main/account-management"
          fragment="order-history"
          (click)="linkToPanelClicked.emit('orderHistory')"
        >
          View Orders
        </a>
      </div>
    </div>
    <div class="col-md-3 col-sm-6">
      <div class="details-title text-uppercase">Your Points</div>
      <div class="details">
        {{ dashboardDetails.vitaPoints }} ({{
          dashboardDetails.vitaPoints / 100 | currency : "USD" : true
        }})
      </div>
    </div>
    <div class="col-md-2 col-sm-6">
      <div class="details-title text-uppercase">Member Since</div>
      <div class="details">{{ dashboardDetails.memberSince | date : "y" }}</div>
    </div>
    <div class="col-md-3 col-sm-6">
      <div class="float-md-right">
        <div class="details-title text-uppercase">Status</div>

        <div class="details status-btn text-uppercase">
          {{ isAffiliate ? "Affiliate" : "Member" }}
        </div>
      </div>
    </div>
  </div>
</div>
