<div class='container payment-form-content'
     [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '610px', 'width': dialogContentMaxWidth }">
  <app-credit-card-form
    [form]="form"
    [isPaymentManagement]="true"
    [creditCardsTypes]="creditCardsTypes"
    (onAddEditCard)="sendUpdatedOrder()"
    (onChangeStep)="onClosePaymentManagement.emit()">
  </app-credit-card-form>
</div>

<p-toast></p-toast>
<app-spinner 
  *ngIf="isLoading" 
  [spinnerText]="spinnerText" 
  [disableFullScreen]="true"
></app-spinner>
