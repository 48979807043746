<div class="panel-wrapper">
  <p-panel
    id="media-library"
    header="Media Library"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    (onAfterToggle)="panelToggled.emit($event)">

    <div class="row panel-block">
      <div class="panel-label">
        Videos
      </div>
    </div>

    <div class="row videos-wrapper">
      <div class='col-lg-4 col-md-12 video-block' *ngFor="let video of videos">
        <div class="video-card">
          <iframe
            [src]="video.Url | safeResourceUrl"
            frameborder="0"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen>
          </iframe>
          <h5 class="video-card-title">
            {{ video.Title }}
          </h5>
          <div class="video-card-text">
            {{ video.Description }}
          </div>
        </div>
        <div class="video-card-links row">
          <div class="col video-button" (click)="openEmbedVideoCodeDialog(video.Url)">
            <i class="fa fa-code" aria-hidden="true"></i>
            <span>Embed</span>
          </div>
          <div class="col video-button">
            <a class="sharing" [href]="video.ShareButtonUrl" target="_blank">
              <i style="margin-bottom: 4px" class="fa fa-share-alt" aria-hidden="true"></i>
              <span>Share</span>
            </a>
          </div>
          <div class="col video-button sharing" (click)="openEmailVideoMessageDialog(video)">
            <i class="fa fa-envelope-o" aria-hidden="true"></i>
            <span>Email</span>
          </div>
        </div>
      </div>
    </div>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>

<app-embed-video-code-dialog
  [embedCodeDialogIsVisible]="dialogsVisibility.embedCodeDialogIsVisible"
  [embedVideoCode]="embedVideoCode"
  (onDialogHide)="onDialogHide($event)">
</app-embed-video-code-dialog>
<app-email-video-message-dialog
  *ngIf="emailDialogVideo"
  [memberId]="memberId"
  [emailDialogIsVisible]="dialogsVisibility.emailDialogIsVisible"
  [emailDialogVideo]="emailDialogVideo"
  (onDialogHide)="onDialogHide($event)"
  (onMessageSent)="onMessageSent({severity: 'success', summary: 'Message was sent.'})">
</app-email-video-message-dialog>