import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ReferralOrderDetailModel } from "../models/referral.model";

@Component({
  selector: "app-order-detail-table",
  templateUrl: "./order-detail-table.component.html",
  styleUrls: ["./order-detail-table.component.css"],
})
export class OrderDetailTableComponent implements OnInit, OnChanges {
  @Input() referralOrderDetails!: ReferralOrderDetailModel[];
  priceTotal = 0;
  extendedTotal = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.referralOrderDetails && this.referralOrderDetails.length) {
      this.priceTotal = this.referralOrderDetails.reduce(
        (partialSum, x) => partialSum + +x.Price,
        0
      );
      this.extendedTotal = this.referralOrderDetails.reduce(
        (partialSum, x) => partialSum + +x.Extended,
        0
      );
    }
  }

  ngOnInit(): void {}
}
