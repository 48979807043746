import { EMPTY } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize } from 'rxjs/operators';

import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { MessageService } from 'primeng/api';
import { Country } from '@models/country.model';
import { OcentureSecondaryModel } from '@models/ocenture-secondary.model';
import { UserService } from '@services/user.service';
import { OcentureService } from '@services/ocenture.service';
import { OcentureSecondaryAddParamsModel } from '@models/ocenture-secondary-add-params.model';

@Component({
  selector: 'app-manage-secondary-accounts',
  templateUrl: './manage-secondary-accounts.component.html',
  styleUrls: ['./manage-secondary-accounts.component.css'],
})
export class ManageSecondaryAccountsComponent implements OnInit {
  @Input() country!: Country;
  showAddEditModal = false;
  secondaryAccounts!: OcentureSecondaryModel[];
  isLoading = false;

  constructor(
    private ocentureService: OcentureService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private authService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getAllSecondaryAccounts();
  }

  getAllSecondaryAccounts() {
    this.isLoading = true;
    this.ocentureService
      .getAllSecondaryAccounts()
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.secondaryAccounts = data;
      });
  }
  onAddSecondaryClick() {
    this.showAddEditModal = true;
  }

  onSecondaryRemove(CustID: string) {
    const account = this.secondaryAccounts.find((x) => x.CustID == CustID);
    this.confirmationService.confirm({
      message: `Do you want to delete this account of ${
        account?.CustFName + ' ' + account?.CustLName
      } - User ID: ${account?.CustID}?`,
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        // To Do Delete API Call
        this.isLoading = true;
        this.ocentureService
          .removeSecondaryAccount(CustID)
          .pipe(
            finalize(() => {
              this.getAllSecondaryAccounts();
              this.isLoading = false;
            })
          )
          .subscribe((data) => {
            console.log('Secondary Customer Remvoed', data);
          });
      },
    });
  }

  onCancel() {
    this.showAddEditModal = false;
  }
  onSubmit(data: OcentureSecondaryAddParamsModel) {
    this.isLoading = true;
    this.ocentureService
      .addSecondaryAccount({ ...data, CustID: this.authService.user?.memberId })
      .pipe(
        catchError((err, caught) => {
          console.log(err);
          this.messageService.add({
            severity: 'error',
            summary: 'Add family member failed! ' + err?.error.Message,
          });
          return EMPTY;
        }),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((data) => {
        this.showAddEditModal = false;
        this.getAllSecondaryAccounts();
        this.messageService.add({
          severity: 'success',
          summary: 'Family member added successfull',
        });
      });
  }
}
