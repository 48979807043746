import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  Subscription,
  debounceTime,
  distinctUntilChanged,
  of,
  startWith,
} from 'rxjs';
import { MessageService } from 'primeng/api';
import { Product } from '@models/arp-order.model';
import { ArpOrderService } from '@services/arp-order.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-available-products',
  templateUrl: './available-products.component.html',
  styleUrls: ['./available-products.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvailableProductsComponent implements OnInit, OnDestroy {
  @Input() countryId!: number;
  @Input() selectedProducts!: Product[];
  @Output() onSelectProduct = new EventEmitter<Product>();
  @Output() onUnSelectProduct = new EventEmitter<Product>();

  public filteredProductsItems!: { product: Product; checked: boolean }[];
  public isLoading!: boolean;
  // public queryName = '';
  public queryName = new FormControl('');
  public spinnerText!: string;

  private products!: Product[];
  private sub!: Subscription;
  private carouselItemList!: { product: Product; checked: boolean }[];

  responsiveOptions: any[] | undefined;

  constructor(
    private arpOrderService: ArpOrderService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    // Timeout is needed for carousel takes full width of parent
    setTimeout(() => {
      this.getAvailableProducts();
      this.unCheckedToggleBtn();

      this.responsiveOptions = [
        {
          breakpoint: '1400px',
          numVisible: 3,
          numScroll: 3,
        },
        {
          breakpoint: '1220px',
          numVisible: 2,
          numScroll: 2,
        },
        {
          breakpoint: '1100px',
          numVisible: 1,
          numScroll: 1,
        },
      ];
    }, 100);

    this.queryName.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(300))
      .subscribe((queryName) => {
        if (queryName === '' || queryName === null || queryName === undefined) {
          this.filteredProductsItems = this.carouselItemList.slice();
          return;
        }
        this.filteredProductsItems = this.carouselItemList.filter((item) => {
          return (
            item.product?.productName
              .toLowerCase()
              .includes(queryName.toLowerCase()) ||
            item.product?.itemNumber
              .toLowerCase()
              .includes(queryName.toLowerCase()) ||
            (item.product?.csvKeywords &&
              item.product?.csvKeywords
                .toString()
                .includes(queryName?.toLowerCase())) ||
            item.product.price.toString().includes(queryName.toLowerCase())
          );
        });
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // forming product list for carousel component
  private createCarouselItems(): void {
    this.carouselItemList = this.products.map((product) => {
      return { product, checked: false };
    });
    this.selectedProducts.map((selectedProduct) => {
      const selectedProductItem = this.carouselItemList.find(
        (item) => item.product.productId === selectedProduct.productId
      );
      if (selectedProductItem) {
        selectedProductItem.checked = true;
      }
    });
    this.filteredProductsItems = this.carouselItemList.slice();
  }

  // get product list for some country from service
  private getAvailableProducts(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    if (this.arpOrderService.availableProducts?.length) {
      this.products = this.arpOrderService.availableProducts;
      this.isLoading = false;
      this.spinnerText = '';
      this.createCarouselItems();
    } else {
      this.arpOrderService.getAvailableProducts(this.countryId).subscribe({
        next: (response) => {
          this.products = response;
          this.isLoading = false;
          this.spinnerText = '';
          this.createCarouselItems();
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.spinnerText = '';
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
    }
  }

  // handle checked/unchecked product item and pass it to parent component
  public handleChange(event: any, product: Product): void {
    if (event.checked) {
      this.onSelectProduct.emit(product);
    } else {
      this.onUnSelectProduct.emit(product);
    }
  }

  // handle checked/unchecked product item in available product list
  private unCheckedToggleBtn(): void {
    this.sub = this.wizardHelperService.removedProductSubject.subscribe(
      (product: Product) => {
        const removedProduct = this.carouselItemList.find(
          (item) => item.product.productId === product.productId
        );

        if (removedProduct) removedProduct.checked = false;
      }
    );
  }
}
