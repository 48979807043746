import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { AffiliateService } from '@services/affiliate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed!: boolean;

  public isLoading!: false;
  public tutorials: any[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getTutorials();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get tutorials from API using service
  private getTutorials(): void {
    this.affiliateService
      .getTutorials()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.tutorials = response;
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }
}
