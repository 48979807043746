import { UserService } from "../../../services/user.service";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { MessageService } from "primeng/api";


@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  @Input() panelCollapsed!: boolean;
  @Input() memberId!: any;

  public isLoading = false;
  public spinnerText!: string;

  constructor(
    private router: Router,
    private userService: UserService,
    private messageService: MessageService,
    public auth: AuthService
  ) {}

  ngOnInit() {
  }




  public onResetPasswordClick(): void {
    this.userService.logout();
    this.router.navigate(["/forgot-password"]);
  }


}
