import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable()
export class OrderHistoryService {
  constructor(
    private httpService: HttpService,
    private authService: UserService
  ) {}

  get userToken(): string {
    return this.authService.user.token;
  }

  getOrderHistory(
    memberId: number,
    startDate: string = '',
    endDate: string = ''
  ): Observable<any> {
    return this.httpService.get(
      `orderhistory/getOrders?id=${memberId}&startDate=${startDate}&endDate=${endDate}`,
      'account',
      this.userToken
    );
  }

  getOrderHistoryById(memberId: number, orderId: number): Observable<any> {
    return this.httpService.get(
      `orderhistory/getOrderDetail?id=${memberId}&orderId=${orderId}`,
      'account',
      this.userToken
    );
  }
}
