import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@components/login/login.component';
import { UpdateProfileComponent } from '@components/update-profile/update-profile.component';

const routes: Routes = [
  //{ path: "", redirectTo: "main", pathMatch: "full" },
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  { path: "update-profile", component: UpdateProfileComponent},
  {
    path: "main",
    loadChildren: () =>
      import("./modules/main/main.module").then((m) => m.MainModule),
  },
  {
    path: "wellness-store",
    loadChildren: () =>
      import("./modules/wellness-store/wellness-store.module").then(
        (m) => m.WellnessStoreModule
      ),
  },
  { path: "**", redirectTo: "main", pathMatch: "full" },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
