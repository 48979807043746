import {AbstractControl, FormGroup} from '@angular/forms';

/**
 * Show validation error when form is invalid or dirty and was submitted
 */
export const showError = (formGroup: FormGroup, controlName: string, formSubmitted: boolean): boolean => {
  return formGroup['controls'][controlName].invalid &&
    formGroup['controls'][controlName].dirty ||
    formGroup['controls'][controlName].invalid && formSubmitted;
};
