import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ArpOrder } from "@models/arp-order.model";
import { ArpOrderService, IntervalOptions } from "@services/arp-order.service";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-arp-order-option",
  templateUrl: "./arp-order-option.component.html",
  styleUrls: ["./arp-order-option.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ArpOrderOptionComponent implements OnInit {
  @Input() order!: ArpOrder; // order object
  @Input() form!: FormGroup; // edit order form

  @Output() onEditOrderClick: EventEmitter<ArpOrder> = new EventEmitter();

  public intervalOptions!: IntervalOptions;
  public dayOfMonthOptions: SelectItem[] = [];
  public pointsOptions = { true: "Yes", false: "No" };
  public frequencyOptions: SelectItem[] = [
    { value: 1, label: "Monthly" },
    { value: 2, label: "Every Two Months" },
    { value: 3, label: "Every Three Months" },
  ];
  public usingPointsOptions: SelectItem[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  constructor(private arpOrderService: ArpOrderService) {}

  ngOnInit() {
    this.getConfigurationOptions();
    this.createDayOfMonthOptions();
  }

  private createDayOfMonthOptions(): void {
    for (let i = 1; i < 29; i++) {
      this.dayOfMonthOptions.push({ value: i, label: i.toString() });
    }
  }

  // get arp configuration options from service
  private getConfigurationOptions(): void {
    if (this.arpOrderService.arpOrderOptions) {
      this.intervalOptions = this.arpOrderService.arpOrderOptions;
    } else {
      this.arpOrderService.setConfigurationOptions().subscribe((response) => {
        this.intervalOptions = response;
      });
    }
  }
}
