<div class="row w-100">
  <div class="col-7 header no-padding"></div>
  <div *ngIf="!isAddingEditingAddress" class='no-padding d-flex justify-content-end col-sm-5 col-12 add-btn-wrapper'>
    <div (click)="addEditAddress(null)" class='add-address-btn bg-color'>
      <i class="fa fa-plus" aria-hidden="true"></i>
      <span [ngClass]="{ 'no-display': dialogContentMaxWidth < 480 }">Add address</span>
    </div>
  </div>
</div>

<div
  *ngIf="!isAddingEditingAddress"
  class='container address-list-content'
  #content
  [ngStyle]="{'height': 450 + 'px', 'overflow-y': 'auto', 'width': dialogContentMaxWidth}">

  <div *ngIf="isCreditCardError" class="credit-error-message">
    There was an error processing the credit card provided. Please update and try again.
  </div>

  <div *ngIf="!isAddingEditingAddress && !!addressItems" class='address-list-block row'>
    <div *ngFor='let address of addressItems' class='address-item col-12 col-sm-6 col-md-4'>
      <div [ngClass]="{ 'border-color': address.checked }" class='address-item-block'>
        <div
          *ngIf='address.address.isDefaultBillingAddress || address.address.isDefaultShippingAddress'
          class='default-address'>
          Default:
          <span *ngIf='address.address.isDefaultShippingAddress'>Shipping</span>
          <span *ngIf='address.address.isDefaultBillingAddress && address.address.isDefaultShippingAddress'>AND</span>
          <span *ngIf='address.address.isDefaultBillingAddress'>Billing</span>
        </div>

        <div
          *ngIf='address.address.isDefaultBillingAddress || address.address.isDefaultShippingAddress'
          class='edit-address-btn'
          (click)="addEditAddress(address.address)">
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </div>

        <div class='col-md-12 address-name'>
          {{ address.address.addressName }}
          <div
            *ngIf='!address.address.isDefaultBillingAddress && !address.address.isDefaultShippingAddress'
            class='remove-address-btn'
            (click)="removeAddress(address.address.addressId)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </div>
          <div
            *ngIf='!address.address.isDefaultBillingAddress && !address.address.isDefaultShippingAddress'
            class='edit-address-btn'
            (click)="addEditAddress(address.address)">
            <i class="fa fa-pencil" aria-hidden="true"></i>
          </div>
        </div>

        <div class='col-md-12'>{{ address.address.address1 }}</div>
        <div *ngIf="country" class='col-md-12'>{{ address.address.city }}
          , {{ address.address.state | stateName : country.states }}, {{ address.address.postalCode }}</div>

        <div class='col-12 select-toggle-btn'>
          <p-toggleButton
            class='select-btn'
            [(ngModel)]="address.checked"
            onLabel="Selected"
            offLabel="Select"
            (onChange)="changeCheckedAddress($event.checked, address)"
            [disabled]="address.checked && (address.address.isDefaultShippingAddress || address.address.isDefaultBillingAddress)">
          </p-toggleButton>
        </div>

      </div>
    </div>
  </div>
</div>

<div
  *ngIf="isAddingEditingAddress"
  class='container address-list-content'
  #content
  [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '650px', 'width': dialogContentMaxWidth }">

  <app-add-edit-address
    *ngIf="isAddingEditingAddress"
    [country]="country"
    [currentAddress]="editableAddress"
    [typeOfAddress]="typeOfAddress"
    [isEditingOrder]="true"
    (onEndAddEditAddress)="endAddEditAddress()">
  </app-add-edit-address>

  <div *ngIf="!isAddingEditingAddress" class='footer-fixed'>
    <button
      class="cancel-btn pointer"
      (click)="onCloseAddressManagement.emit()">
      Close
    </button>
  </div>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
