<div class="panel-wrapper">

  <p-panel
    header="Auto Replenishment Program"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

    (onAfterToggle)="onPanelToggle($event)">

    <div *ngIf="orderList">

      <!-- View order, Edit order options and products -->
      <app-arp-order
        [country]="country"
        [user]="user"
        [orderList]="orderList"
        (onToggleWizardDialog)="wizardDialogIsOpen = $event;"
      ></app-arp-order>

      <!-- Add order dialog -->
      <app-arp-order-wizard
        *ngIf="wizardDialogIsOpen"
        [country]="country"
        [user]="user"
        [visible]="wizardDialogIsOpen"
        [orderList]="orderList"
        (onAddOrder)="onAddOrderHandler($event)"
        (onToggleWizardDialog)="wizardDialogIsOpen = $event;"
      ></app-arp-order-wizard>

    </div>

  </p-panel>
  <app-spinner *ngIf="isLoading" [spinnerText]="spinnerText" [disableFullScreen]="true"></app-spinner>
</div>
<p-toast></p-toast>
