import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './spinner/spinner.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { ProgressSpinnerModule } from 'primeng/progressspinner';




@NgModule({
  declarations: [
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    //NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' })
    ProgressSpinnerModule
  ],
  exports: [
    SpinnerComponent
  ]
})
export class SpinnerModule { }
