<div class="cointainer">
  <div class="row basic-info-block ">
    <div class="col-md-3 col-sm-6">
      <div class="details-title text-uppercase">Total Customers</div>
      <div class="details">{{ dashboardInfo?.dashboard?.totalCustomers }}</div>
    </div>
    <div class="col-md-3 col-sm-6">
      <div class="details-title text-uppercase">Last Months Income</div>
      <div class="details">{{ dashboardInfo?.dashboard?.lastMonthsIncome | currency:'USD':true}}</div>
    </div>
    <div class="col-md-3 col-sm-6">
      <div class="details-title text-uppercase">Year to Date Income</div>
      <div class="details">{{ dashboardInfo?.dashboard?.ytdIncome | currency:'USD':true}}</div>
    </div>
  </div>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
