import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ArpOrder } from "@models/arp-order.model";
import { Country } from "@models/country.model";
import { UserInfo } from "@models/user-info.model";



@Component({
  selector: 'app-arp-order-personal-info',
  templateUrl: './arp-order-personal-info.component.html',
  styleUrls: ['./arp-order-personal-info.component.scss']
})
export class ArpOrderPersonalInfoComponent implements OnInit {
  @Input() country!: Country;
  @Input() order!: ArpOrder;
  @Input() user!: UserInfo;

  @Output() onOpenManagementAddress: EventEmitter<{ order: ArpOrder, typeOfAddress: string | null }> = new EventEmitter();
  @Output() onOpenManagementPayment: EventEmitter<ArpOrder> = new EventEmitter();

  public openAddressManagement(typeOfAddress: string | null = null): void {
    const editingOrder = {order: this.order, typeOfAddress: typeOfAddress};
    this.onOpenManagementAddress.emit(editingOrder);
  }

  public openPaymentManagement(): void {
    const editingOrder = this.order;
    this.onOpenManagementPayment.emit(editingOrder);
  }

  ngOnInit(): void {
    console.log(this.order);
  }

}
