import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateName',
})
export class StateNamePipe implements PipeTransform {
  transform(stateCode: any, states: any): string {
    if (states && states.length) {
      if (
        states.find(({ value }: any) => {
          return value === stateCode;
        })
      ) {
        return states.find(({ value }: any) => value === stateCode)['label'];
      }
    }
    return '';
  }
}
