import { cloneDeep } from "lodash";

export interface HeaderConfiguration {
  label: string;
  value: string;
  active: boolean;
  secondary?: {
    label: string;
    value: string;
    active: boolean;
    hidden?: boolean;
  }[];
  collapsed?: boolean;
}

export enum Pages {
  ACCOUNT_MANAGEMENT = "ACCOUNT MANAGEMENT",
  AFFILIATE_OFFICE = "AFFILIATE OFFICE",
  WELLNESS_STORE = "WELLNESS STORE",
  CONTENT = "CONTENT",
  STORES = "STORES",
  MODE = "MODE",
}

const affiliateOffice = {
  label: Pages.AFFILIATE_OFFICE,
  value: "/main/affiliate-office",
  active: false,
};

export const defaultConfiguration: HeaderConfiguration[] = [
  {
    label: Pages.ACCOUNT_MANAGEMENT,
    value: "/main/account-management",
    active: false,
  },
  {
    label: Pages.WELLNESS_STORE,
    value: "/wellness-store/user",
    active: false,
    collapsed: true,
    secondary: [
      { label: "Stores", value: "/select-store", active: false, hidden: true },
      { label: "Feed", value: "/feed", active: false },
      { label: "Blog", value: "/blog", active: false },
      { label: "Site Settings", value: "/site-settings", active: false },
    ],
  },
  { label: Pages.MODE, value: "/wellness-store/mode", active: false },
];

export const adminConfiguration: HeaderConfiguration[] = [
  {
    label: Pages.STORES,
    value: "/wellness-store/admin-stores",
    active: false,
    collapsed: true,
    secondary: [
      { label: "Users", value: "/users", active: false },
      { label: "Feed", value: "/feed", active: false },
      { label: "Blog", value: "/blog", active: false },
    ],
  },
  {
    label: Pages.CONTENT,
    value: "/wellness-store/admin-content",
    active: false,
    collapsed: true,
    secondary: [
      { label: "Feed", value: "/feed", active: false },
      { label: "Blog", value: "/blog", active: false },
    ],
  },
  { label: Pages.MODE, value: "/wellness-store/mode", active: false },
];

export function getHeaderConfiguration(
  isAffiliate: string,
  changeMode = false,
  wellnessStore = true,
  admin = false
): HeaderConfiguration[] {
  const config = admin
    ? cloneDeep(adminConfiguration)
    : cloneDeep(defaultConfiguration);

  if (!changeMode && !admin) {
    config.splice(config.length - 1, 1);
  }

  if (isAffiliate === "true" && !admin) {
    config.splice(1, 0, affiliateOffice);
  }

  if (!wellnessStore && !admin) {
    config.splice(1, 1);
  }

  return config;
}
