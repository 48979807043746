import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Country } from '@models/country.model';
import { WizardHelperService } from '@services/wizard-helper.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-arp-order-address-view',
  templateUrl: './arp-order-address-view.component.html',
  styleUrls: ['./arp-order-address-view.component.scss'],
})
export class ArpOrderAddressViewComponent implements OnInit {
  @Input() address!: any;
  @Input() country!: Country;
  @Input() title!: string;

  @Output() onOpenAddressManagement: EventEmitter<null> = new EventEmitter();

  constructor(private wizardHelperService: WizardHelperService) {}

  ngOnInit() {
    if (!this.address.addressId) {
      this.wizardHelperService.addressListSubject
        .pipe(first())
        .subscribe((addresses) => {
          this.address = addresses.find(
            (item) => item.addressId === this.address
          );
        });
    }
  }
}
