<div class="panel-wrapper">
  <p-panel
    header="Message Center"
    [collapsed]="panelCollapsed">
    <div class="message" *ngIf="messages && messages.length === 0">
      There are currently no messages. Look here for important messages from Trivita.
    </div>
    <div class="message" *ngFor="let message of messages">
      <div class="icon">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      </div>
      <div class="text" [innerHTML]="message.Description | safeHtml"></div>
    </div>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>

