<div class="subscription">
  <div class="container">
    <div class="row w-100">
      <div class="col-12">
        <h3>Subscription Update Required</h3>
        <p>We were unable to process your credit card for your store access fee. Please provide a new credit card below.</p>
        <!-- <p>Amount due: $xx.xx</p> -->
      </div>
    </div>
  </div>

  <ng-container *ngIf="{
      paymentInfo: paymentInfo,
      contactInfo: contactInfo$ | async
    } as subscription"
  >
  <div *ngIf="subscription.contactInfo?.defaultBillingAddress" class="container">
    <div class="row w-100">
      <div class="col-lg-6 col-md-12 col-sm-12 margin-bottom-30 align-content-start">
        <p class="panel-content-title">Credit card
        </p>
        <p class="panel-content-value">{{ subscription.paymentInfo?.CCNum }}</p>
        <form
          [formGroup]="updateCardForm"
          class="panel-content-value"
        >
        <div class="card-number">
          <span class="label">
            Card number:
          </span>
          <input
            type="text"
            placeholder="Credit Card Number"
            formControlName="CCNum"
          />
        </div>
        <div class="exp-date">
          <span class="label">Card type:</span>
          <div class="exp-date__dropdown">
            <p-dropdown
                placeholder="Select Card Type"
                [options]="(cardTypes$ | async)!"
                formControlName="CardType"
            ></p-dropdown>
          </div>
        </div>
          <div class="exp-date">
            <span class="label">Expiration date:</span>
            <div class="exp-date__dropdown">
              <p-dropdown
                  placeholder="Month"
                  [options]="months"
                  formControlName="cardExpMonth"
              ></p-dropdown>
            </div>
            <span class="separator">/</span>
            <div class="exp-date__dropdown">
              <p-dropdown
                placeholder="Year"
                [options]="years"
                formControlName="cardExpYear"
              ></p-dropdown>
            </div>
          </div>
          <div class="cvv-code">
            <span class="label">
              CVV:
            </span>
            <input
              type="text"
              placeholder="CVV"
              formControlName="CVV"
            />
          </div>
        </form>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-12 row margin-bottom-30 align-content-start">
        <p class="col-12 panel-content-title">Billing Address
          <span class="text-color pointer margin-left" (click)="editAddress()">Change</span>
        </p>
        <p class="col-12 panel-content-value"> {{ subscription?.contactInfo?.defaultBillingAddress?.addressName }} </p>
        <p class="col-12 panel-content-value"> {{ subscription?.contactInfo?.defaultBillingAddress?.address1 }} </p>
        <p *ngIf="subscription?.contactInfo?.defaultBillingAddress?.address2" class="col-12 panel-content-value">{{ subscription?.contactInfo?.defaultBillingAddress?.address2 }}</p>
        <p class="col-12 panel-content-value"> {{ subscription?.contactInfo?.defaultBillingAddress?.city }}
          , {{ subscription?.contactInfo?.defaultBillingAddress?.postalCode }} </p>
        <p class="col-12 panel-content-value"> {{ subscription?.contactInfo?.defaultBillingAddress?.state | stateName :
          subscription?.contactInfo?.country?.states }}
          , {{ subscription?.contactInfo?.country?.displayName }}
        </p>
      </div>

      <app-address-list
        *ngIf="isEditingBillingAddress"
        [isEditingBillingAddress]="isEditingBillingAddress"
        [country]="subscription?.contactInfo?.country!"
        [memberId]="currentUser?.memberId"
        [form]="contactInfoForm"
        [user]="currentUser"
        (onCloseDialog)="closeEditingAddressDialog()"
        (onSelectAddress)="initContactInfo()"
        (onShowNotification)="showNotification($event)"
      ></app-address-list>

    </div>
    <div class="row w-100">
      <div class="col-lg-3 col-md-6 col-sm-12">
        <button (click)="onUpdateCard()"
          [disabled]="!updateCardForm.valid"
          class="submit-btn bg-color pointer"
          type="button">Process Subscription</button>
      </div>
    </div>
  </div>
</ng-container>

</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [disableFullScreen]="true"
  spinnerText="Processing your request..."
></app-spinner>
