<div [ngClass]="{ 'add-edit-form-wrapper': isAddingEditingAddress }" class="modal-wrapper">
  <p-dialog
    *ngIf="addressList && dialogCenterVisible"
    [visible]="true"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    (onHide)="closeDialog()"
    (visibleChange)="closeDialog()"
    [blockScroll]="true"    header="Header">

    <ng-template pTemplate="header">
      <div class="row w-100">
        <div class="col-6 col-sm-7"><span>{{ title }}</span></div>
        <div *ngIf="!isAddingEditingAddress"
             class='address-list__add-address no-padding d-flex justify-content-sm-end justify-content-start col-6 col-sm-5'>
          <div (click)="addEditAddress()" class='add-address-btn bg-color'>
            <i class="fa fa-plus" aria-hidden="true"></i>
            <span>Add address</span>
          </div>
        </div>
      </div>
  </ng-template>
    <!-- <p-header class="address-list__header">
      <div class="row w-100">
        <div class="col-12 col-sm-7"><span>{{ title }}</span></div>
        <div *ngIf="!isAddingEditingAddress"
             class='address-list__add-address no-padding d-flex justify-content-sm-end justify-content-start col-12 col-sm-5'>
          <div (click)="addEditAddress()" class='add-address-btn bg-color'>
            <i class="fa fa-plus" aria-hidden="true"></i>
            <span>Add address</span>
          </div>
        </div>
      </div>
    </p-header> -->
    <div
      *ngIf="!isAddingEditingAddress"
      class='container'
      #content
      [ngStyle]="{'height': 450 + 'px', 'overflow-y': 'auto', 'width': '80vw', 'max-width': '1000px'}">

      <div *ngIf="addressItems" class='address-list-block row'>
        <div *ngFor='let address of addressItems' class='address-item col-12 col-sm-6 col-md-4'>
          <div [ngClass]="{ 'border-color': address.checked }" class='address-item-block'>
            <div
              *ngIf='address.address.isDefaultBillingAddress || address.address.isDefaultShippingAddress'
              class='default-address'>
              Default:
              <span *ngIf='address.address.isDefaultShippingAddress'>Shipping</span>
              <span
                *ngIf='address.address.isDefaultBillingAddress && address.address.isDefaultShippingAddress'>AND</span>
              <span *ngIf='address.address.isDefaultBillingAddress'>Billing</span>
            </div>
            <div
              *ngIf='address.address.isDefaultBillingAddress || address.address.isDefaultShippingAddress'
              class='edit-address-btn'
              (click)="addEditAddress(address?.address)">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </div>

            <div class='col-md-12 address-name'>
              {{ address.address.addressName }}
              <div
                *ngIf='!address.address.isDefaultBillingAddress && !address.address.isDefaultShippingAddress'
                class='remove-address-btn'
                (click)="removeAddress(address?.address?.addressId)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </div>
              <div
                *ngIf='!address.address.isDefaultBillingAddress && !address.address.isDefaultShippingAddress'
                class='edit-address-btn'
                (click)="addEditAddress(address?.address)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </div>
            </div>
            <div class='col-md-12'>{{ address.address.address1 }}</div>
            <div *ngIf="country" class='col-md-12'>{{ address.address.city }}
              , {{ address.address.state | stateName : country.states }}, {{ address.address.postalCode }}</div>
            <div class='col-12 select-toggle-btn'>
              <p-toggleButton
                class='select-btn'
                [(ngModel)]="address.checked"
                onLabel="Selected"
                offLabel="Select address"
                [disabled]="address.checked && (address.address.isDefaultShippingAddress || address.address.isDefaultBillingAddress)"
                (onChange)="changeCheckedAddress($event.checked, address)">
              </p-toggleButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="isAddingEditingAddress"
      class='content'
      #content
      [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '650px', 'width': dialogContentMaxWidth }">

      <app-add-edit-address-form
        *ngIf="isAddingEditingAddress"
        [country]="country"
        (onCancelAddEditAddress)="cancelAdditingAddress()"
        (onUpdateAddressList)="updateAddressList($event)"
        (onCancelAdditingAddress)="cancelAdditingAddress()"
        [editableAddress]="editableAddress">
      </app-add-edit-address-form>

    </div>

  </p-dialog>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
