<div *ngIf="payment" class="row w-100 margin-bottom-30 align-content-start">
  <div class="col-12 panel-content-title"> Credit Card
    <span
      (click)="onOpenPaymentManagement.emit()"
      class="text-color pointer">
      Change
    </span>
  </div>
  <div class="col-7 panel-content-value"> {{ payment.cardNumber }} </div>
  <div class="col-5 panel-content-value"> Exp: {{ payment.cardExpMonth }}/{{ payment.cardExpYear }} </div>
</div>
