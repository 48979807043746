<div *ngIf="address" class="row w-100 margin-bottom-30 align-content-start">
  <div class="col-12 panel-content-title"> {{ title }}
    <span
      *ngIf="title === 'Shipping Address'"
      (click)="onOpenAddressManagement.emit()"
      class="text-color pointer">
      Change
    </span>
  </div>
  <div class="col-12 panel-content-value"> {{ address.address1 }} </div>
  <div *ngIf="country" class="col-12 panel-content-value"> {{ address.city }}
    , {{ address.state | stateName : country.states }} </div>
  <div class="col-12 panel-content-value"> {{ address.postalCode }} </div>
</div>
