<div
  #content
  class="container address-list-content wizard-step-content"
  [ngStyle]="{'overflow-y': 'auto', 'width': '1000px'}"
>

  <div *ngIf="order">
    <app-available-products
      [countryId]="order.countryId"
      [selectedProducts]="order.products"
      (onSelectProduct)="addSelectedProduct($event)"
      (onUnSelectProduct)="removeSelectedProduct($event)"
    ></app-available-products>

    <div class="footer-fixed">
      <button class="cancel-btn pointer" (click)="onCloseProductsManagement.emit()">Cancel</button>
      <button class="submit-btn bg-color pointer" (click)="updateOrders()">Apply</button>
    </div>
  </div>
</div>

