import { UserService } from "../../services/user.service";
import {
  Component,
  OnInit,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LocalStorage } from "@helpers/local-storage.decorator";
import { UserInfo } from "@models/user-info.model";
import { Subject, Subscription } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  tap,
} from "rxjs/operators";
import { HeaderConfiguration, getHeaderConfiguration } from "./header-configuration";
import { CustomerStores } from "@modules/wellness-store/interfaces/store.interface";
import { Store } from "@ngrx/store";
import { WellnessStoreState } from "@modules/wellness-store/store/wellness-store.reducer";
import { CompensationService } from "@services/compensation.service";
import { fetchSiteSettings, setCurrentStoreID, showSpinner } from "@modules/wellness-store/store/wellness-store.actions";
import { AuthService } from "@auth0/auth0-angular";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @LocalStorage() user!:UserInfo;
  @LocalStorage() storeID!:string;
  @LocalStorage() isCCExpired!:boolean;

  public configuration!: HeaderConfiguration[];
  public activeSegment!: HeaderConfiguration;
  public customerStores!: CustomerStores[];
  public currentStoreID!: number | undefined;
  public path!: string;
  public show = false;
  public closed = true;

  private destroy$ = new Subject<boolean>();

  // currentUser;

  constructor(
    public userService: UserService,
    private router: Router,
    private store: Store<{ wellnessStore: WellnessStoreState }>,
    private ref: ChangeDetectorRef,
    private storeService: CompensationService,
    public auth: AuthService
  ) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.urlAfterRedirects)
      )
      .subscribe((path: string) => {
        this.path = path;

        if (!this.configuration) {
          return;
        }
        if (this.path === "/wellness-store/mode") {
          this.activeSegment =
            this.configuration[this.configuration.length - 1];
          this.configuration.forEach((item) => (item.active = false));
          this.activeSegment.active = true;
        } else {
          this.determineActiveRoute();
        }

        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    if (!this.user) {
      this.user = this.userService.getCurrentUser();
    }
    this.store
      .select((store) => store.wellnessStore?.customerStores)
      .pipe(
        takeUntil(this.destroy$),
        filter((customerStores) => !!customerStores)
      )
      .subscribe((customerStores) => {
        this.customerStores = customerStores;
      });

    this.store
      .select((store) => store.wellnessStore?.currentStoreID)
      .pipe(
        takeUntil(this.destroy$),
        filter((currentStoreID) => !!currentStoreID)
      )
      .subscribe((currentStoreID) => {
        this.currentStoreID = currentStoreID;
      });

    this.store
      .select((store) => store.wellnessStore)
      .pipe(
        tap(() => {
          if (!this.configuration && this.user) {
            this.storeService
              .getStore(this.user.memberId)
              .subscribe((stores) => {
                // this http request "checkUserStores" failed you will not have menus populated in this header.
                this.configuration = getHeaderConfiguration(
                  this.user.isAffiliate.toString(),
                  this.user.accountFlags.includes(9995),
                  !!stores?.length
                );
                this.determineActiveRoute();
                setTimeout(() => {
                  this.ref.detectChanges();
                }, 100);
              });
          }
        }),
        filter((state) => state && !Object.is(state.admin, null)),
        map((state) => state.admin),
        distinctUntilChanged((prev, curr) => prev === curr)
      )
      .subscribe((admin) => {
        this.configuration = getHeaderConfiguration(
          this.user.isAffiliate.toString(),
          this.user.accountFlags.includes(9995),
          false,
          admin
        );

        this.determineActiveRoute();
        setTimeout(() => {
          this.ref.detectChanges();
        }, 100);
      });

    this.userService.firstStoreCreatedSubject$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isCreated) => {
        if (isCreated) {
          this.storeService.getStore(this.user.memberId).subscribe((stores) => {
            // this http request "checkUserStores" failed you will not have menus populated in this header.
            this.configuration = getHeaderConfiguration(
              this.user.isAffiliate.toString(),
              this.user.accountFlags.includes(9995),
              !!stores?.length
            );

            if (this.configuration) {
              this.changeModule(
                this.configuration.find((x) =>
                  x.value.includes("wellness-store")
                ) as HeaderConfiguration
              );
            }
            this.determineActiveRoute();
            setTimeout(() => {
              this.ref.detectChanges();
            }, 100);
          });
        }
      });
  }

  determineActiveRoute() {
    if (!this.configuration || !this.configuration.length) {
      return;
    }

    this.configuration.forEach((primary: HeaderConfiguration) => {
      if (this.path?.includes(primary.value)) {
        primary.active = true;
        this.activeSegment = primary;
      } else {
        primary.active = false;
      }

      if (primary.secondary && primary.active) {
        primary.secondary.forEach((secondary: HeaderConfiguration) => {
          secondary.active = this.path.includes(secondary.value);
        });
      }
    });
  }

  public changeModule(item: HeaderConfiguration, mobile?: boolean): void {
    const path = item.secondary
      ? `${item.value}${item.secondary[0].value}`
      : `${item.value}`;

    this.router.navigateByUrl(path);

    if (mobile) {
      setTimeout(() => (this.closed = true), 1000);
    }
  }

  public changeSubModule(path: string): void {
    this.router.navigateByUrl(this.activeSegment.value + path);
  }

  public changeModuleAndSubmodule(segment: HeaderConfiguration, path: string): void {
    this.activeSegment = segment;
    this.router.navigateByUrl(this.activeSegment.value + path);

    setTimeout(() => (this.closed = true), 1000);
  }

  public selectStore({ value: currentStoreID }: any): void {
    const currentStore = this.customerStores.filter(
      ({ customer: { ID } }) => currentStoreID === ID
    );

    this.storeID = currentStoreID;
    this.isCCExpired =
      new Date() > new Date(currentStore[0].customer?.ExpirationDate as string);

    if (this.isCCExpired) {
      this.router.navigateByUrl("main/subscription");
    }

    this.store.dispatch(showSpinner());
    this.store.dispatch(setCurrentStoreID({ currentStoreID }));
    this.store.dispatch(fetchSiteSettings());
  }

  public switchMobileMenu(): void {
    this.closed = !this.closed;

    if (this.closed) {
      //document.getElementById("chat-widget-container")?.style = "block";
    } else {
      //document.getElementById("chat-widget-container").style.display = "none";
    }
  }

  logOut() {
    this.userService.logout();
  }

  navigateToTrivita() {
    location.href = this.userService.user?.billingAddress?.countryId === 1 ? environment.auth0.logoutReturnUS : environment.auth0.logoutReturnCA
  }
}
