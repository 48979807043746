<div class="address-form-wrapper add-edit-address container">

  <form *ngIf='addressForm' [formGroup]="addressForm" class="container">

    <div class="row w-100 d-flex justify-content-center">
      <div class='form-group col-11'>
        <input pInputText
               type="text"
               class="form-control"
               placeholder="Full Name"
               formControlName="addressName">
      </div>

      <div class="form-group col-11">
        <input pInputText
               type="text"
               class='form-control'
               placeholder="Address Line 1"
               formControlName="address1">
      </div>

      <div class="form-group col-11">
        <input pInputText
               type="text"
               class='form-control'
               placeholder="Address Line 2"
               formControlName="address2">
      </div>

      <div class='form-group col-11'>
        <input pInputText
               type="text"
               class='form-control'
               placeholder="City"
               formControlName="city">
      </div>

      <div *ngIf="country" class="form-group col-11" [ngClass]="{ 'selected': addressForm.controls['state'].valid }">
        <p-dropdown
          placeholder="Select State"
          [options]="country.states"
          formControlName="state">
        </p-dropdown>
      </div>

      <div class='form-group col-11'>
        <input pInputText
               appInputUppercase
               type="text"
               class='form-control'
               placeholder="Postal Code"
               formControlName="postalCode"
               [dropSpecialCharacters]="false"
               [showMaskTyped]="true"
               [mask]="postalCodeMask">
      </div>

      <div class='form-group col-11'>
        <input pInputText
               readonly
               type="text"
               class='form-control readonly-input'
               formControlName="countryId">
      </div>

      <div class='col-11 form-group'>
        <div class='col-12 toggle-buttons-block'>
          <span> Default: </span>
          <div class="billing-shipping-toggle">
            <p-toggleButton
              formControlName="isDefaultBillingAddress"
              onLabel="BILLING"
              offLabel="BILLING"
              [onIcon]="'pi pi-check'"
              offIcon="pi pi-circle-off"
            >
            </p-toggleButton>
            <p-toggleButton
              formControlName="isDefaultShippingAddress"
              onLabel="SHIPPING"
              offLabel="SHIPPING"
              [onIcon]="'pi pi-check'"
              offIcon="pi pi-circle-off"
            >
            </p-toggleButton>
          </div>
        </div>
      </div>

      <div class="row w-100 col-11 no-padding address-form-btn-block">
        <div class='col-md-6 col-12'>
          <button class="cancel-btn pointer" (click)="endAddEditAddress()">
            <span *ngIf="!isEditingOrder"> Back </span>
            <span *ngIf="isEditingOrder"> Cancel </span>
          </button>
        </div>
        <div class='col-md-6 col-12'>
          <button
            *ngIf="addressForm"
            class="submit-btn bg-color pointer"
            [disabled]="!addressForm.valid || addressForm.pristine"
            (click)="onSubmit()">
            <span *ngIf="!isEditingOrder"> Next </span>
            <span *ngIf="isEditingOrder"> Save </span>
          </button>
        </div>
      </div>
    </div>

  </form>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
