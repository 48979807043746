import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { PaginatorModule } from 'primeng/paginator';
import { SelectButtonModule } from 'primeng/selectbutton';
import {TableModule} from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { ToggleButtonModule } from 'primeng/togglebutton';

const PRIMENG_MODULES = [
  CalendarModule,
  CarouselModule,
  DialogModule,
  DropdownModule,
  OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  TabViewModule,
  SelectButtonModule,
  ToggleButtonModule,
  TooltipModule,
  TableModule
];

// @ts-ignore
@NgModule({
  exports: PRIMENG_MODULES,
  imports: [...PRIMENG_MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PrimengModule {
}
