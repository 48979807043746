<div [ngClass]="{ 'add-edit-form-wrapper': isAddingEditingAddress || isPaymentManagement }"
     class="modal-wrapper order-editing-wrapper">
  <p-dialog
    #dialog
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [visible]="visible"
    (onHide)="closeDialog()"
    (visibleChange)="closeDialog()"
    [blockScroll]="true"
  >
    <p-header>
      <div class="row w-100">
        <div class="no-padding col-12 col-sm-7"><span> {{ title }} </span></div>
      </div>
    </p-header>
    <div
      class='content'
      #content
      [ngStyle]="{ 'overflow-y': 'auto' }">

      <app-address-management
        *ngIf="typeOfAddress"
        [country]='country'
        [isAddingEditingAddress]="isAddingEditingAddress"
        [editableAddress]="editableAddress"
        [form]='editOrderForm'
        [typeOfAddress]='typeOfAddress'
        [user]="user"
        (onAddEditAddress)='addEditAddress($event)'
        (onSelectAddressToEditing)='selectAddresToEditing($event)'
        (onCloseAddressManagement)='closeDialog()'
        (onShowNotification)="onShowNotification.emit($event); onCloseEditingDialog.emit()">
      </app-address-management>

      <app-payment-management
        *ngIf="isPaymentManagement"
        [form]='editOrderForm'
        (onClosePaymentManagement)='closeDialog()'
        (onShowNotification)='onShowNotification.emit($event)'>
      </app-payment-management>

      <app-products-management
        *ngIf="isProductManagement"
        [country]='country'
        [existingOrder]="form.value"
        (onCloseProductsManagement)='closeDialog()'
        (onProductListUpdated)='onProductListUpdated.emit($event)'
      ></app-products-management>

    </div>
  </p-dialog>
</div>
