import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { AffiliateService } from '@services/affiliate.service';
import { AffiliateMessage } from '@models/affiliate-message.model';

@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss'],
})
export class MessageCenterComponent implements OnInit, OnDestroy {
  @Input() panelCollapsed!: boolean;

  private destroy$ = new Subject<void>();

  public isLoading!: false;
  public messages!: AffiliateMessage[];

  constructor(
    private affiliateService: AffiliateService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getMessages();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get messages to displaing in message-center-block from API using service
  private getMessages(): void {
    this.affiliateService
      .getMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: ({ messages }) => {
          this.messages = messages;
        },
        error: ( error: any ) => {
          console.log(error);
          this.messageService.add({
            severity: 'error',
            summary: error,
          });
        },
      });
  }
}
