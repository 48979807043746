import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  CompensationDashboardModel,
  StatementDetailModel,
  StatementPeriodModel,
} from "./models/compensation.models";
import { Component, OnInit } from "@angular/core";
import { CompensationService } from "../../../services/compensation.service";
import { UserService } from "../../../services/user.service";
import { catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";

var jsPDF = require('jspdf');
require('jspdf-autotable');



@Component({
  selector: "app-my-compensations",
  templateUrl: "./my-compensations.component.html",
  styleUrls: ["./my-compensations.component.css"],
})
export class MyCompensationsComponent implements OnInit {
  isLoading = false;
  user: any;
  panelCollapsed = true;
  hasStore = false;
  userStore!: any[];

  compensationDashboard!: CompensationDashboardModel;

  statementDatesFormGroup: FormGroup;

  statements: StatementDetailModel[] = [];

  retailTotal!: number;
  valueTotal!: number;
  paidTotal!: number;

  showStatementModal = false;

  today = new Date();
  minDate = new Date(2020, 8, 1);

  previousMonthDate = new Date(new Date().setDate(this.today.getDate() - 30));

  fromDate = this.previousMonthDate;
  toDate = this.today;
  maxYear = this.toDate.getFullYear();


  constructor(
    private _fb: FormBuilder,
    private storeService: CompensationService,
    private authService: UserService
  ) {
    this.user = authService.getCurrentUser();
    this.statementDatesFormGroup = this._fb.group({
      period: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.getCompensationDashboard();
  }


  getCompensationDashboard() {
    this.storeService
      .getCompenstationDashboard(this.user.memberId)
      .subscribe((res: any) => {
        this.compensationDashboard = res[0];
        console.log(this.compensationDashboard);
      });
  }

  showStatement() {
    this.isLoading = true;
    this.statements = [];
    this.retailTotal = 0;
    this.valueTotal = 0;
    this.paidTotal = 0;

    this.storeService
      .getStatements(
        this.user.memberId,
        this.fromDate.toUTCString(),
        this.toDate.toUTCString()
      )
      .pipe(
        catchError((err, caught) => {
          this.isLoading = false;
          console.log(err);
          return EMPTY;
        })
      )
      .subscribe((res: any) => {
        console.log(res);
        this.isLoading = false;
        this.showStatementModal = true;
        if (res) {
          this.statements = res;
          this.retailTotal = this.statements.reduce(
            (partialSum, x) => partialSum + x.Retail,
            0
          );
          this.valueTotal = this.statements.reduce(
            (partialSum, x) => partialSum + x.Value,
            0
          );
          this.paidTotal = this.statements.reduce(
            (partialSum, x) => partialSum + x.Paid,
            0
          );
        }
      });
  }

  exportPdf() {
    let columns = [
      {
        title: "Period",
        dataKey: "Period",
      },

      {
        title: "Order",
        dataKey: "Order",
      },
      {
        title: "Date",
        dataKey: "Date",
      },
      {
        title: "Retail",
        dataKey: "Retail",
      },
      {
        title: "Type",
        dataKey: "Type",
      },
      {
        title: "Percentage",
        dataKey: "Percentage",
      },
      {
        title: "Value",
        dataKey: "Value",
      },
      {
        title: "Paid",
        dataKey: "Paid",
      },
    ];

    const data = this.statements.map((x) => {
      return {
        Period: x.Period,
        Order: x.Order ? x.Order : "-",
        Date: new Date(x.Date).toLocaleDateString("en-US"),
        Retail: x.Retail ? "$" + x.Retail.toFixed(2) : "-",
        Type: x.Type,
        Percentage: x.Percentage ? x.Percentage + "%" : "-",
        Value: x.Value ? "$" + x.Value.toFixed(2) : "-",
        Paid: x.Paid ? "$" + x.Paid.toFixed(2) : "-",
      };
    });
    // const doc = new jsPDF();
    const doc = new jsPDF("p", "pt");

    var width = doc.internal.pageSize.getWidth();

    doc.setFontSize(24);
    doc.text("Compensation Statements", width / 2, 50, { align: "center" });
    doc.setFontSize(12);
    doc.text(
      `Customer: ${this.user.Name} - ${this.user.memberId}`,
      width / 2,
      75,
      {
        align: "center",
      }
    );
    doc.text(
      `${this.fromDate.toLocaleDateString(
        "en-us"
      )} - ${this.toDate.toLocaleDateString("en-us")}`,
      width / 2,
      100,
      { align: "center" }
    );
    doc.setFontSize(10);

    doc['autoTable'](columns, data, {
      startY: 120,
      showFoot: "everyPage",
      didDrawPage: function (data: any) {
        // Footer
        var str = "Page " + doc.internal["getNumberOfPages"]();

        doc.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 20);
      },
    });

    doc.save(
      `compensation-statements-${
        this.user.memberId
      }-${new Date().toLocaleDateString("en-US")}.pdf`
    );
  }

}
