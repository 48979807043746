<div
  *ngIf="currentStep"
  class="wizard-wrapper"
  [ngClass]="{
    'address-management':
      currentStep.stepName === 'addEditShippingAddress' ||
      currentStep.stepName === 'addEditPaymentMethod' ||
      currentStep.stepName === 'addEditBillingAddress'
  }"
>
  <p-dialog
    #dialog
    *ngIf="dialogCenterVisible"
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [visible]="visible"
    (onHide)="closeDialog()"
    (visibleChange)="closeDialog()"
    [blockScroll]="true"
    header="Header"
  >
    <!-- TODO: check ngStyle does work? -->
    <ng-template pTemplate="header">
      <div class="row w-100">
        <div class="col-12 col-sm-7">
          <span class="title"> {{ currentStep.title }} </span>
        </div>
      </div>
    </ng-template>

    <form *ngIf="form" [formGroup]="form" (submit)="onSubmit($event)">
      <app-select-products-step
        *ngIf="currentStep.stepName === 'selectProducts'"
        [form]="form"
        [user]="user"
        [arpOrder]="arpOrder"
        (onClickNextBtn)="goToNextStep($event)"
      >
      </app-select-products-step>

      <app-configure-order-step
        *ngIf="currentStep.stepName === 'configureOrder'"
        [form]="form"
        [arpOrder]="arpOrder"
        (onClickNextBtn)="goToNextStep($event)"
      >
      </app-configure-order-step>

      <app-select-shipping-address-step
        [form]="form"
        [user]="user"
        [arpOrder]="arpOrder"
        [country]="country"
        (onClickNextBtn)="goToNextStep($event)"
        *ngIf="currentStep.stepName === 'selectShippingAddress'"
      >
      </app-select-shipping-address-step>

      <app-add-edit-shipping-address-step
        [form]="form"
        [user]="user"
        [arpOrder]="arpOrder"
        [country]="country"
        (onClickNextBtn)="goToNextStep($event)"
        (onChangeStepTitle)="changeStepTitle($event)"
        *ngIf="currentStep.stepName === 'addEditShippingAddress'"
      >
      </app-add-edit-shipping-address-step>

      <app-select-payment-method-step
        [form]="form"
        [billingAddress]="user?.billingAddress!"
        [arpOrder]="arpOrder"
        [country]="country"
        *ngIf="currentStep.stepName === 'selectPaymentMethod'"
        [memberId]="user.memberId"
        (onDialogDecentered)="dialog.center()"
        (onClickNextBtn)="goToNextStep($event)"
        (onChangeStepTitle)="changeStepTitle($event)"
      >
      </app-select-payment-method-step>

      <app-add-edit-payment-method-step
        [form]="form"
        [country]="country"
        *ngIf="currentStep.stepName === 'addEditPaymentMethod'"
        [memberId]="user.memberId"
        (onClickNextBtn)="goToNextStep($event)"
        (onChangeStepTitle)="changeStepTitle($event)"
      >
      </app-add-edit-payment-method-step>

      <app-select-billing-address-step
        [form]="form"
        [user]="user"
        [arpOrder]="arpOrder"
        [country]="country"
        *ngIf="currentStep.stepName === 'selectBillingAddress'"
        (onClickNextBtn)="goToNextStep($event)"
      >
      </app-select-billing-address-step>

      <app-add-edit-billing-address-step
        [form]="form"
        [user]="user"
        [arpOrder]="arpOrder"
        [country]="country"
        (onClickNextBtn)="goToNextStep($event)"
        (onChangeStepTitle)="changeStepTitle($event)"
        *ngIf="currentStep.stepName === 'addEditBillingAddress'"
      >
      </app-add-edit-billing-address-step>

      <app-review-your-order-step
        [user]="user"
        [form]="form"
        [arpOrder]="arpOrder"
        [country]="country"
        (onClickNextBtn)="goToNextStep($event)"
        (onSubmitOrder)="onAddOrder.emit($event); closeDialog()"
        *ngIf="currentStep.stepName === 'reviewYourOrderStep'"
      >
      </app-review-your-order-step>
    </form>
  </p-dialog>
</div>
