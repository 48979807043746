import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Customer } from '@models/customer.model';
import { UserInfo } from '@models/user-info.model';
import { AffiliateService } from '@services/affiliate.service';
import { UserService } from '@services/user.service';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactsComponent implements OnInit, OnDestroy {
  @Input() currentUser!: UserInfo;
  @Input() panelCollapsed!: boolean;

  private customersDatatable = {
    pageIndex: 0,
    pageSize: 25,
    sortColumn: null,
    sortOrder: null,
    totalCustomers: null,
  };
  private destroy$ = new Subject<void>();

  public customers!: Customer[];
  public selectedCustomers!: Customer[];
  public isLoading = false;

  constructor(
    private affiliateService: AffiliateService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getContacts();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get contacts from service
  private getContacts(): void {
    this.affiliateService
      .getContacts(
        this.currentUser.memberId,
        this.customersDatatable.pageIndex,
        this.customersDatatable.pageSize,
        this.customersDatatable.sortColumn!,
        this.customersDatatable.sortOrder!
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (contacts: any) => {
          this.customersDatatable.totalCustomers = contacts.totalCustomers;
          this.customers = contacts.customers;
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          if ([401, 403].includes(error.status)) {
            this.userService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: error.message,
            });
          }
        },
      });
  }
}
