<p-dialog
  *ngIf="embedVideoCode"
  header="Embed Code"
  [blockScroll]="true"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [(visible)]="embedCodeDialogIsVisible"
  width="570"
  (onHide)="onDialogHide.emit('embedCodeDialogIsVisible')">
  <textarea
    class="dialog-textarea"
    [ngModel]="embedVideoCode">
  </textarea>
  <div class="row justify-content-between" style="padding: 0 15px">
    <div>
      <button
        style="width: 70px"
        class="submit-btn bg-color"
        type='button'
        ngxClipboard
        [cbContent]="embedVideoCode"
        pTooltip="Copied!"
        tooltipPosition="bottom"
        tooltipEvent="focus"
        [hideDelay]="1500">
        Copy
      </button>
    </div>
    <div>
      <button
        style="width: 70px"
        class="cancel-btn"
        type='button'
        (click)="onDialogHide.emit('embedCodeDialogIsVisible')">
        Close
      </button>
    </div>
  </div>
</p-dialog>
