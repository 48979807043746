<app-spinner
  *ngIf="isLoading"
  [spinnerText]="'Loading...'"
  [disableFullScreen]="true"
></app-spinner>
<div class="panel-wrapper">
  <p-panel
    class="my-referrals"
    id="my-referrals"
    header="My Referrals"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      <button class="btn btn-primary bg-color btn-fullscreen" (click)="showFullScreen = true">Show Full Table</button>
      <div class="table-wrapper">
        <app-referrals-table
          [myReferrals]="myReferrals"
          (handleShowOrder)="showOrders($event)"
        ></app-referrals-table>
      </div>
    </div>
  </p-panel>
</div>

<div *ngIf="showFullScreen" class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showFullScreen"
    [modal]="true"
    [resizable]="true"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
    [position]="'center'"
    [style]="{ width: '90vw', height: '85vh' }"
  >
    <p-header>
      <h2 class="title">Referrals</h2>
    </p-header>

    <div class="table-wrapper">
      <app-referrals-table
        *ngIf="myReferrals"
        [myReferrals]="myReferrals"
        [showOptionalColumn]="true"
        (handleShowOrder)="showOrders($event)"
      ></app-referrals-table>
    </div>
  </p-dialog>
</div>
<div *ngIf="showOrdersModal" class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showOrdersModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
    [position]="'center'"
    [style]="{ width: '90vw', height: '85vh' }"
  >
    <p-header>
      <h2 class="title">Orders</h2>
    </p-header>
    <div class="info">
      <p>
        Referral: {{ selectedReferral.Name }} - ({{ selectedReferral.Account }})
      </p>
    </div>
    <div class="table-wrapper">
      <app-orders-table
        [referralOrders]="referralOrders"
        (handleOrderClick)="showOrderDetails($event)"
      ></app-orders-table>
    </div>
  </p-dialog>
</div>

<div *ngIf="showOrderDetailsModal" class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showOrderDetailsModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
    [position]="'center'"
    [style]="{ width: '90vw', height: '85vh' }"
  >
    <p-header>
      <h2 class="title">Orders Details</h2>
    </p-header>
    <div class="info">
      <p>
        Referral: {{ selectedReferral.Name }} - ({{ selectedReferral.Account }})
      </p>
      <p>Order: {{ selectedOrder.Order }}</p>
    </div>
    <div class="table-wrapper">
      <app-order-detail-table
        [referralOrderDetails]="referralOrderDetails"
      ></app-order-detail-table>
    </div>
  </p-dialog>
</div>
