<div class="contacts-panel-wrapper">
  <p-panel
    header="Contacts"
    [collapsed]="panelCollapsed"
    [toggleable]="true">
    <div class="contact__table" *ngIf="customers">
      <p-table #dt
               [value]="customers"
               [showCurrentPageReport]="true"
               [rowsPerPageOptions]="[10,25,50,75,100]"
               [rows]="10"
               [(selection)]="selectedCustomers"
               [rowHover]="true"
               [paginator]="true"
               [filterDelay]="0"
               [loading]="isLoading"
               [globalFilterFields]="['customerName','addDate','lastOrderDate','lastOrderProductTotal']">
        <ng-template pTemplate="caption">
          <label>
            Contacts
            <input
              pInputText
              type="text"
              (keyup)="dt.filterGlobal($event, 'contains')"/>
          </label>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="customerName">
              <p-sortIcon field="customerName"></p-sortIcon>
              NAME
            </th>
            <th pSortableColumn="addDate">
              <p-sortIcon field="addDate"></p-sortIcon>
              ADD DATE
            </th>
            <th pSortableColumn="lastOrderDate">
              <p-sortIcon field="lastOrderDate"></p-sortIcon>
              LAST ORDER DATE
            </th>
            <th pSortableColumn="lastOrderProductTotal">
              <p-sortIcon field="lastOrderProductTotal"></p-sortIcon>
              LAST ORDER PRODUCT TOTAL
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
          <tr>
            <td>{{customer.customerName}}</td>
            <td>{{customer.addDate | date}}</td>
            <td>{{customer.lastOrderDate | date}}</td>
            <td>{{customer.lastOrderProductTotal | currency}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
