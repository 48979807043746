import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Country } from "@models/country.model";
import { PaymentService } from "@services/payment.service";
import { WizardHelperService } from "@services/wizard-helper.service";
import { MessageService, SelectItem } from "primeng/api";



@Component({
  selector: "app-add-edit-payment-method-step",
  templateUrl: "./add-edit-payment-method-step.component.html",
  styleUrls: ["./add-edit-payment-method-step.component.scss"],
})
export class AddEditPaymentMethodStepComponent implements OnInit {
  @Input() country!: Country;
  @Input() form!: FormGroup;
  @Input() memberId!: string;

  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onChangeStepTitle = new EventEmitter<string>();

  public creditCardsTypes: SelectItem[] = [];
  public dialogContentMaxWidth = window.innerWidth - 300;

  private backStepName!: string;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onWindowResize(event:any) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private paymentService: PaymentService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getPaymentOptions();
    this.getCardList();
  }

  // defined next  or preview step after filling out creditCardForm
  public changeStep(): void {
    this.onClickNextBtn.emit(this.backStepName);
  }

  // get all cards for this memberId and set card list to object
  private getCardList(): void {
    this.wizardHelperService.cardListSubject.subscribe((cards) => {
      this.backStepName =
        cards && cards.length ? "selectPaymentMethod" : "selectShippingAddress";
    });
  }

  // get credit card options for add/edit card form
  private getPaymentOptions(): void {
    this.paymentService.getCardTypes().subscribe({
      next: (response) => {
        const creditCardsTypes = response.countries[2].cardTypes;
        for (let i = 0; i < creditCardsTypes.length; i++) {
          this.creditCardsTypes.push({
            value: creditCardsTypes[i].value.toString(),
            label: creditCardsTypes[i].displayName,
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: "error",
          summary: error.message,
        });
      }
  });
  }

  // move to next wizard step
  public finishThisStep(): void {
    this.onClickNextBtn.emit("selectBillingAddress");
  }
}
