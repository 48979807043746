<div class="row w-100 personal-info-block">

  <app-arp-order-address-view
    class="col-12 col-md-5 col-lg-4"
    title="Shipping Address"
    [address]="order.shippingAddress || order.shippingAddressId"
    [country]="country"
    (onOpenAddressManagement)="openAddressManagement('shipping')"
  ></app-arp-order-address-view>

  <app-arp-order-card-view
    class="col-12 col-md-7 col-lg-5"
    [payment]="order.payment"
    (onOpenPaymentManagement)="openAddressManagement(null); openPaymentManagement()">
  </app-arp-order-card-view>

  <app-arp-order-address-view
    class="col-12 col-md-6 col-lg-3"
    title="Billing Address"
    [address]="order.billingAddress || order.billingAddressId"
    [country]="country"
    (onOpenAddressManagement)="openAddressManagement('billing')"
  ></app-arp-order-address-view>

</div>
