<app-spinner *ngIf="isLoading"></app-spinner>


<!-- contact-form.component.html -->
<form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="form-container">
  <!-- Personal Information -->
  <div class="form-section">
    <h4 class="title">Personal Information</h4>
    <div class="form-group">
      <label for="firstName">First Name:</label>
      <input type="text" formControlName="firstName" id="firstName" required />
      <div
        *ngIf="
          contactForm?.get('firstName')?.invalid &&
          contactForm?.get('firstName')?.touched
        "
        class="error-msg"
      >
        First name is required.
      </div>
    </div>
    <div class="form-group">
      <label for="lastName">Last Name:</label>
      <input type="text" formControlName="lastName" id="lastName" required />
      <div
        *ngIf="
          contactForm?.get('lastName')?.invalid &&
          contactForm?.get('lastName')?.touched
        "
        class="error-msg"
      >
        Last name is required.
      </div>
    </div>
    <div class="form-group">
      <label for="phone">Phone:</label>
      <input type="tel" formControlName="phone" id="phone" required />
      <div
        *ngIf="
          contactForm?.get('phone')?.invalid &&
          contactForm?.get('phone')?.touched
        "
        class="error-msg"
      >
        Phone is required.
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email:</label>
      <input type="text" formControlName="email" id="email" />
    </div>
  </div>
  <!-- Shipping Address -->
  <div class="form-section">
    <h4 class="title">Shipping Address</h4>
    <div class="form-group">
      <label for="shippingFullName">Full Name:</label>
      <input
        type="text"
        formControlName="shippingFullName"
        id="shippingFullName"
        required
      />
      <div
        *ngIf="
          contactForm?.get('shippingFullName')?.invalid &&
          contactForm?.get('shippingFullName')?.touched
        "
        class="error-msg"
      >
      Full name is required.
      </div>
    </div>
    <div class="form-group">
      <label for="shippingAddressLine1">Address Line 1:</label>
      <input
        type="text"
        formControlName="shippingAddressLine1"
        id="shippingAddressLine1"
        required
      />
      <div
        *ngIf="
          contactForm?.get('shippingAddressLine1')?.invalid &&
          contactForm?.get('shippingAddressLine1')?.touched
        "
        class="error-msg"
      >
      Address line 1 is required.
      </div>
    </div>
    <div class="form-group">
      <label for="shippingAddressLine2">Address Line 2:</label>
      <input
        type="text"
        formControlName="shippingAddressLine2"
        id="shippingAddressLine2"
      />
    </div>
    <div class="form-group">
      <label for="shippingCity">City:</label>
      <input
        type="text"
        formControlName="shippingCity"
        id="shippingCity"
        required
      />
      <div
        *ngIf="
          contactForm?.get('shippingCity')?.invalid &&
          contactForm?.get('shippingCity')?.touched
        "
        class="error-msg"
      >
      City is required.
      </div>
    </div>
    <div class="form-group">
      <label for="shippingState">State:</label>
      <select
        type="text"
        formControlName="shippingState"
        id="shippingState"
        required
      >
        <option
          *ngFor="let state of selectedShippingCountry?.states"
          [value]="state.value"
        >
          {{ state.displayName }}
        </option>
      </select>
      <div
        *ngIf="
          contactForm?.get('shippingState')?.invalid &&
          contactForm?.get('shippingState')?.touched
        "
        class="error-msg"
      >
      State is required.
      </div>
    </div>
    <div class="form-group">
      <label for="shippingZip">Zip:</label>
      <input
        type="text"
        formControlName="shippingZip"
        id="shippingZip"
        [dropSpecialCharacters]="false"
        [showMaskTyped]="true"
        [mask]="shippingPostalCodeMask"
      />
      <div
        *ngIf="
          contactForm?.get('shippingZip')?.invalid &&
          contactForm?.get('shippingZip')?.touched
        "
        class="error-msg"
      >
      Zip is required.
      </div>
    </div>

    <div class="form-group">
      <label for="shippingCountry">Country:</label>
      <select
        type="text"
        formControlName="shippingCountry"
        id="shippingCountry"
      >
        <option *ngFor="let country of countries" [value]="country?.value">
          {{ country?.displayName }}
        </option>
      </select>
      <div
        *ngIf="
          contactForm?.get('shippingCountry')?.invalid &&
          contactForm?.get('shippingCountry')?.touched
        "
        class="error-msg"
      >
      Country is required.
      </div>
    </div>
  </div>

  <div class="form-section">
    <!-- Billing Address -->
    <h4 class="title">Billing Address</h4>

    <div class="form-group">
      <label>
        <input
          type="checkbox"
          formControlName="sameAsShipping"
          (change)="copyShippingAddress($event)"
        />
        Same as Shipping Address
      </label>
    </div>

    <div class="form-group">
      <label for="billingFullName">Full Name:</label>
      <input
        type="text"
        formControlName="billingFullName"
        id="billingFullName"
        required
      />
      <div
        *ngIf="
          contactForm?.get('billingFullName')?.invalid &&
          contactForm?.get('billingFullName')?.touched
        "
        class="error-msg"
      >
      Full name is required.
      </div>
    </div>
    <div class="form-group">
      <label for="billingAddressLine1">Address Line 1:</label>
      <input
        type="text"
        formControlName="billingAddressLine1"
        id="billingAddressLine1"
        required
      />
      <div
        *ngIf="
          contactForm?.get('billingAddressLine1')?.invalid &&
          contactForm?.get('billingAddressLine1')?.touched
        "
        class="error-msg"
      >
      Address line 1 is required.
      </div>
    </div>
    <div class="form-group">
      <label for="billingAddressLine2">Address Line 2:</label>
      <input
        type="text"
        formControlName="billingAddressLine2"
        id="billingAddressLine2"
      />
    </div>
    <div class="form-group">
      <label for="billingCity">City:</label>
      <input
        type="text"
        formControlName="billingCity"
        id="billingCity"
        required
      />
      <div
        *ngIf="
          contactForm?.get('billingCity')?.invalid &&
          contactForm?.get('billingCity')?.touched
        "
        class="error-msg"
      >
      City is required.
      </div>
    </div>
    <div class="form-group">
      <label for="billingState">State:</label>
      <select type="text" formControlName="billingState" id="billingState">
        <option
          *ngFor="let state of selectedBillingCountry?.states"
          [value]="state.value"
        >
          {{ state.displayName }}
        </option>
      </select>
      <div
        *ngIf="
          contactForm?.get('billingState')?.invalid &&
          contactForm?.get('billingState')?.touched
        "
        class="error-msg"
      >
      State is required.
      </div>
    </div>
    <div class="form-group">
      <label for="billingZip">Zip:</label>
      <input
        type="text"
        formControlName="billingZip"
        id="billingZip"
        [dropSpecialCharacters]="false"
        [showMaskTyped]="true"
        [mask]="billingPostalCodeMask"
      />
      <div
        *ngIf="
          contactForm?.get('billingZip')?.invalid &&
          contactForm?.get('billingZip')?.touched
        "
        class="error-msg"
      >
      Zip is required.
      </div>
    </div>
    <div class="form-group">
      <label for="billingCountry">Country:</label>
      <select
        type="text"
        formControlName="billingCountry"
        id="billingCountry"
        required
      >
        <option *ngFor="let country of countries" [value]="country?.value">
          {{ country?.displayName }}
        </option>
      </select>
      <div
        *ngIf="
          contactForm?.get('billingCountry')?.invalid &&
          contactForm?.get('billingCountry')?.touched
        "
        class="error-msg"
      >
      Country is required.
      </div>
    </div>
  </div>

  <button type="submit" [disabled]="contactForm.invalid" class="submit-btn">
    Save
  </button>
</form>
