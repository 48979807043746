<div class="panel-wrapper comp-info-wrapp">
  <p-panel
    id="compensation-info"
    header="Compensation Information"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    (onAfterToggle)="panelToggled.emit($event)">

    <div class="row panel-block">
      <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 panel-label">
        Payments
      </div>
      <div class="panel-input col-xs-12 col-sm-4 col-md-3 col-lg-2">
        <p-calendar
          [(ngModel)]="paymentsDatesRange[0]"
          dateFormat="M d, yy"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="calendarInputYearRange"
          (onSelect)="filteredPayments = filter(payments, 'paymentDate', paymentsDatesRange);">
        </p-calendar>
      </div>
      <div class="panel-input col-xs-12 col-sm-4 col-md-3 col-lg-2">
        <p-calendar
          [(ngModel)]="paymentsDatesRange[1]"
          dateFormat="M d, yy"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="calendarInputYearRange"
          (onSelect)="filteredPayments = filter(payments, 'paymentDate', paymentsDatesRange);">
        </p-calendar>
      </div>
    </div>

    <div class="panel-table-block">
      <div class="compensation-info-payments-wrapper">
        <!--        <p-dataTable -->
        <!--            [totalRecords]="filteredPayments.length" -->
        <!--            [value]="filteredPayments"-->
        <!--            [responsive]="true">-->
        <!--          <p-column field="paymentDate" header="Date" [style]="{ 'width': '50%' }">-->
        <!--            <ng-template let-col let-payment="rowData" pTemplate="body">-->
        <!--              {{ payment[col.field] | date }}-->
        <!--            </ng-template>-->
        <!--          </p-column>-->
        <!--          <p-column field="paymentAmount" header="Amount"></p-column>-->
        <!--        </p-dataTable>-->
      </div>
    </div>

    <div *ngIf="filteredPayments?.length" class="row total-row">
      <div class="col-sm-6">
        <p>Total:</p>
      </div>
      <div class="col-sm-6">
        <p>$ {{ countSum(filteredPayments, 'paymentAmount') | number : '1.2-2' }}</p>
      </div>
    </div>
    <!-- Company (business) information block -->
    <div class="business-info-block">
      <div class='title-info'>
        <p class="col-sm-12">Company Information</p>
      </div>

      <!-- View company (business) info -->
      <div class="container">
        <div *ngIf="!isEditing.business" class="row margin-bottom-30">
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Company Name</p>
            <p class="col-12 panel-content-value">{{ info.business?.companyName }}</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Business Type</p>
            <p class="col-12 panel-content-value">{{ info.business?.businessType }}</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Tax ID</p>
            <p class="col-12 panel-content-value">{{ info.business?.taxId }}</p>
          </div>
        </div>
        <div *ngIf="!isEditing.business" class="edit-btn edit-btn--payments text-color"
             (click)='forms.business && editBusinessInfo()'>Edit
        </div>
      </div>

      <!-- Edit company (business) info -->
      <div *ngIf='forms.business && isEditing.business' class="form-container">
        <p class="edit-mode-text text-color">Edit mode. Please click save button to apply changes.</p>
        <form class="container" [formGroup]="forms.business" (ngSubmit)="onSubmit('business')">
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(forms.business, 'companyName', submitted.business)}">
              <label class="col-12 panel-content-title text-uppercase">Company Name</label>
              <input
                class="col-12"
                type='text'
                placeholder='Company Name'
                formControlName="companyName"/>
              <p *ngIf="showError(forms.business, 'companyName', submitted.business)">Please enter Company Name.</p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(forms.business, 'businessType', submitted.business)}">
              <label class="col-12 panel-content-title text-uppercase">Business Type</label>
              <p-dropdown
                [placeholder]="info.business.businessType"
                [options]="businessTypeOptions"
                formControlName="businessType"
                (ngModelChange)="changeBusinessType($event)">
              </p-dropdown>
              <p *ngIf="showError(forms.business, 'businessType', submitted.business)">Please enter Business Type.</p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(forms.business, 'taxId', submitted.business)}">
              <label class="col-12 panel-content-title text-uppercase">Tax ID</label>
              <input
                class="col-12"
                type='text'
                placeholder='Tax ID'
                formControlName="taxId"
                [mask]="taxIdMask"/>
              <p *ngIf="showError(forms.business, 'taxId', submitted.business)">Please enter Tax ID.</p>
            </div>
          </div>
          <div class="margin-bottom-30 row justify-content-end">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <button class="submit-btn bg-color" type='submit'>Save</button>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
              <button class="cancel-btn" type='button' (click)="isEditing.business = false">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- Banking account information block -->
    <div *ngIf="info.banking" class="banking-info-block">
      <div class='title-info'>
        <p class="col-sm-12">Bank Account Information</p>
      </div>

      <!-- View banking account info -->
      <div class="container">

        <div *ngIf="!isEditing.banking && info.banking.usRoutingNumber" class="row">
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Routing Number</p>
            <p class="col-12 panel-content-value">{{ info.banking?.usRoutingNumber }}</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Account Number</p>
            <p class="col-12 panel-content-value">{{ info.banking?.accountNumber }}</p>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Account Type</p>
            <p class="col-12 panel-content-value">{{ info.banking?.accountType }}</p>
          </div>
        </div>

        <div *ngIf="!isEditing.banking && (info.banking.caTransit || info.banking.caInstitution)" class="row">
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Transit</p>
            <p class="col-12 panel-content-value">{{ info.banking?.caTransit }}</p>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Institution</p>
            <p class="col-12 panel-content-value">{{ info.banking?.caInstitution }}</p>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Account Number</p>
            <p class="col-12 panel-content-value">{{ info.banking?.accountNumber }}</p>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 row margin-bottom-30">
            <p class="col-12 panel-content-title text-uppercase">Account Type</p>
            <p class="col-12 panel-content-value">{{ info.banking?.accountType }}</p>
          </div>
        </div>
        <div *ngIf="!isEditing.banking" class="edit-btn text-color" (click)='forms.banking && isEditing.banking = true'>
          Edit
        </div>
      </div>

      <!-- Edit banking account info -->
      <div *ngIf='isEditing.banking' class="form-container">
        <p class="edit-mode-text text-color">Edit mode. Please click save button to apply changes.</p>
        <form [formGroup]="forms.banking" (ngSubmit)="onSubmit('banking')">

          <div class="row">
            <div
              class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
              [ngClass]="{ 'wrong-input': showError(forms.banking, 'caTransit', submitted.banking)}"
              *ngIf="info.banking.defaultCountry === 'CA'">
              <label class="col-12 panel-content-title text-uppercase">Transit</label>
              <input
                class="col-12"
                type='text'
                placeholder='Transit'
                formControlName="caTransit"/>
              <p *ngIf="showError(forms.banking, 'caTransit', submitted.banking)">Please enter Transit.</p>
            </div>
            <div
              class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
              [ngClass]="{ 'wrong-input': showError(forms.banking, 'caInstitution', submitted.banking)}"
              *ngIf="info.banking.defaultCountry === 'CA'">
              <label class="col-12 panel-content-title text-uppercase">Institution</label>
              <input
                class="col-12"
                type='text'
                placeholder='Institution'
                formControlName="caInstitution"/>
              <p *ngIf="showError(forms.banking, 'caInstitution', submitted.banking)">Please enter Institution.</p>
            </div>
            <div
              class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
              [ngClass]="{ 'wrong-input': showError(forms.banking, 'usRoutingNumber', submitted.banking)}"
              *ngIf="info.banking.defaultCountry === 'US'">
              <label class="col-12 panel-content-title text-uppercase">Routing Number</label>
              <input
                class="col-12"
                type='text'
                placeholder='Routing Number'
                formControlName="usRoutingNumber"/>
              <p *ngIf="showError(forms.banking, 'usRoutingNumber', submitted.banking)">Please enter Routing Number.</p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(forms.banking, 'accountNumber', submitted.banking)}">
              <label class="col-12 panel-content-title text-uppercase">Account Number</label>
              <input
                class="col-12"
                type='text'
                placeholder='Account Number'
                formControlName="accountNumber"/>
              <p *ngIf="showError(forms.banking, 'accountNumber', submitted.banking)">Please enter Account Number.</p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(forms.banking, 'accountType', submitted.banking)}">
              <label class="col-12 panel-content-title text-uppercase">Account Type</label>
              <p-dropdown
                [placeholder]="info.banking.accountType"
                [options]="accountTypeOptions"
                formControlName="accountType">
              </p-dropdown>
              <p *ngIf="showError(forms.banking, 'accountType', submitted.banking)">Please enter Account Type.</p>
            </div>
          </div>

          <div class="margin-bottom-30 row justify-content-end">
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              <button class="submit-btn bg-color" type='submit'>Save</button>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
              <button class="cancel-btn" type='button' (click)="isEditing.banking = false">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>

  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
