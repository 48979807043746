<div class="search-input">
  <i class="fa fa-search" aria-hidden="true"></i>
  <input
    placeholder="Search"
    [formControl]="queryName"

  />
</div>

<div *ngIf="filteredProductsItems" class='carousel-block'>
  <p-carousel [value]="filteredProductsItems" [numVisible]="4" [numScroll]="1" [responsiveOptions]="responsiveOptions" [showIndicators]="true">
    <ng-template let-productItem pTemplate="item">
      <div class="ui-grid ui-grid-responsive item-details">
        <div
          class="pointer"
          (click)="productItem.checked = !productItem.checked; handleChange(productItem, productItem.product)">
          <img [src]="productItem.product.cartImage"/>
        </div>
        <div
          class="pointer"
          (click)="productItem.checked = !productItem.checked; handleChange(productItem, productItem.product)">
          {{ productItem.product.productName }}
        </div>
        <div style="text-align: center">{{ productItem.product.price | currency:'USD':'symbol' }}</div>
        <div class='action-btn-block col-12 d-flex justify-content-start'>
          <div class="col-12 select-toggle-btn">
            <p-toggleButton
              class="select-btn"
              [(ngModel)]="productItem.checked"
              (onChange)="handleChange($event, productItem.product)"
              onLabel="Selected"
              offLabel="Select">
            </p-toggleButton>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</div>

<app-spinner *ngIf="isLoading" [spinnerText]="spinnerText" [disableFullScreen]="true"></app-spinner>
<p-toast></p-toast>
