import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { CompensationService } from '@services/compensation.service';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-create-first-store',
  templateUrl: './create-first-store.component.html',
  styleUrls: ['./create-first-store.component.css']
})
export class CreateFirstStoreComponent implements OnInit {
  panelCollapsed = true;
  isLoading = false;

  storeCreateSettingsFormGroup!: FormGroup;
  storeCreateErrorMessages: string[] = [];
  showCreateStoreModal = false;
  isSiteAddressUsedError = false;

  user;

  isTermsAccepted = true;
  constructor(
    private _fb: FormBuilder,
    private storeService: CompensationService,
    private authService: UserService,
    private store: Store<{ wellnessStore: any }>,
  ) {
    this.user = authService.getCurrentUser();

  }
  ngOnInit(): void {
  }

  openCreateStoreModal() {
    this.storeCreateSettingsFormGroup = this.createStoreFormGroup();
    this.showCreateStoreModal = true;
  }

  createStoreFormGroup() {
    return this._fb.group({
      Title: ["", Validators.required],
      SiteAddress: ["", Validators.required],
      Story: ["", Validators.required],
      Color: ["orange", Validators.required],
    });
  }

  createStore() {
    if (this.storeCreateSettingsFormGroup.invalid) {
      this.storeCreateErrorMessages.push(
        "Please fill all the required fields."
      );
      return;
    }
    this.storeCreateErrorMessages = [];
    this.isSiteAddressUsedError = false;

    this.isLoading = true;
    let settings = this.storeCreateSettingsFormGroup.value;

    const data = {
      ID: 0,
      ParentID: 0,
      Customer: {
        ID: this.user.memberId,
      },
      Settings: settings,
    };

    this.storeService
      .createStore(data)
      .pipe(
        catchError((err, caught) => {
          console.log(err);
          this.isLoading = false;

          if (err && err.error?.ModelState["Settings.SiteAddress"]) {
            this.isSiteAddressUsedError = true;
            this.storeCreateErrorMessages.push(
              `This site address ${settings.SiteAddress} is already used.`
            );
          } else {
            this.storeCreateErrorMessages.push(
              "Something went wrong. Try again later"
            );
          }
          return EMPTY;
        })
      )
      .subscribe((res) => {
        this.isLoading = false;
        if (res) {
          this.showCreateStoreModal = false;
          this.storeCreateSettingsFormGroup.reset();
          this.authService.firstStoreCreatedSubject$.next(true);
        }
      });
  }
}
