<div class='panel-wrapper'>
  <p-panel
    header="Contact Information"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

    (onAfterToggle)="onPanelToggle($event)">

    <div class='contacts-block'>
      <div class="container">
        <div *ngIf='!isEditing && country'
             class="row w-100 d-flex justify-content-lg-around">
          <div class="col-lg-4 col-md-6 col-sm-12 row margin-bottom-30 align-content-start">
            <p class="col-12 panel-content-title"> Phone
              <span *ngIf="!isEditing" class="text-color pointer margin-left"
                    (click)="startEditing(); phoneEditing=true">Change</span>
            </p>
            <p class="col-12 panel-content-value margin-bottom-30">
              <input
                readonly="readonly"
                [ngModel]="contactInformationEditForm.value['phone'] | mask: '(000) 000-0000'"
                [ngModelOptions]="{standalone: true}"
                style="border: none; padding: 0"
                >
            </p>
            <p class="col-12 panel-content-title">Email
              <!-- <span *ngIf="!isEditing" class="text-color pointer margin-left"
                    (click)="startEditing(); emailEditing=true">Change</span> -->
            </p>
            <p class="col-12 panel-content-value"> {{ contactInformationEditForm.value.email  }}</p>
          </div>
          <div  class="col-lg-4 col-md-6 col-sm-12 row margin-bottom-30 align-content-start">
            <p class="col-12 panel-content-title"> Shipping Address
              <span class="text-color pointer margin-left"
                    (click)="editAddress('isEditingShippingAddress')">{{defaultShippingAddress ? 'Change' : 'Add Shipping Address'}}</span>
            </p>

            <ng-container *ngIf="defaultShippingAddress">
            <p class="col-12 panel-content-value"> {{ defaultShippingAddress?.addressName }} </p>
            <p class="col-12 panel-content-value"> {{ defaultShippingAddress?.address1 }} </p>
            <p *ngIf="defaultShippingAddress?.address2"
               class="col-12 panel-content-value"> {{ defaultShippingAddress?.address2 }} </p>
            <p class="col-12 panel-content-value"> {{ defaultShippingAddress?.city }}
              , {{ defaultShippingAddress?.postalCode }}  </p>
            <p class="col-12 panel-content-value"> {{ defaultShippingAddress?.state | stateName : country?.states }}
              , {{ country?.displayName }} </p>
            </ng-container>
          </div>

          <div class="col-lg-4 col-md-6 col-sm-12 row margin-bottom-30 align-content-start">
            <p class="col-12 panel-content-title">Billing Address
              <span class="text-color pointer margin-left"
                    (click)="editAddress('isEditingBillingAddress')">{{defaultBillingAddress ? 'Change' : 'Add Shipping Address'}}</span>
            </p>

            <ng-container *ngIf="defaultBillingAddress">
            <p class="col-12 panel-content-value"> {{ defaultBillingAddress?.addressName }} </p>
            <p class="col-12 panel-content-value"> {{ defaultBillingAddress?.address1 }} </p>
            <p *ngIf="defaultBillingAddress?.address2"
               class="col-12 panel-content-value"> {{ defaultBillingAddress?.address2 }} </p>
            <p class="col-12 panel-content-value"> {{ defaultBillingAddress?.city }}
              , {{ defaultBillingAddress.postalCode }}  </p>
            <p class="col-12 panel-content-value"> {{ defaultBillingAddress?.state | stateName : country?.states }}
              , {{ country?.displayName }}  </p>
            </ng-container>

          </div>
        </div>
      </div>

      <!-- Edit contacts info -->
      <div *ngIf='isEditing && contactInformationEditForm' class="form-container">
        <p class="edit-mode-text text-color">Please click save button to apply changes.</p>
        <form class="container" [formGroup]="contactInformationEditForm" (ngSubmit)="onSubmit()">
          <div class="row w-100">
            <div *ngIf="phoneEditing" class="col-md-3 col-sm-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(contactInformationEditForm, 'phone', submitted)}">
              <label class="col-12 panel-content-title">Phone</label>
              <input
                class="col-12"
                type='phone'
                type="text"
                placeholder='Phone'
                formControlName="phone"
                mask="(000) 000-0000"
                [dropSpecialCharacters]="false"
                [showMaskTyped]="true"
                (keydown)="setPhonePatternValidator('phone')"/>
              <p *ngIf="showError(contactInformationEditForm, 'phone', submitted)">Please enter Phone.</p>
            </div>
            <div *ngIf="emailEditing" class="col-md-3 col-sm-12 margin-bottom-30"
                 [ngClass]="{ 'wrong-input': showError(contactInformationEditForm, 'email', submitted)}">
              <label class="col-12 panel-content-title">Email</label>
              <input
                class="col-12"
                type='text'
                placeholder='Email'
                formControlName="email"/>
              <p *ngIf="showError(contactInformationEditForm, 'email', submitted)">Please enter Email.</p>
              <p *ngIf="existingEmail" class="error-message"> This email already exists.</p>
            </div>
          </div>

          <div class="row w-100 justify-content-start">
            <div class="col-md-3 col-sm-12 margin-bottom-30">
              <button
                class="submit-btn bg-color"
                type='submit'
                [disabled]="existingEmail || !!contactInformationEditForm.pristine || saveButtonDisabled || contactInformationEditForm.invalid">
                Save
              </button>
            </div>
            <div class="col-md-3 col-sm-12 margin-bottom-30">
              <button class="cancel-btn" type='button' (click)="cancelEditing()">Cancel</button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <app-address-list
      *ngIf="isEditingBillingAddress || isEditingShippingAddress"
      [isEditingBillingAddress]="isEditingBillingAddress"
      [country]='country'
      [memberId]='currentUser.memberId'
      [form]="contactInformationEditForm"
      [user]="currentUser"
      (onCloseDialog)="closeEditingAddressDialog()"
      (onSelectAddress)="getAddresses()"
      (onShowNotification)="showNotification($event)">
    </app-address-list>
  </p-panel>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [disableFullScreen]="true"
  [spinnerText]="spinnerText"
></app-spinner>
