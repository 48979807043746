import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { LocalStorage } from "@helpers/local-storage.decorator";


@Injectable()
export class SubscriptionGuard implements CanActivate {
  @LocalStorage() storeID!:string;

  constructor(private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): boolean {
    if (this.storeID) {
      return true;
    } else {
      this.router.navigateByUrl("/main");
      return false;
    }
  }
}
