<p-table
  class="referral-table"
  [value]="referralOrderDetails"
  [styleClass]="'p-datatable-sm'"
  [tableStyle]="{ 'min-width': '60rem' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="Product">
        Product ID <p-sortIcon field="Product"></p-sortIcon>
      </th>
      <th pSortableColumn="Description">
        Description <p-sortIcon field="Description"></p-sortIcon>
      </th>
      <th pSortableColumn="Quantity">
        Quantity <p-sortIcon field="Quantity"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="Price">
        Price <p-sortIcon field="Price"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="Extended">
        Extended <p-sortIcon field="Extended"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-detail>
    <tr>
      <td>{{ detail.Product }}</td>
      <td>{{ detail.Description }}</td>
      <td>{{ detail.Quantity }}</td>
      <td class="money">
        {{ detail.Price ? "$" + detail.Price.toFixed(2) : "-" }}
      </td>
      <td class="money">
        {{ detail.Extended ? "$" + detail.Extended.toFixed(2) : "-" }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr class="total-row">
      <td colspan="3">Total</td>
      <td class="money">${{ priceTotal ? priceTotal.toFixed(2) : 0 }}</td>
      <td class="money">${{ extendedTotal ? extendedTotal.toFixed(2) : 0 }}</td>
    </tr>
  </ng-template>
</p-table>
