import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Address, ArpOrder } from "@models/arp-order.model";
import { UserInfo } from "@models/user-info.model";
import { Dialog } from "primeng/dialog";


@Component({
  selector: "app-arp-order-editing",
  templateUrl: "./arp-order-editing.component.html",
  styleUrls: ["./arp-order-editing.component.scss"],
})
export class ArpOrderEditingComponent implements OnInit {
  @ViewChild("dialog", { static: true }) dialog!: Dialog;
  @Input() editableOrder!: any;
  @Input() country!: any;
  @Input() form!: FormGroup;
  @Input() isProductManagement!: boolean;
  @Input() isPaymentManagement!: boolean;
  @Input() typeOfAddress!: string;
  @Input() visible!: boolean;
  @Input() user!: UserInfo;

  @Output() onCloseEditingDialog: EventEmitter<null> = new EventEmitter();
  @Output() onProductListUpdated: EventEmitter<ArpOrder> = new EventEmitter();
  @Output() onShowNotification: EventEmitter<any> = new EventEmitter();

  public isAddingEditingAddress!: boolean;
  public editOrderForm!: FormGroup;
  public editableAddress!: Address;
  public title!: string;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.buildForm();

    this.title = this.typeOfAddress
      ? "Address Management"
      : "Payment Management";
    if (this.isProductManagement) {
      this.title = "Product Management";
    }
  }

  private buildForm(): void {
    this.editOrderForm = this.formBuilder.group({
      memberId: [this.editableOrder.memberId],
      orderId: [this.editableOrder.orderId],
      countryId: [this.editableOrder.countryId],
      products: [this.editableOrder.products],
      dayOfMonthToShip: [this.editableOrder.dayOfMonthToShip],
      interval: [this.editableOrder.interval],
      autoApplyVitaPoints: [this.editableOrder.autoApplyVitaPoints],
      shippingAddressId: [this.editableOrder.shippingAddress.addressId],
      billingAddressId: [this.editableOrder.billingAddress.addressId],
      payment: [this.editableOrder.payment],
      previewOnly: [false],
    });
  }

  public addEditAddress(event: any): void {
    this.isAddingEditingAddress = event;
  }

  public selectAddresToEditing(event: any): void {
    this.editableAddress = event;
  }

  public closeDialog(): void {
    this.editableOrder = null;
    this.typeOfAddress = '';
    this.onCloseEditingDialog.emit();
  }
}
