import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Country } from '@models/country.model';
import { UserInfo } from '@models/user-info.model';
import { ArpOrder } from '@models/arp-order.model';
import { ArpOrderService, IntervalOptions } from '@services/arp-order.service';
import { AddressService } from '@services/address.service';
import { UserService } from '@services/user.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-auto-replenishment-program',
  templateUrl: './auto-replenishment-program.component.html',
  styleUrls: ['./auto-replenishment-program.component.scss'],
})
export class AutoReplenishmentProgramComponent implements OnInit, OnDestroy {
  @Input() country!: Country;
  @Input() panelCollapsed!: boolean;
  @Input() user!: UserInfo;

  public isLoading!: boolean;
  public orderList!: ArpOrder[];
  public wizardDialogIsOpen!: boolean;
  public spinnerText!: string;
  public arpConfiguration!: IntervalOptions;

  private sub!: Subscription;

  constructor(
    private addressService: AddressService,
    private arpOrderService: ArpOrderService,
    private userService: UserService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.getConfigurationOptions();
    this.getArpOrders();
    this.getAddresses();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // get order list for current user
  private getArpOrders(): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.getArpOrders(this.user.memberId).subscribe({
      next: ({ arpOrders }) => {
        this.orderList = arpOrders;
        this.wizardHelperService.setOrderList(arpOrders);
        this.sub = this.wizardHelperService.orderListSubject.subscribe(
          (orders) => {
            this.orderList = orders;
          }
        );
        this.isLoading = false;
        this.spinnerText = '';
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.spinnerText = '';
        if ([401, 403].includes(error.status)) {
          this.userService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        }
      }
  });
  }

  // get all addresses from api and set them to subject
  private getAddresses(): void {
    this.addressService.getAllAddresses(this.user.memberId).subscribe({
      next: (data) => {
        const {addresses} = JSON.parse(data);
        this.wizardHelperService.setAddressList(addresses);
      },
      error: (error: HttpErrorResponse) => {
        if ([401, 403].includes(error.status)) {
         // this.userService.logout();
        } else {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        }
      },
    });
  }

  // get arp configuration options from service
  private getConfigurationOptions(): void {
    if (this.arpOrderService.arpOrderOptions) {
      this.arpConfiguration = this.arpOrderService.arpOrderOptions;
    } else {
      this.arpOrderService.setConfigurationOptions().subscribe((response) => {
        this.arpConfiguration = response;
      });
    }
  }

  // update order list
  public onAddOrderHandler(orders: ArpOrder[]): void {
    if (orders === null) {
      return;
    } else {
      this.orderList = orders;
    }
  }

  /**
   * Arp order panel toggle handler
   * @param event - dom event object
   */
  public onPanelToggle({ collapsed }: any): void {
    if (!collapsed) {
    }
  }
}
