<div
  class='wizard-step-content'
  [ngStyle]="{
      'height': '800px',
      'overflow-y': 'auto',
      'overflow-x': 'hidden',
      'width': dialogContentMaxWidth,
      'max-width': '950px',
      'padding-bottom':'60px'
    }">

  <div *ngIf="form" [formGroup]="form">
    <app-available-products
      [countryId]="user.billingAddress?.countryId!"
      [selectedProducts]="form.controls['products']?.value"
      (onSelectProduct)="addSelectedProduct($event)"
      (onUnSelectProduct)="removeSelectedProduct($event)">
    </app-available-products>

    <div class="note">Note: Price displayed do not reflect any current offers. Auto Replenishment Program orders are
      guaranteed to be the lowest catalog price at the time of shipment.
    </div>
    <div class='title'>Your Selected Products</div>


    <app-arp-order-products
      [form]="form"
      [order]="null"
      [memberId]="user.memberId"
      wizardStepName="selectProducts"
      (onUnSelectProduct)="removeSelectedProduct($event)"
      mode="add"
    ></app-arp-order-products>

  </div>

  <div class='footer-fixed'>
    <button
      class="submit-btn bg-color pointer"
      (click)="finishThisStep()"
      [disabled]="!form.value.products?.length">
      Next
    </button>
  </div>
</div>
