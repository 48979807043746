<p-table
  class="referral-table"
  [value]="myReferrals"
  [styleClass]="'p-datatable-sm'"
  [tableStyle]="{ 'min-width': '60rem', 'max-height': '70vh' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="Name">
        Name <p-sortIcon field="Name"></p-sortIcon>
      </th>
      <th
        *ngIf="showOptionalColumn"
        pSortableColumn="Account"
        style="width: 15%"
      >
        ID <p-sortIcon field="Account"></p-sortIcon>
      </th>
      <th *ngIf="showOptionalColumn" pSortableColumn="Date">
        Date <p-sortIcon field="Date"></p-sortIcon>
      </th>
      <th pSortableColumn="Orders">
        Orders <p-sortIcon field="Orders"></p-sortIcon>
      </th>
      <th pSortableColumn="LastOrder">
        Last Order <p-sortIcon field="LastOrder"></p-sortIcon>
      </th>
      <th *ngIf="showOptionalColumn" pSortableColumn="Store">
        Store ID <p-sortIcon field="Store"></p-sortIcon>
      </th>
      <th pSortableColumn="Url" style="width: 20%;">Url <p-sortIcon field="Url"></p-sortIcon></th>
      <th class="money" pSortableColumn="TotalRetail">
        T. Retail
        <i
          [pTooltip]="
            'Retail product value your Referral customer paid for the product.  The total does not include shipping costs, taxes, or the use of Vitapoints.'
          "
          tooltipPosition="top"
          class="fa fa-question-circle"
        ></i>
        <p-sortIcon field="TotalRetail"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="TotalValue">
        T. Value
        <i
          [pTooltip]="
            'The product value that your compensation will be based off of.'
          "
          tooltipPosition="top"
          class="fa fa-question-circle"
        ></i>
        <p-sortIcon field="TotalValue"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="TotalPaid">
        T. Paid
        <i
          [pTooltip]="
            'The total amount you were paid based on product value and eligible compensation percentages.'
          "
          tooltipPosition="top"
          class="fa fa-question-circle"
        ></i>
        <p-sortIcon field="TotalPaid"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-referral>
    <tr>
      <td>{{ referral.Name }}</td>
      <td *ngIf="showOptionalColumn">{{ referral.Account }}</td>
      <td *ngIf="showOptionalColumn">
        {{ referral.Date | date : "MM/dd/yyyy" }}
      </td>
      <td>
        <button
          *ngIf="referral.Orders"
          (click)="onShowClick(referral)"
          class="btn btn-primary btn-view"
        >
          {{ referral.Orders }}
        </button>
      </td>
      <td>{{ referral.LastOrder | date : "MM/dd/yyyy" }}</td>
      <td *ngIf="showOptionalColumn">{{ referral.Store }}</td>
      <td class="url">
        <a href=" {{ referral.Url }}"> {{ referral.Url }}</a>
      </td>
      <td class="money">
        {{ referral.TotalRetail ? "$" + referral.TotalRetail.toFixed(2) : "-" }}
      </td>
      <td class="money">
        {{ referral.TotalValue ? "$" + referral.TotalValue.toFixed(2) : "-" }}
      </td>
      <td class="money">
        {{ referral.TotalPaid ? "$" + referral.TotalPaid.toFixed(2) : "-" }}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr class="total-row">
      <td *ngIf="!showOptionalColumn" colspan="4">Total</td>
      <td *ngIf="showOptionalColumn" colspan="7">Total</td>
      <td class="money">${{ retailTotal ? retailTotal.toFixed(2) : 0 }}</td>
      <td class="money">${{ valueTotal ? valueTotal.toFixed(2) : 0 }}</td>
      <td class="money">${{ paidTotal ? paidTotal.toFixed(2) : 0 }}</td>
    </tr>
  </ng-template>
</p-table>
