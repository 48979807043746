import { APP_INITIALIZER, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { ConfigService } from './services/config.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { MessageService } from 'primeng/api';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '@modules/shared/shared.module';
import { HeaderDropdownComponent } from './components/header/header-dropdown/header-dropdown.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonModule } from '@angular/common';
import { MainModule } from '@modules/main/main.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TokenInterceptorFactory } from './interceptors/token.interceptor';
import { UserService } from '@services/user.service';
import { Router } from '@angular/router';
import { BaseUrlInterceptorFactory } from './interceptors/base-url.interceptor';
import { NO_ERRORS_SCHEMA } from '@angular/core';
import { AddressService } from '@services/address.service';
import { AffiliateService } from '@services/affiliate.service';
import { ArpOrderService } from '@services/arp-order.service';
import { ContactInfoService } from '@services/contact-info.service';
import { CountryService } from '@services/country.service';
import { SubscriptionService } from '@services/subscription.service';
import { HttpService } from '@services/http.service';
import { IdleService } from '@services/idle.service';
import { OrderHistoryService } from '@services/order-history.service';
import { PaymentService } from '@services/payment.service';
import { ProfileService } from '@services/profile.service';
import { ReferService } from '@services/refer.service';
import { StoreParamsService } from '@services/store-param.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { AuthModule } from '@auth0/auth0-angular';
export const ENV = new InjectionToken('environment');

import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { UpdateProfileComponent } from '@components/update-profile/update-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    HeaderDropdownComponent,
    UpdateProfileComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MainModule,
    // MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({}),
    ToastModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        // Request this audience at user authentication time
        audience: environment.auth0.audience,
        // Request this scope at user authentication time
        scope: environment.auth0.scope,
      },
    }),
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true,
    },
    AddressService,
    AffiliateService,
    ArpOrderService,
    ConfigService,
    ContactInfoService,
    CountryService,
    SubscriptionService,
    UserService,
    HttpService,
    IdleService,
    OrderHistoryService,
    PaymentService,
    ProfileService,
    ReferService,
    StoreParamsService,
    // UserService,
    WizardHelperService,
    MessageService,
    { provide: ENV, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: TokenInterceptorFactory,
      deps: [Router, ConfigService, UserService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: BaseUrlInterceptorFactory,
      deps: [ConfigService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideNgxMask(),
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}
export function ConfigLoader(configService: ConfigService) {
  // this factory need to return a function (that return a promise)
  return () => configService.load(environment.configFile);
}
