import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { UserInfo } from '@models/user-info.model';
import { showError } from '@helpers/form-helper';
import { UserService } from '@services/user.service';
import { ConfigService } from '@services/config.service';
import { ReferService } from '@services/refer.service';
import { Patterns } from '@helpers/helper';
import { HttpErrorResponse } from '@angular/common/http';

const GiftCardUrl = 'https://activate.trivita.com/card-activation';

export interface GiftCard {
  AccountNumber: string;
  SecurityID: string;
  CardType: string;
  CardTypeCode: string;
  Name: string;
}

enum GoogleAnalyticsEventTypes {
  shareAndEarnCopyLink = 'shareAndEarnCopyLink',
  shareAndEarnOpenSendEmail = 'shareAndEarnOpenSendEmail',
  shareAndEarnSentEmail = 'shareAndEarnSentEmail',
  shareAndEarnDownloadPDF = 'shareAndEarnDownloadPDF',
}

@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss'],
})
export class ReferAFriendComponent implements OnInit, OnDestroy {
  @Input() currentUser!: UserInfo;
  @Input() panelCollapsed!: boolean;

  errorMessage!: string | null;
  form!: FormGroup;
  giftCardPDFUrl: string;
  giftCardUrl: string = GiftCardUrl;
  isEmailFormContent!: boolean;
  isLoading!: boolean;
  showError: Function = showError;
  spinnerText = 'Processing your request...';
  submitted!: boolean;
  visible!: boolean;
  sub!: Subscription;

  constructor(
    private fb: FormBuilder,
    private referService: ReferService,
    private authService: UserService,
    private messageService: MessageService,
    private configService: ConfigService
  ) {
    this.giftCardPDFUrl = configService.config.giftCardPdfUrl;
  }

  ngOnInit() {
    this.getGiftCardInfo();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  openDialog(): void {
    this.visible = true;
    this.buildForm();
    this.isEmailFormContent = true;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }

    this.submitted = true;
    this.isLoading = true;

    this.sub = this.referService
      .checkExistingEmail(this.form.value.email)
      .pipe(
        finalize(() => {
          this.submitted = false;
          this.isLoading = false;
        }),
        tap(
          (response: any) =>
            (this.errorMessage = response.existingCustomer
              ? 'Email address belongs to an existing customer'
              : null)
        ),
        filter((response) => !response.existingCustomer),
        switchMap((response) =>
          this.referService.sendGiftCardOnEmail(
            encodeURIComponent(this.form.value.email),
            this.currentUser.memberId,
            this.authService.user.token
          )
        )
      )
      .subscribe({
        next: (response) => {
          this.isEmailFormContent = false;
          this.form.controls['email'].reset();
        },
        error: (error: HttpErrorResponse) => {
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
  }

  sendEventInfoToGA(eventType: string) {}

  private buildForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(Patterns.email)]],
    });
  }

  private createLinks(memberId: string, countryId: number): void {
    this.giftCardUrl += `?cardNumber=${this.currentUser?.giftCard?.AccountNumber}&pinNumber=${this.currentUser?.giftCard?.SecurityID}`;
    this.giftCardPDFUrl += `?CustID=${memberId}&CountryID=${countryId}&SID=${this.currentUser?.giftCard?.SecurityID}`;
  }

  private getGiftCardInfo(): void {
    this.currentUser = this.authService.getCurrentUser();

    if (this.currentUser && this.currentUser.giftCard?.AccountNumber) {
      this.currentUser.giftCard = this.currentUser.giftCard;
      this.createLinks(
        this.currentUser.memberId,
        this.currentUser?.shippingAddress?.countryId!
      );
    } else {
      this.isLoading = true;
      const token = this.authService.user.token;
      this.authService.getCurrentUserByToken(token).subscribe({
        next: (res) => {
          this.currentUser = res;
          if (this.currentUser.giftCard) {
            this.createLinks(
              this.currentUser.memberId,
              this.currentUser?.shippingAddress?.countryId!
            );
          }
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: error.message,
          });
        },
      });
    }
  }
}
