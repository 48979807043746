import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Country } from '@models/country.model';
import { UserService } from '@services/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-premier-membership-plus',
  templateUrl: './premier-membership-plus.component.html',
  styleUrls: ['./premier-membership-plus.component.scss'],
})
export class PremierMembershipPlusComponent implements OnInit {
  @Input() country!: Country;
  @Input() panelCollapsed = true;
  @Input() ocenture!: any;
  @Input() expired = false;
  @Output() telehealth = new EventEmitter();
  @Output() prayer = new EventEmitter();
  @Output() reactivate = new EventEmitter();
  @Output() cancelSubscription = new EventEmitter();

  isTersmCheckBoxChecked = false;
  tersmSubmitted = false;

  constructor(private authService: UserService) {}

  ngOnInit() {
    console.log(this.ocenture);
    console.log(this.expired)
  }

  onTermsAgree() {
    this.tersmSubmitted = true;
    let userInfo = this.authService.getCurrentUser();
    if (userInfo) {
      this.authService
        .acceptPNP(userInfo)
        ?.pipe(
          finalize(() => {
            this.authService.setOcenture(userInfo);
          })
        )
        .subscribe();
    }
  }
}
