import { Observable, throwError as observableThrowError } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { LocalStorage } from '../helpers/local-storage.decorator';
import { Router } from '@angular/router';
import { UserInfo } from '@models/user-info.model';
import {
  WELLNESS_CACHE,
  WELLNESS_STORE,
} from '@modules/wellness-store/services/wellness-store.api.services';
import { ConfigService } from '@services/config.service';
import { UserService } from '@services/user.service';

export const TokenInterceptorFactory = (
  router: Router,
  config: ConfigService,
  authService: UserService
) => {
  return new TokenInterceptor(router, config, authService);
};

export class TokenInterceptor implements HttpInterceptor {
  @LocalStorage() user!: UserInfo;

  constructor(
    private router: Router,
    private config: any,
    private userService: UserService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let customReq = null;
    const token = this.userService?.user?.token;

    if (token && (request.url.includes(WELLNESS_STORE))) {
      customReq = request.clone({
        headers: request.headers.append('Authorization', `bearer ${token}`),
      });
    }

    if (request.url.includes(WELLNESS_CACHE)) {
      customReq = request.clone({
        headers: request.headers.append(
          'Authorization',
          `${this.config.getConfiguration().clearCacheToken}`
        ),
      });
    }

    return next.handle(customReq || request).pipe(
      catchError((error: Response) => {
        if (error.status === 500) {
          // this.router.navigate(["/login"]);
        }else if ([401, 403].includes(error.status)) {
          this.userService.logout();
        }
        return observableThrowError(error);
      })
    );
  }
}
