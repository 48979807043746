import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Patterns } from "@helpers/helper";


@Component({
  selector: "app-add-edit-address-form",
  templateUrl: "./add-edit-address-form.component.html",
  styleUrls: ["./add-edit-address-form.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddEditAddressFormComponent implements OnInit {
  @Input() editableAddress: any;
  @Input() country: any;
  @Output() onCancelAddEditAddress = new EventEmitter<number>();
  @Output() onUpdateAddressList = new EventEmitter<any>();

  public addEditAddressForm!: FormGroup;
  public postalCodeMask!: string;

  private submitted = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.buildForm();
    this.postalCodeMask =
      this.country.value === 2
        ? Patterns.postalCode.mask.canada
        : Patterns.postalCode.mask.usa;
  }

  /**
   * Build new customer checkout model driven form
   */
  private buildForm(): void {
    this.addEditAddressForm = this.fb.group({
      addressName: ["", [Validators.required]],
      address1: ["", [Validators.required]],
      address2: [""],
      postalCode: [
        "",
        [Validators.required, Validators.pattern(this.patternForPostalCode)],
      ],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      countryId: [this.country.displayName, [Validators.required]],
      isDefaultBillingAddress: [false],
      isDefaultShippingAddress: [false],
      addressId: [0],
    });
    this.setAddressDetails();
    // this.addEditAddressForm.valueChanges.subscribe(console.log);
  }

  private get patternForPostalCode(): RegExp {
    return this.country.value === 2
      ? Patterns.postalCode.pattern.canada
      : Patterns.postalCode.pattern.usa;
  }

  /**
   * Emit cancel adding or editing event
   */
  public cancelAddEditAddress(): void {
    this.onCancelAddEditAddress.emit();
  }

  // /**
  //  * Return boolean is invalid passed form control or not
  //  * @param controlName {string}
  //  */
  // controlIsNotValid(controlName: string): boolean {
  //   return this.addEditAddressForm.controls[controlName].invalid;
  // }

  /**
   * Update exiat or adding new address
   */
  public onSubmit(): void {
    this.submitted = true;
    const body = JSON.parse(JSON.stringify(this.addEditAddressForm.value));
    body["countryId"] = this.country.value;
    if (this.addEditAddressForm.valid && this.addEditAddressForm.dirty) {
      this.onUpdateAddressList.emit(body);
    }
  }

  // Fill out the form to editing address
  private setAddressDetails(): void {
    if (this.editableAddress) {
      this.addEditAddressForm.controls["addressId"].setValue(
        this.editableAddress.addressId
      );
      for (const key in this.editableAddress) {
        if (key !== "addressId" && key !== "countryId") {
          this.addEditAddressForm.controls[key].setValue(
            this.editableAddress[key]
          );
        }
      }
    }
  }

  /**
   * Show validation error when form is invalid and was submitted or is dirty
   * @param controlName {string}
   */
  public showError(controlName: string): boolean {
    return (
      (this.addEditAddressForm.controls[controlName].invalid &&
        this.addEditAddressForm.controls[controlName].dirty) ||
      (this.addEditAddressForm.controls[controlName].invalid && this.submitted)
    );
  }
}
