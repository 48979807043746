import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CompensationService {
  constructor(private httpClient: HttpClient) {}

  upgradeToAffiliate(memberId: string) {
    return this.httpClient.get(
      `wellness/api/compensation/upgradeToaffiliate?custId=${memberId}`
    );
  }

  getStore(memberId: string): Observable<any> {
    return this.httpClient.get(`wellness/api/customer/${memberId}/store`);
  }

  getCompenstationDashboard(memberId: number) {
    return this.httpClient.get(
      `wellness/api/compensation/dashboard?custId=${memberId}`
    );
  }

  getStatements(memberId: number, formDate: any, thruDate: any) {
    return this.httpClient.get(
      `wellness/api/Compensation/Statement?CustID=${memberId}&FromDate=${formDate}&ThruDate=${thruDate}`
    );
  }

  createStore(data: any) {
    return this.httpClient.post(`wellness/api/store`, data);
  }

  getReferrals(memberId: number) {
    return this.httpClient.get<any>(
      `wellness/api/Referrals?CustID=${memberId}`
    );
  }
  getOrders(referralID: number) {
    return this.httpClient.get<any>(
      `wellness/api/Referrals/Orders?ReferralID=${referralID}`
    );
  }

  getOrderDetails(orderNumber: number) {
    return this.httpClient.get<any>(
      `wellness/api/Referrals/OrderDetails?OrdNum=${orderNumber}`
    );
  }
}
