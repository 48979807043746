import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Country } from '@models/country.model';
import { Address } from '@models/arp-order.model';
import { UserInfo } from '@models/user-info.model';
import { AddressService } from '@services/address.service';
import { ArpOrderService } from '@services/arp-order.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-address-management',
  templateUrl: './address-management.component.html',
  styleUrls: ['./address-management.component.scss'],
})
export class AddressManagementComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() country!: Country;
  @Input() editableAddress!: Address;
  @Input() isAddingEditingAddress!: boolean;
  @Input() typeOfAddress!: string;
  @Input() user!: UserInfo;

  @Output() onAddEditAddress: EventEmitter<boolean> = new EventEmitter();
  @Output() onShowNotification: EventEmitter<any> = new EventEmitter();
  @Output() onCloseAddressManagement: EventEmitter<string> = new EventEmitter();
  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onSelectAddressToEditing = new EventEmitter<Address | null>();

  addressItems!: { address: Address; checked: boolean }[];
  addressList!: Address[];
  changeDetectionInterval: any;
  dialogContentMaxWidth: number = window.innerWidth - 300;
  display = false;
  public isCreditCardError = false;
  public isLoading = false;
  public spinnerText!: string;

  private sub!: Subscription;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private addressService: AddressService,
    private arpOrderService: ArpOrderService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getAddressList();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Get editable address
   */
  addEditAddress(address: Address | null): void {
    this.onAddEditAddress.emit(true);
    this.onSelectAddressToEditing.emit(address);
  }

  /**
   * Finish adding or editing address process
   */
  endAddEditAddress(): void {
    this.onAddEditAddress.emit(false);
    //this.editableAddress = {new Address()};
  }

  // get address list from api
  getAddressList(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe(
      (addresses: Address[]) => {
        this.addressList = addresses;
        this.updateAddresses(this.addressList);
      }
    );
  }

  // remove address from address list by id
  removeAddress(addressId: any): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.addressService.removeAddress(this.user.memberId, addressId).subscribe({
      next: (response) => {
        this.wizardHelperService.setAddressList(response.addresses);
        this.isLoading = false;
        this.spinnerText = '';
        this.messageService.add({
          severity: 'success',
          summary: 'Address was deleted',
        });
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.spinnerText = '';
        this.messageService.add({
          severity: 'error',
          summary: error.message,
        });
      }
  });
  }

  // handler toggle btn event
  public changeCheckedAddress(checked: any, address: any): void {
    if (checked) {
      this.addressItems.map((item) => {
        item.checked = false;
        if (item.address.addressId === address.address.addressId) {
          item.checked = true;
          this.form.controls[`${this.typeOfAddress}AddressId`].setValue(
            address.address.addressId
          );
          this.sendUpdatedOrder();
        }
      });
    } else {
      this.addressItems.map((item) => {
        item.checked = false;
        const defaultAddress =
          this.typeOfAddress === 'shipping'
            ? this.addressList.find(
                (adressItem) => adressItem.isDefaultShippingAddress === true
              )
            : this.addressList.find(
                (adressItem) => adressItem.isDefaultBillingAddress === true
              );

        if (item.address.addressId === defaultAddress?.addressId) {
          item.checked = true;
          this.form.controls[`${this.typeOfAddress}AddressId`].setValue(
            defaultAddress.addressId
          );
          this.sendUpdatedOrder();
        }
      });
    }
  }

  /**
   * Update component addresses list and set billing address id
   * @param addresses - addresses list
   */
  private updateAddresses(addresses: any, isUpdatingOrder?: boolean): void {
    if (this.typeOfAddress === 'shipping') {
      const addressItems = addresses.map((address: Address) => {
        if (
          address.addressId === this.form.controls['shippingAddressId'].value
        ) {
          this.form.controls['shippingAddressId'].setValue(address.addressId);
          return { address, checked: true };
        } else {
          return { address, checked: false };
        }
      });
      this.addressItems = addressItems.slice();
    } else {
      this.addressItems = addresses.map((address: Address) => {
        if (
          address.addressId === this.form.controls['billingAddressId'].value
        ) {
          this.form.controls['billingAddressId'].setValue(address.addressId);
          return { address, checked: true };
        } else {
          return { address, checked: false };
        }
      });
    }
  }

  // send updated order to service and update general order list
  private sendUpdatedOrder(): void {
    this.isCreditCardError = false;
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.arpOrderService.editArpOrder(this.form.value).subscribe({
      next: (response) => {
        this.wizardHelperService.setOrderList(response.arpOrders);
        this.onShowNotification.emit({
          error: false,
          message: 'Address was changed',
        });
        this.isLoading = false;
        this.spinnerText = '';
      },
      error: (error: any) => {
        this.isLoading = false;
        this.spinnerText = '';
        if (error === 'AEC0053') {
          this.isCreditCardError = true;
        } else {
          this.messageService.add({
            severity: 'error',
            summary: error.error_description,
          });
        }
      }
  });
  }
}
