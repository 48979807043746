import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpService} from './http.service';

@Injectable()
export class ReferService {

  constructor(private httpService: HttpService) {
  }

  checkExistingEmail(email: string): Observable<{ existingCustomer: boolean }> {
    return this.httpService.get(`profile?email=${email}`, 'cart');
  }

  sendGiftCardOnEmail(email: string, membeId: string, token: string) {
    return this.httpService.get(`Customer/EmailGC?memberId=${membeId}&recipientEmail=${email}&templateID=743`, 'global', token);
  }
}
