import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountManagmentComponent } from './account-managment.component';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@modules/spinner/spinner.module';
import { ToastModule } from 'primeng/toast';
import { ClipboardModule } from 'ngx-clipboard';
import { AccountBasicInfoComponent } from './account-basic-info/account-basic-info.component';
import { AddressManagementComponent } from './auto-replenishment-program/arp-order-editing/address-management/address-management.component';
import { ArpOrderEditingComponent } from './auto-replenishment-program/arp-order-editing/arp-order-editing.component';
import { PaymentManagementComponent } from './auto-replenishment-program/arp-order-editing/payment-management/payment-management.component';
import { ProductsManagementComponent } from './auto-replenishment-program/arp-order-editing/products-management/products-management.component';
import { AddEditBillingAddressStepComponent } from './auto-replenishment-program/arp-order-wizard/add-edit-billing-address-step/add-edit-billing-address-step.component';
import { AddEditPaymentMethodStepComponent } from './auto-replenishment-program/arp-order-wizard/add-edit-payment-method-step/add-edit-payment-method-step.component';
import { AddEditShippingAddressStepComponent } from './auto-replenishment-program/arp-order-wizard/add-edit-shipping-address-step/add-edit-shipping-address-step.component';
import { ArpOrderWizardComponent } from './auto-replenishment-program/arp-order-wizard/arp-order-wizard.component';
import { ConfigureOrderStepComponent } from './auto-replenishment-program/arp-order-wizard/configure-order-step/configure-order-step.component';
import { ReviewYourOrderStepComponent } from './auto-replenishment-program/arp-order-wizard/review-your-order-step/review-your-order-step.component';
import { SelectBillingAddressStepComponent } from './auto-replenishment-program/arp-order-wizard/select-billing-address-step/select-billing-address-step.component';
import { CreditCardFormComponent } from './auto-replenishment-program/arp-order-wizard/select-payment-method-step/credit-card-form/credit-card-form.component';
import { SelectPaymentMethodStepComponent } from './auto-replenishment-program/arp-order-wizard/select-payment-method-step/select-payment-method-step.component';
import { AvailableProductsComponent } from './auto-replenishment-program/arp-order-wizard/select-products-step/available-products/available-products.component';
import { SelectProductsStepComponent } from './auto-replenishment-program/arp-order-wizard/select-products-step/select-products-step.component';
import { SelectShippingAddressStepComponent } from './auto-replenishment-program/arp-order-wizard/select-shipping-address-step/select-shipping-address-step.component';
import { ArpOrderComponent } from './auto-replenishment-program/arp-order/arp-order.component';
import { AutoReplenishmentProgramComponent } from './auto-replenishment-program/auto-replenishment-program.component';
import { AddEditAddressComponent } from './auto-replenishment-program/shared/add-edit-address/add-edit-address.component';
import { ArpOrderOptionComponent } from './auto-replenishment-program/shared/arp-order-option/arp-order-option.component';
import { ArpOrderAddressViewComponent } from './auto-replenishment-program/shared/arp-order-personal-info/arp-order-address-view/arp-order-address-view.component';
import { ArpOrderCardViewComponent } from './auto-replenishment-program/shared/arp-order-personal-info/arp-order-card-view/arp-order-card-view.component';
import { ArpOrderPersonalInfoComponent } from './auto-replenishment-program/shared/arp-order-personal-info/arp-order-personal-info.component';
import { ArpOrderProductsComponent } from './auto-replenishment-program/shared/arp-order-products/arp-order-products.component';
import { BecomeAffiliateComponent } from './become-affiliate/become-affiliate.component';
import { AddEditAddressFormComponent } from './contact-information/address-list/add-edit-address-form/add-edit-address-form.component';
import { AddressListComponent } from './contact-information/address-list/address-list.component';
import { ContactInformationComponent } from './contact-information/contact-information.component';
import { CreateFirstStoreComponent } from './create-first-store/create-first-store.component';
import { MyCompensationsComponent } from './my-compensations/my-compensations.component';
import { MyReferralsComponent } from './my-referrals/my-referrals.component';
import { OrderDetailTableComponent } from './my-referrals/order-detail-table/order-detail-table.component';
import { OrdersTableComponent } from './my-referrals/orders-table/orders-table.component';
import { ReferralsTableComponent } from './my-referrals/referrals-table/referrals-table.component';
import { OrderHistoryComponent } from './order-history/order-history.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { AddMemberFormComponent } from './premier-membership-plus/manage-secondary-accounts/add-member-form/add-member-form.component';
import { ManageSecondaryAccountsComponent } from './premier-membership-plus/manage-secondary-accounts/manage-secondary-accounts.component';
import { PremierMembershipPlusComponent } from './premier-membership-plus/premier-membership-plus.component';
import { ReferAFriendComponent } from './refer-a-friend/refer-a-friend.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { WecareComponent } from './wecare/wecare.component';
import { CvvHelpComponent } from '@components/shared/cvv-help/cvv-help.component';
import { SharedModule } from '@modules/shared/shared.module';
import { PasswordComponent } from './password/password.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClipboardModule,
    SharedModule,
    ToastModule,
    SpinnerModule
  ],
  declarations: [
    AccountBasicInfoComponent,
    AccountManagmentComponent,
    AddressListComponent,
    AddEditAddressComponent,
    AddEditAddressFormComponent,
    AddressManagementComponent,
    ArpOrderComponent,
    ArpOrderAddressViewComponent,
    ArpOrderCardViewComponent,
    ArpOrderEditingComponent,
    ArpOrderOptionComponent,
    AddEditBillingAddressStepComponent,
    AddEditPaymentMethodStepComponent,
    AddEditShippingAddressStepComponent,
    ArpOrderProductsComponent,
    ArpOrderPersonalInfoComponent,
    ArpOrderWizardComponent,
    AutoReplenishmentProgramComponent,
    AvailableProductsComponent,
    ContactInformationComponent,
    ConfigureOrderStepComponent,
    CreditCardFormComponent,
    CvvHelpComponent,
    OrderHistoryComponent,
    PaymentMethodComponent,
    PaymentManagementComponent,
    PaymentManagementComponent,
    ReferAFriendComponent,
    ProductsManagementComponent,
    ReviewYourOrderStepComponent,
    SelectBillingAddressStepComponent,
    SelectProductsStepComponent,
    SelectPaymentMethodStepComponent,
    SelectShippingAddressStepComponent,
    SubscriptionComponent,
    PremierMembershipPlusComponent,
    WecareComponent,
    ManageSecondaryAccountsComponent,
    AddMemberFormComponent,
    MyReferralsComponent,
    MyCompensationsComponent,
    ReferralsTableComponent,
    OrdersTableComponent,
    OrderDetailTableComponent,
    BecomeAffiliateComponent,
    CreateFirstStoreComponent,
    PasswordComponent
  ],

})
export class AccountManagmentModule {}
