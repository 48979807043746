import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { WELLNESS_CACHE, WELLNESS_STORE } from "@modules/wellness-store/services/wellness-store.api.services";
import { Observable } from "rxjs";


export const BaseUrlInterceptorFactory = (config: any) => {
  return new BaseUrlInterceptor(config);
};

export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private config: any) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes("SaveCard")) {
      return next.handle(req);
    }

    if (req.url.includes(WELLNESS_STORE)) {
      const newUrl = req.url.split(WELLNESS_STORE)[1];
      const url = `${this.config.getConfiguration().wellness}${newUrl}`;
      const Req = req.clone({ url });
      return next.handle(Req);
    }

    if (req.url.includes(WELLNESS_CACHE)) {
      const newUrl = req.url.split(WELLNESS_CACHE)[1];
      const url = `${this.config.getConfiguration().cacheBaseUrl}${newUrl}`;
      const Req = req.clone({ url });
      return next.handle(Req);
    }

    return next.handle(req);
  }
}
