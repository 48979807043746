<div class="header">
  <div class="header__primary">
    <div class="header__content">
      <div class="header__logo" (click)="navigateToTrivita()"></div>

      <ng-container *ngIf="!userService.isNewRegisteredUser()">
        <!-----mobile menu---------------------------------------------------->
        <div class="header__mobile-menu">
          <i
            *ngIf="!closed"
            (click)="switchMobileMenu()"
            class="header__mobile-menu--open"
          ></i>
          <i
            *ngIf="closed"
            (click)="switchMobileMenu()"
            class="header__mobile-menu--closed"
          ></i>
        </div>
        <div *ngIf="!closed" class="header__mobile-menu-content">
          <div class="header__mobile-nav-wrapper">
            <div
              *ngFor="let item of configuration"
              class="header__mobile-nav"
              [class.header__mobile-nav--active]="item.active"
            >
              <ng-container *ngIf="item?.secondary; else single">
                <div
                  class="header__mobile-panel"
                  [class.header__mobile-panel--active]="!item.collapsed"
                >
                  <p-panel
                    [header]="item.label"
                    [collapsed]="item.collapsed"
                    [toggleable]="true"
                    [toggler]="'header'"
                    (onBeforeToggle)="item.collapsed = !item.collapsed"
                  >
                    <div
                      (click)="changeModuleAndSubmodule(item, secondary.value)"
                      [class.header__mobile-secondary-active]="secondary.active"
                      *ngFor="let secondary of item.secondary"
                    >
                      {{ secondary.label }}
                    </div>
                  </p-panel>
                </div>
              </ng-container>
              <ng-template #single>
                <div
                  (click)="changeModule(item, true)"
                  class="header__item-mobile"
                >
                  {{ item.label }}
                </div>
              </ng-template>
            </div>
          </div>
          <div class="header__mobile-footer">
            <div class="header__mobile-logout" (click)="logOut()">Log out</div>
            <div class="header__mobile-user-name">
              Welcome {{ user?.firstname }}
            </div>
          </div>
        </div>
        <!-------------------------------------------------------------------->
        <div
          class="header__item"
          *ngFor="let item of configuration"
          (click)="changeModule(item)"
          [class.header__primary-active]="item.active"
        >
          {{ item?.label }}
        </div>
      </ng-container>

      <div
        class="header__item-user"
        [style.display]="
          userService?.isNewRegisteredUser() ? 'flex' : ''
        "
        (click)="show = !show"
      >
        Welcome {{ user?.firstname }}
        <app-header-dropdown
          [(show)]="show"
          (click)="show = !show"
          (logout)="logOut()"
        ></app-header-dropdown>
      </div>
    </div>
  </div>
  <div
    class="header__secondary"
    *ngIf="
      activeSegment &&
      activeSegment.secondary &&
      !['/wellness-store/user/select-store', '/main/subscription'].includes(
        path
      )
    "
  >
    <div class="header__content header__content--secondary">
      <div style="display: flex">
        <ng-container *ngFor="let item of activeSegment.secondary">
          <div
            *ngIf="!item.hidden"
            class="header__item"
            (click)="changeSubModule(item.value)"
            [class.header__secondary-active]="item.active"
          >
            {{ item.label }}
          </div>
        </ng-container>
      </div>
      <div
        *ngIf="customerStores && customerStores.length > 1"
        class="header__item--store-selection"
      >
        <p-dropdown
          [options]="customerStores"
          [ngModel]="currentStoreID"
          (onChange)="selectStore($event)"
        ></p-dropdown>
      </div>
    </div>
  </div>
</div>
