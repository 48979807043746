import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrimengModule } from '@modules/primeng/primeng.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { SpinnerModule } from '@modules/spinner/spinner.module';
import { SafeHtmlPipe } from '@pipes/safe-html.pipe';
import { SafeResourceUrlPipe } from '@pipes/safe-resource-url.pipe';
import { ArpOrderOptionsPipe } from '@pipes/arp-order-options';
import { StateNamePipe } from '@pipes/state-name.pipe';
import { InputUppercaseDirective } from '@directives/input-uppercase.directive';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    PrimengModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    SpinnerModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ArpOrderOptionsPipe,
    StateNamePipe,
    InputUppercaseDirective,
  ],
  exports: [
    SpinnerModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    PrimengModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    SafeHtmlPipe,
    SafeResourceUrlPipe,
    ArpOrderOptionsPipe,
    StateNamePipe,
    InputUppercaseDirective,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [ConfirmationService],
})
export class SharedModule {}
