import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appInputUppercase]'
})
export class InputUppercaseDirective {
  @HostListener('keyup') onKeyUp() {
    this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
    this.el.nativeElement.dispatchEvent(new Event('input'));
  }

  constructor(private el: ElementRef) {
  }
}
