<div
  #content
  class="wizard-step-content"
  [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '610px', 'width': dialogContentMaxWidth }">

  <app-credit-card-form
    [form]="form"
    [isPaymentManagement]="false"
    [creditCardsTypes]="creditCardsTypes"
    (onAddEditCard)="finishThisStep()"
    (onChangeStep)="changeStep()">
  </app-credit-card-form>
</div>
<p-toast></p-toast>
