<app-affiliate-basic-info
  [currentUser]="currentUser">
</app-affiliate-basic-info>

<app-message-center
  [panelCollapsed]="panelsCollapsed.messageCenter">
</app-message-center>

<app-compensation-information
  [panelCollapsed]="panelsCollapsed.compensationInformation">
</app-compensation-information>

<app-contacts
  [panelCollapsed]="panelsCollapsed.contacts"
  [currentUser]="currentUser">
</app-contacts>

<app-media-library
  [panelCollapsed]="panelsCollapsed.mediaLibrary">
</app-media-library>

<app-printed-material
  [panelCollapsed]="panelsCollapsed.printedMaterial">
</app-printed-material>

<app-tutorials
  [panelCollapsed]="panelsCollapsed.tutorials">
</app-tutorials>
