import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { forkJoin, Observable, Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";
import { MessageService, SelectItem } from "primeng/api";
import { isEmpty } from "lodash";
import { LocalStorage } from "@helpers/local-storage.decorator";
import { Address } from "@models/arp-order.model";
import { Country } from "@models/country.model";
import { UserInfo } from "@models/user-info.model";
import { AddressService } from "@services/address.service";
import { UserService } from "@services/user.service";
import { CountryService } from "@services/country.service";
import { PaymentService } from "@services/payment.service";
import { SubscriptionService } from "@services/subscription.service";
import { Patterns } from "@helpers/helper";
import { AuthService } from "@auth0/auth0-angular";


const addZero = (number: any): string =>
  number < 10 ? `0${number}` : `${number}`;

const CVV_PATTERN = /^[0-9]{3,4}$/;

enum ResultMessageStatus {
  Success = "Success",
}

enum SeverityStatus {
  Error = "error",
}

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit, OnDestroy {
  @LocalStorage() isCCExpired: any;
  @LocalStorage() storeID: any;

  public contactInfo$!: Observable<{
    addresses: Address[];
    defaultBillingAddress: Address;
    defaultShippingAddress: Address;
    country: Country;
  }>;
  public cardTypes$!: Observable<SelectItem[]>;
  public isEditingBillingAddress = false;
  public isEditingCreditCard = false;
  public isLoading!: boolean;
  public contactInfoForm!: FormGroup;
  public addCardForm!: FormGroup;
  public updateCardForm = this.fb.group({
    cardExpMonth: ["", Validators.required],
    cardExpYear: ["", Validators.required],
    CVV: ["", [Validators.required, Validators.pattern(CVV_PATTERN)]],
    CCNum: "",
    CardType: "",
  });
  public paymentInfo!: {
    PaymentAccountID: string;
    CCNum: string;
    CCExp: string;
    CVV: string;
    BillAddID: string;
  };
  public months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  public currentUser!: UserInfo;
  private destroy$ = new Subject<boolean>();

  constructor(
    private addressService: AddressService,
    private userService: UserService,
    private countryService: CountryService,
    private messageService: MessageService,
    private subscriptionService: SubscriptionService,
    private paymentService: PaymentService,
    private fb: FormBuilder,
    private router: Router,
    public auth: AuthService
  ) {
    this.currentUser = this.userService.getCurrentUser();
  }

  ngOnInit(): void {
    if (this.currentUser && this.currentUser.memberId) {
      this.fetchPaymentInfo();
      this.initContactInfo();
      this.fetchCardTypes();
      this.initCardForm();
    } else {
      this.userService.logout();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private initCardForm(): void {
    this.addCardForm = this.fb.group({
      payment: "",
    });
  }

  public onUpdateCard(): void {
    const {
      CVV,
      cardExpMonth,
      cardExpYear,
      CCNum,
      // CardType,
    } = this.updateCardForm.value;

    this.subscriptionService
      .updateStoreCardById(this.storeID, {
        CustID: this.currentUser.memberId,
        PaymentAccountID: this.paymentInfo.PaymentAccountID,
        CCNum,
        CCExp: `${addZero(cardExpMonth)}${cardExpYear?.slice(2)}`,
        CVV,
        BillAddID: this.paymentInfo.BillAddID,
      })
      .subscribe(({ ResultMessage }) => {
        if (ResultMessage === ResultMessageStatus.Success) {
          this.isCCExpired = false;
          this.router.navigateByUrl("wellness-store/user/select-store");
        } else {
          this.pushNotification({
            severity: SeverityStatus.Error,
            message: ResultMessage,
          });
        }
      });
  }

  public fetchCardTypes(): void {
    this.cardTypes$ = this.paymentService.getCardTypes().pipe(
      takeUntil(this.destroy$),
      map(({ countries }) => {
        const country = countries.filter(({ value }) => value === 1)[0];
        return country?.cardTypes.map(({ value, displayName }) => ({
          value,
          label: displayName,
        }));
      })
    );
  }

  public fetchPaymentInfo(): void {
    this.subscriptionService
      .paymentInfo({
        CustID: this.currentUser.memberId,
        PaymentUsageID: "5",
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentInfo) => {
        this.paymentInfo = paymentInfo;
      });
  }

  public initContactInfo(): void {
    this.isLoading = true;
    this.contactInfo$ = forkJoin([
      this.addressService.getAllAddresses(this.currentUser.memberId),
      this.countryService.getCountriesWithStates(),
    ]).pipe(
      takeUntil(this.destroy$),
      filter(([{ addresses }]) => !isEmpty(addresses)),
      map(([{ addresses }, countries]) => ({
        addresses,
        defaultBillingAddress: addresses.filter(
          (address: any) => address.isDefaultBillingAddress
        )[0],
        defaultShippingAddress: addresses.filter(
          (address: any) => address.isDefaultShippingAddress
        )[0],
        country: countries.filter(
          ({ value }) => value === this.currentUser?.billingAddress?.countryId
        )[0],
      })),
      tap(({ defaultBillingAddress, defaultShippingAddress }) => {
        this.contactInfoForm = this.fb.group({
          email: [
            this.currentUser.email,
            [Validators.required, Validators.pattern(Patterns.email)],
          ],
          phone: [this.currentUser.phone, [Validators.required]],
          billingAddressId: [
            defaultBillingAddress.addressId,
            Validators.required,
          ],
          shippingAddressId: [
            defaultShippingAddress.addressId,
            Validators.required,
          ],
        });
        this.isLoading = false;
      })
    );
  }

  public pushNotification({ severity, message }: any): void {
    this.messageService.add({
      severity,
      summary: message,
    });
  }

  public editAddress(): void {
    this.isEditingBillingAddress = true;
  }

  public closeEditingAddressDialog(): void {
    this.isEditingBillingAddress = false;
  }

  public get years(): SelectItem[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 50; i++) {
      const nextYear = (currentYear + i).toString();
      years.push({
        value: nextYear,
        label: nextYear,
      });
    }
    return years;
  }

  public showNotification({ error, message }: any): void {
    if (error) {
      this.messageService.add({ severity: "error", summary: message });
    } else {
      this.messageService.add({ severity: "success", summary: message });
    }
  }
}
