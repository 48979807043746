import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Patterns } from '@helpers/helper';
import { Country } from '@models/country.model';
import { OcentureSecondaryAddParamsModel } from '@models/ocenture-secondary-add-params.model';

@Component({
  selector: 'app-add-member-form',
  templateUrl: './add-member-form.component.html',
  styleUrls: ['./add-member-form.component.scss'],
})
export class AddMemberFormComponent implements OnInit, OnChanges {
  @Input() country!: Country;
  @Output() onCancel = new EventEmitter<boolean>(false);
  @Output() onSubmitForm = new EventEmitter<OcentureSecondaryAddParamsModel>();
  addMemberFormGroup!: FormGroup;
  postalCodeMask!: string;
  phoneMask: any;
  submitted = false;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    this.postalCodeMask =
      this.country?.value === 2
        ? Patterns.postalCode.mask.canada
        : Patterns.postalCode.mask.usa;
    this.phoneMask = Patterns.phone.mask;
  }
  ngOnInit(): void {
    this.addMemberFormGroup = this.createForm();
  }

  createForm() {
    return this.fb.group({
      FirstName: ['', [Validators.required]],
      LastName: ['', [Validators.required]],
      Email: ['', [Validators.required]],
      Phone: [
        '',
        [Validators.required, Validators.pattern(Patterns.phone.pattern)],
      ],
      Address: ['', [Validators.required]],
      City: ['', [Validators.required]],
      State: ['', [Validators.required]],
      Zipcode: ['', [Validators.required]],
    });
  }

  onSubmit() {
    if (!this.addMemberFormGroup.valid) return;
    const value: OcentureSecondaryAddParamsModel =
      this.addMemberFormGroup?.value;
    let phone = value.Phone.replace('(', '')
      .replace(')', '')
      .replace(' ', '')
      .replace('-', '');
    this.onSubmitForm.emit({ ...value, Phone: phone });
    this.submitted = true;
    this.addMemberFormGroup.reset();
  }

  cancelAddEditAddress() {
    this.onCancel.emit(true);
  }
}
