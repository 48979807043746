import { Component, OnInit } from "@angular/core";
import { catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { UserInfo } from "@models/user-info.model";
import { UserService } from "@services/user.service";
import { CompensationService } from "@services/compensation.service";

@Component({
  selector: "app-become-affiliate",
  templateUrl: "./become-affiliate.component.html",
  styleUrls: ["./become-affiliate.component.css"],
})
export class BecomeAffiliateComponent implements OnInit {
  isLoading = false;
  panelCollapsed = true;
  isTermsAccepted = true;
  user!: UserInfo;
  constructor(
    private authService: UserService,
    private compensationService: CompensationService
  ) {
    this.user = authService.user;
  }

  ngOnInit(): void {}

  becomeAffiliate() {
    this.isLoading = true;
    this.compensationService
      .upgradeToAffiliate(this.user.memberId)
      .pipe(
        catchError((err, caught) => {
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((user: any) => {
        this.isLoading = false;
        this.authService.setCurrentUser(user);
      });
  }
}
