import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { UserInfo } from '@models/user-info.model';

@Injectable()
export class ProfileService {
  userToken!: string;

  constructor(
    private httpService: HttpService,
    private authService: UserService
  ) {
    if (this.authService.getCurrentUser()) {
      this.userToken = this.authService.user.token;
    }
  }

  /**
   * Get profile info from API
   */
  retrieveProfile(memberId: string): Observable<UserInfo> {
    return this.httpService.get(
      `profile/${memberId}`,
      'account',
      this.userToken
    );
  }
}
