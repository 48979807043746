import { UserService } from '../../services/user.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Country } from '@models/country.model';
import { UserInfo } from '@models/user-info.model';
import { CompensationService } from '@services/compensation.service';
import { ConfigService } from '@services/config.service';
import { CountryService } from '@services/country.service';
import { HttpService } from '@services/http.service';
import { Subscription, filter } from 'rxjs';

interface IPanelsCollapsed {
  contactInformation: boolean;
  paymentMethod: boolean;
  orderHistory: boolean;
  autoReplenishmentProgram: boolean;
  referAFriend: boolean;
  password: boolean;
}

@Component({
  selector: 'app-account-managment',
  templateUrl: './account-managment.component.html',
  styleUrls: ['./account-managment.component.scss'],
})
export class AccountManagmentComponent implements OnInit, OnDestroy {
  loginChangedSub$!: Subscription;
  storeCreateSub$!: Subscription;
  isLoading = false;

  public country: Country | undefined;
  public currentUser!: UserInfo;
  public panelsCollapsed: IPanelsCollapsed = {
    contactInformation: true,
    paymentMethod: true,
    orderHistory: true,
    autoReplenishmentProgram: true,
    referAFriend: true,
    password: true,
  };
  public referAFriendIsVisible!: boolean;

  private getCountriesWithStatesSubscription!: Subscription;
  public ocenture!: any;
  public ocentureExpired!: boolean;

  public isAffiliate = false;
  public hasStore = false;

  constructor(
    private countryService: CountryService,
    private router: Router,
    private userService: UserService,
    private http: HttpService,
    private configService: ConfigService,
    private storeService: CompensationService,
    public auth: AuthService
  ) {
    this.currentUser = userService.getCurrentUser();
  }

  private reloadCurrentRoute(): void {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  ngOnInit() {
    //debugger;
    //this.reloadCurrentRoute();
    this.checkUserIsLogged();
    this.initOcenture();
    this.getStore();
    this.isAffiliate = this.userService.isAffiliate();

    this.loginChangedSub$ = this.userService.userChanged$.subscribe((x) => {
      this.isAffiliate = this.userService.isAffiliate();
    });

    this.storeCreateSub$ = this.userService.firstStoreCreatedSubject$.subscribe(
      (isCreated) => {
        if (isCreated) {
          this.getStore();
        }
      }
    );
  }

  private initOcenture(): void {
    this.userService.ocentureStream
      .pipe(filter(Boolean))
      .subscribe((ocenture) => {
        this.ocenture = ocenture;
        if (this.ocenture.expiryDate) {
          this.ocentureExpired =
            new Date(this.ocenture.expiryDate) < new Date();
        }
      });
  }

  ngOnDestroy() {
    if (this.getCountriesWithStatesSubscription) {
      this.getCountriesWithStatesSubscription.unsubscribe();
    }

    this.loginChangedSub$.unsubscribe();
    this.storeCreateSub$.unsubscribe();
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  private checkUserIsLogged(): void {
    this.currentUser = this.userService.getCurrentUser();

    this.referAFriendIsVisible =
      this.currentUser &&
      (this.currentUser.isPremierMember === true ||
        this.currentUser.isPremierMember === 'true' ||
        this.currentUser.isItbo === true ||
        this.currentUser.isItbo === 'true' ||
        this.currentUser.isAffiliate === true ||
        this.currentUser.isAffiliate === 'true');

    if (!this.currentUser && location.pathname !== '/forgot-password') {
      // this.router.navigate(["/login"]);
      this.userService.logout();
    } else {
      this.defineCurrentCountry();
    }
  }

  /**
   * Get country for a country drop down along with their associated states
   */
  private defineCurrentCountry(): void {
    const countryId = this.currentUser?.billingAddress?.countryId;
    this.getCountriesWithStatesSubscription = this.countryService
      .getCountriesWithStates()
      .subscribe((response) => {
        this.country = response.find((item) => item.value === countryId);
      });
  }

  /**
   * Expands one of panel which exists in child components
   * @param panelName - name of property of this.panelsCollapsed object
   */
  public expandPanel(panelName: string): void {
    this.panelsCollapsed[panelName as keyof IPanelsCollapsed] = false;
  }

  /**
   *
   * @param event - primeng generated event
   * @param panelName - name of property of this.panelsCollapsed object
   */
  public updatePanelCollapsed(
    event: { originalEvent: Event; collapsed: boolean },
    panelName: string
  ): void {
    this.panelsCollapsed[panelName as keyof IPanelsCollapsed] = event.collapsed;
  }

  telehealth(): void {
    window.open(`${this.ocenture.url}`, '_blank');
  }

  prayer(): void {
    window.open(
      `${this.configService.getConfiguration().prayerHelplineLink}`,
      '_blank'
    );
  }

  reactivate(): void {
    const url = this.configService.getConfiguration().cart_trivita;
    const route = `/?dsCart=false&prodList=${this.ocenture.prodID}|1,${this.ocenture.reinstatementProdID}|1&siteId=4&countryId=${this.ocenture.storeID}&sourceId=0`;

    window.open(url + route, '_blank');
  }

  cancelPMPsubscription(): void {
    if(confirm("Are you sure want to cancel subscription?")) {
      this.isLoading = true;
      this.userService.cancelPMPsubscription().subscribe({
        next: (res)=> {
          this.isLoading = false;
        },
        error: (err)=> {
          this.isLoading = false;
        }
      });
    }
  }

  getStore() {
    this.storeService.getStore(this.currentUser.memberId).subscribe((res) => {
      if (res && res.length) {
        this.hasStore = true;
        console.log(res);
      } else {
        this.hasStore = false;
      }
    });
  }
}
