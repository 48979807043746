<div class="panel-wrapper">
  <p-panel
    class="order-table"
    id="order-history"
    header="Share And Earn"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      The Share and Earn program allows you to share wellness and a $20 TriVita
      Gift Card with a friend. Here are the benefits to them and you:
    </div>
    <div class="panel-description">
      <div class="panel-description-title">For Your Friend</div>
      <ul style="list-style-type: disc">
        <li>
          $20 worth of VitaPoints that can be used toward the purchase of any
          Trivita products
        </li>
        <li>Greater wellness</li>
      </ul>
    </div>
    <div
      class="panel-description"
      *ngIf="
        currentUser.isItbo === true ||
        currentUser.isItbo === 'true' ||
        currentUser.isPremierMember === true ||
        currentUser.isPremierMember === 'true'
      "
    >
      <div class="panel-description-title">For You</div>
      <ul>
        <li
          *ngIf="
            (currentUser.isPremierMember === true ||
              currentUser.isPremierMember === 'true') &&
            (!currentUser.isAffiliate || currentUser.isAffiliate === 'false') &&
            (!currentUser.isItbo || currentUser.isItbo === 'false')
          "
        >
          Earn $20 in VitaPoints upon card activation
        </li>
        <li
          *ngIf="
            (currentUser.isPremierMember === true ||
              currentUser.isPremierMember === 'true') &&
            (!currentUser.isAffiliate || currentUser.isAffiliate === 'false') &&
            (!currentUser.isItbo || currentUser.isItbo === 'false')
          "
        >
          Earn 20% of their first purchase in VitaPoints
        </li>
        <li
          *ngIf="
            (currentUser.isPremierMember === true ||
              currentUser.isPremierMember === 'true') &&
            (!currentUser.isAffiliate || currentUser.isAffiliate === 'false') &&
            (!currentUser.isItbo || currentUser.isItbo === 'false')
          "
        >
          Earn 20% of their future purchases in VitaPoints
        </li>
        <li
          *ngIf="currentUser.isItbo === true || currentUser.isItbo === 'true'"
        >
          First Sale Bonus
        </li>
        <li
          *ngIf="currentUser.isItbo === true || currentUser.isItbo === 'true'"
        >
          Standard Compensation on every additional product order
        </li>
        <li
          *ngIf="
            (currentUser.isAffiliate === true ||
              currentUser.isAffiliate === 'true') &&
            (!currentUser.isPremierMember ||
              currentUser.isPremierMember === 'false') &&
            (!currentUser.isItbo || currentUser.isItbo === 'false')
          "
        >
          Earn 40% commission of their first 3 product order
        </li>
        <li
          *ngIf="
            (currentUser.isAffiliate === true ||
              currentUser.isAffiliate === 'true') &&
            (!currentUser.isPremierMember ||
              currentUser.isPremierMember === 'false') &&
            (!currentUser.isItbo || currentUser.isItbo === 'false')
          "
        >
          Earn 20% commission of every product order after the first 3
        </li>
      </ul>
    </div>

    <div class="panel-description">That is a win-win!</div>
    <div class="panel-description">
      You can share your TriVita Gift Card with your friends by sending them an
      email, sharing a link, or downloading a printable PDF below.
    </div>

    <div class="card-title">VitaPoints Gift Card</div>
    <div class="card-details row d-flex">
      <div class="col-4">
        <div class="sub-title">CARD #</div>
        <div>{{ currentUser.giftCard?.AccountNumber }}</div>
      </div>
      <div class="col-4">
        <div class="sub-title">PIN #</div>
        <div>{{ currentUser.giftCard?.SecurityID }}</div>
      </div>
    </div>
    <div class="active-buttons row d-flex">
      <div class="col-4">
        <p-overlayPanel #op> Link copied to clipboard. </p-overlayPanel>
        <button
          ngxClipboard
          [cbContent]="giftCardUrl"
          (click)="op.show($event)"
          class="bg-color"
        >
          copy gift card link
        </button>
      </div>
      <div class="col-4">
        <button class="bg-color" (click)="openDialog()">
          email vitaPoints gift card
        </button>
      </div>
      <div class="col-4">
        <a [href]="giftCardPDFUrl" target="_blank">
          <button class="bg-color">download vitaPoints gift card PDF</button>
        </a>
      </div>
    </div>
  </p-panel>
</div>

<div class="refer-a-friend-dialog">
  <p-dialog
    [header]="'VitaPoints Gift Card Email'"
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [(visible)]="visible"
    (onHide)="visible = false"
  >
    <ng-container *ngIf="isEmailFormContent; else actionButtons">
      <div class="dialog-description">
        Enter the required information below to email your VitaPoint Gift Card.
      </div>
      <div>Send To:</div>
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div
          class="form-group"
          [ngClass]="{ 'wrong-input': showError(form, 'email', submitted) }"
        >
          <input
            pInputText
            type="text"
            placeholder="Email Address"
            formControlName="email"
            class="form-control"
          />
          <p *ngIf="showError(form, 'email', submitted)" class="error-message">
            Please enter email.
          </p>
        </div>
        <button [disabled]="form.invalid" type="submit" class="bg-color">
          Send
        </button>
      </form>
    </ng-container>

    <ng-template #actionButtons>
      <div class="dialog-description">
        Your VitaPoint Gift Card has been sent.
      </div>
      <div class="row w-100 d-flex">
        <div class="col-6">
          <button class="bg-color" (click)="isEmailFormContent = true">
            Send Another
          </button>
        </div>
        <div class="col-6">
          <button class="bg-color" (click)="visible = false">Close</button>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
