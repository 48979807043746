import { Component, Renderer2 } from '@angular/core';
import { LocalStorage } from './helpers/local-storage.decorator';
import { UserInfo } from './models/user-info.model';
import { StoreParamsService } from './services/store-param.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  @LocalStorage() user!: UserInfo;

  public showHeader = true;
  public isLoggedIn = false;

  constructor(
    private storeParamsService: StoreParamsService,
    private router: Router,
    private renderer: Renderer2,
    public authService: UserService
  ) {

  }

  ngOnInit() {
    this.setBodyThemeClass(
      this.storeParamsService.getQueryParameterByName('siteId')
    );

    if (this.user) {
      this.authService.setCurrentUser(this.user);
    } else {
      //this.checkUserIsLogged();
    }

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        map((event: any) => {
          return event.urlAfterRedirects;
        })
      )
      .subscribe((path: string) => {
        this.showHeader = !['/login', '/wellness-store/agreement'].includes(
          path
        );

      });

    this.authService.userChanged$.subscribe((isloggedIn: any) => {
      this.isLoggedIn = isloggedIn;
    });
  }

  /**
   * Check is user logged or not and redirect to according route
   */
  // checkUserIsLogged(): boolean {
  //   return this.authService.isLoggedIn();
  // }

  /**
   Allows to scroll pages to top after their refreshing
   */
  onRouterDeactivate(): void {
    this.renderer.setProperty(document.body, 'scrollTop', 0);
  }

  /**
   * Get passed in query params site id (site user came from) and
   * set specify class to body tag for styling child elements
   * according to design that site
   * See ass
   * ets/styles/themes/ directory
   */
  setBodyThemeClass(siteId: string = '1') {
    if(!siteId) siteId = '1';
    document.querySelector('body')?.classList.remove(`theme-site-id-1`); // remove default class
    document.querySelector('body')?.classList.add(`theme-site-id-${siteId}`); // apply actual class
  }
}
