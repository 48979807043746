<p-overlayPanel #op>
  <h5><b>Card Code Verification</b></h5>
  <p>
    A Card Code Verification is an anti-fraud security feature to help verify that you are in possession of your
    credit card.
  </p>
  <p>
    <b>Visa, MasterCard & Discover:</b> This number is printed on your cards in the signature area of the back of the card.
    <b>American Express:</b> This number is printed on the front of your card.
  </p>
  <div class="down-arrow"></div>
</p-overlayPanel>

<a (mouseover)="op.show($event)"
   (mouseout)="op.hide()"
   class="text-color pointer"
   ui-overlaypanel
>What is this?</a>
