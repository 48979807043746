<div *ngIf="order">
  <div *ngIf="!form || form.value.orderId !== order.orderId && order.orderId" class="order-option-wrapper">
    <div class='row d-flex justify-content-between'>
      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Next Shipment Date</p>
        <p class="col-12  panel-content-value">{{ order.nextShipDate | date:'MMM dd, y' }}</p>
      </div>
      <div class="col-lg-2 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Ship Day</p>
        <p class="col-12 panel-content-value">{{ order.dayOfMonthToShip }}</p>
      </div>

      <div class="col-lg-2 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Order Interval</p>
        <p
          class="col-12 panel-content-value">{{ order.interval | arpOrderOptions : intervalOptions}}</p>
      </div>

      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Auto Apply VitaPoints</p>
        <p class="col-12 panel-content-value">{{ order.autoApplyVitaPoints | arpOrderOptions : pointsOptions }}</p>
      </div>

      <div class="col-lg-2 tcol-sm-4 col-12 pointer ext-lg-right edit-order-link"
           (click)="onEditOrderClick.emit(order)">
        <div class='text-color'>Edit Order</div>
      </div>
    </div>
  </div>

  <div *ngIf="form && (form.value.orderId === order.orderId || !order.orderId)" class="order-option-wrapper arg-order-option"
       [formGroup]="form">
    <div class='row d-flex justify-content-between'>
      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Next Shipment Date</p>
        <p class="col-12  panel-content-value">{{ order.nextShipDate | date:'MMM dd, y' }}</p>
      </div>

      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Ship Day</p>
        <p class="col-12 panel-content-value" [ngClass]="{ 'selected': form.controls['dayOfMonthToShip'].valid }">
          <p-dropdown
            [placeholder]="form.value.dayOfMonthToShip"
            [options]="dayOfMonthOptions"
            formControlName="dayOfMonthToShip">
          </p-dropdown>
        </p>
      </div>

      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Order Interval</p>
        <p class="col-12 panel-content-value" [ngClass]="{ 'selected': form.controls['interval'].valid }">
          <p-dropdown
            [placeholder]="form.value.interval"
            [options]="frequencyOptions"
            formControlName="interval">
          </p-dropdown>
        </p>
      </div>

      <div class="col-lg-3 col-sm-6 col-12 margin-bottom-30">
        <p class="col-12 panel-content-title">Auto Apply VitaPoints</p>
        <p class="col-12 panel-content-value" [ngClass]="{ 'selected': form.controls['autoApplyVitaPoints'].valid }">
          <p-dropdown
            [placeholder]="form.value.autoApplyVitaPoints"
            [options]="usingPointsOptions"
            formControlName="autoApplyVitaPoints">
          </p-dropdown>
        </p>
      </div>
    </div>
  </div>
