<app-spinner
  *ngIf="isLoading"
  [spinnerText]="'Loading...'"
  [disableFullScreen]="true"
></app-spinner>

<div class="panel-wrapper">
  <p-panel
    class="my-compensations"
    id="my-compensations"
    header="My Compensations"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      <div>
        <section class="dashboard">
          <h2 class="title text-center">My Status</h2>

          <table class="table table-bordered table-sm">
            <thead>
              <th></th>
              <th>Last Year</th>
              <th>Year to Date</th>
            </thead>
            <tbody *ngIf="compensationDashboard">
              <tr>
                <td class="data-label">Order</td>
                <td class="data">
                  {{ compensationDashboard.Orders_LastYear }}
                </td>
                <td class="data">
                  {{ compensationDashboard.Orders_YearToDate }}
                </td>
              </tr>
              <tr>
                <td class="data-label">Paid</td>
                <td class="data money">
                  ${{
                    compensationDashboard.Paid_LastYear
                      ? compensationDashboard.Paid_LastYear
                      : 0
                  }}
                </td>
                <td class="data money">
                  ${{
                    compensationDashboard.Paid_YearToDate
                      ? compensationDashboard.Paid_YearToDate
                      : 0
                  }}
                </td>
              </tr>
              <tr>
                <td class="data-label">Referrals</td>
                <td class="data">
                  {{ compensationDashboard.Referrals_LastYear }}
                </td>
                <td class="data">
                  {{ compensationDashboard.Referrals_YearToDate }}
                </td>
              </tr>
              <tr>
                <td class="data-label">Total Referral:</td>
                <td class="data" colspan="2">
                  {{ compensationDashboard.Referrals_Total }}
                </td>
              </tr>
              <tr>
                <td class="data-label">As of Date:</td>
                <td style="color: red; text-align: center" colspan="2">
                  {{ compensationDashboard.AsOfDate | date }}
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-center"><a target="_blank" href="http://trivita.cdn.s3.amazonaws.com/US/files/MWSCompensation.pdf">Learn more about compensation.</a></p>
        </section>

        <section class="statement">
          <h2 class="title text-center">Statement</h2>
          <form>
            <div class="form-row d-flex">
              <div class="form-group">
                <label for="fromDate">Form: </label>
                <p-calendar
                  id="fromDate"
                  name="fromDate"
                  [(ngModel)]="fromDate"
                  [minDate]="minDate"
                  [maxDate]="today"
                  [readonlyInput]="true"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="2020:{{maxYear}}"
                ></p-calendar>
              </div>
              <div class="form-group">
                <label for="toDate">To: </label>
                <p-calendar
                  id="toDate"
                  name="toDate"
                  [(ngModel)]="toDate"
                  [minDate]="minDate"
                  [maxDate]="today"
                  [readonlyInput]="true"
                  [monthNavigator]="true"
                  [yearNavigator]="true"
                  yearRange="2020:{{maxYear}}"
                ></p-calendar>
              </div>
            </div>
          </form>

          <button (click)="showStatement()" class="submit-btn bg-color my-4">
            Show Statements
          </button>
        </section>
      </div>
    </div>
  </p-panel>
</div>

<div *ngIf="showStatementModal" class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showStatementModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
    [position]="'center'"
  >
    <p-header>
      <h2 class="title text-center">Compensation Statements</h2>
    </p-header>
    <div class="content">
      <div class="info" id="statementInfo">
        <p class="customer-name text-center my-0">
          Customer: {{ user.Name }} - {{ user.memberId }}
        </p>
        <p class="date-range text-center my-0">
          From <b>{{ fromDate | date }}</b> - <b>{{ toDate | date }}</b>
        </p>
        <div class="action">
          <button (click)="exportPdf()" class="btn btn-primary bg-color"> Export as pdf</button>
        </div>
      </div>

      <div class="table-wrapper">
        <p-table
          class="statement-table"
          [value]="statements"
          [styleClass]="'p-datatable-sm'"
          [tableStyle]="{ 'min-width': '60rem', 'max-width': '70vw' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="Period" >
                Period <p-sortIcon field="Period"></p-sortIcon>
              </th>
              <th pSortableColumn="Order" >
                Order <p-sortIcon field="Order"></p-sortIcon>
              </th>
              <th pSortableColumn="Date" >
                Date <p-sortIcon field="Date"></p-sortIcon>
              </th>
              <th pSortableColumn="Type" >
                Type <p-sortIcon field="Type"></p-sortIcon>
              </th>
              <th pSortableColumn="Percentage" >
                Percentage <p-sortIcon field="Percentage"></p-sortIcon>
              </th>
              <th class="money" pSortableColumn="Retail" >
                Retail
                <i
                  [pTooltip]="
                    'Retail product value your Referral customer paid for the product.  The total does not include shipping costs, taxes, or the use of Vitapoints.'
                  "
                  tooltipPosition="top"
                  class="fa fa-question-circle"
                ></i
                ><p-sortIcon field="Retail"></p-sortIcon>
              </th>
              <th class="money" pSortableColumn="Value" >
                Value
                <i
                  [pTooltip]="
                    'The product value that your compensation will be based off of.'
                  "
                  tooltipPosition="top"
                  class="fa fa-question-circle"
                ></i>
                <p-sortIcon field="Value"></p-sortIcon>
              </th>
              <th class="money" pSortableColumn="Paid" >
                Paid
                <i
                  [pTooltip]="
                    'The total amount you were paid based on product value and eligible compensation percentages.'
                  "
                  tooltipPosition="top"
                  class="fa fa-question-circle"
                ></i>
                <p-sortIcon field="Paid"></p-sortIcon>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-detail>
            <tr>
              <td>{{ detail.Period ? detail.Period : "-" }}</td>
              <td>{{ detail.Order ? detail.Order : "-" }}</td>
              <td>
                {{ detail.Date ? (detail.Date | date : "MM/dd/yyyy") : "-" }}
              </td>
              <td>{{ detail.Type ? detail.Type : "-" }}</td>
              <td>{{ detail.Percentage ? detail.Percentage + "%" : "-" }}</td>
              <td class="money">
                {{ detail.Retail ? "$" + detail.Retail.toFixed(2) : "-" }}
              </td>
              <td class="money">
                {{ detail.Value ? "$" + detail.Value.toFixed(2) : "-" }}
              </td>
              <td class="money">
                {{ detail.Paid ? "$" + detail.Paid.toFixed(2) : "-" }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr class="total-row">
              <td colspan="5">Total</td>
              <td class="money">${{ retailTotal.toFixed(2) }}</td>
              <td class="money">${{ valueTotal.toFixed(2) }}</td>
              <td class="money">${{ paidTotal.toFixed(2) }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </p-dialog>
</div>
