<div
  class="spinner-wrap"
  [ngClass]="{ 'disable-full-screen': disableFullScreen }"
>
  <!-- <ngx-spinner type="ball-scale-multiple"></ngx-spinner> -->
  <div class="p-spinner">

    <p-progressSpinner></p-progressSpinner>
  </div>

  <div *ngIf="spinnerText" class="spinner-text">{{ spinnerText }}</div>
</div>
