import { Component } from '@angular/core';
import { LocalStorage } from '@helpers/local-storage.decorator';
import { UserInfo } from '@models/user-info.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  year = new Date().getFullYear();
  @LocalStorage() user!: UserInfo;
}
