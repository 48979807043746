<div class="panel-wrapper">
  <p-panel
    class="wecare"
    id="wecare"
    header="WeCare"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      <p>
        From time to time, we all need someone we can talk to who will listen,
        pray, and provide Christian-based guidance and support to help ease your
        pain or concerns. WeCARE is a complementary and confidential helpline
        purposed to help you get through difficult times.
      </p>
      <p>
        Our aim is to provide you with the love, peace, and comfort that
        promotes the process of healing and recovery of the things that are
        causing your distress. As a Premier Member, this free and complimentary
        service is here for you because we truly care!
      </p>

      <button
        class="submit-btn bg-color"
        type="submit"
        id="btnReset"
        (click)="bookAppoinment()"
      >
        Book an Appoinment
      </button>
    </div>
  </p-panel>
</div>

<!-- <div class="refer-a-friend-dialog">
  <p-dialog
    [header]="'VitaPoints Gift Card Email'"
    [draggable]="false"
    [modal]="true"
    [resizable]="false"
    [(visible)]="visible"
    (onHide)="visible = false"
  >
    <ng-container *ngIf="isEmailFormContent; else actionButtons">
      <div class="dialog-description">
        Enter the required information below to email your VitaPoint Gift Card.
      </div>
      <div>Send To:</div>
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div
          class="form-group"
          [ngClass]="{ 'wrong-input': showError(form, 'email', submitted) }"
        >
          <input
            pInputText
            type="text"
            placeholder="Email Address"
            formControlName="email"
            class="form-control"
          />
          <p *ngIf="showError(form, 'email', submitted)" class="error-message">
            Please enter email.
          </p>
        </div>
        <button [disabled]="form.invalid" type="submit" class="bg-color">
          Send
        </button>
      </form>
    </ng-container>

    <ng-template #actionButtons>
      <div class="dialog-description">
        Your VitaPoint Gift Card has been sent.
      </div>
      <div class="row w-100 d-flex">
        <div class="col-6">
          <button class="bg-color" (click)="isEmailFormContent = true">
            Send Another
          </button>
        </div>
        <div class="col-6">
          <button class="bg-color" (click)="visible = false">Close</button>
        </div>
      </div>
    </ng-template>
  </p-dialog>
</div> -->

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>

<div class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showAppoinmentModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
  >
    <p-header>
      <div class="row w-100">
        <div class="col-12 col-sm-7">
          <span> Book an Appoinment</span>
        </div>
      </div>
    </p-header>
    <iframe
      name="iframe1"
      src="https://clients.vcita.com/portal/mmpfq7uul9x5orio#/schedule?isWidget=true&mobile=&mode=no_header_scroll&o64=calendar_widget&o=calendar_widget&preview=&s=&service_id=792y077hnzr4brym&widget=true&widget_type=calendar_widget&frontage_iframe=true&fromName&step=1"
    ></iframe>
  </p-dialog>
</div>
