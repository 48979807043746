import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ArpOrder } from '@models/arp-order.model';
import { UserInfo } from '@models/user-info.model';

@Component({
  selector: 'app-select-products-step',
  templateUrl: './select-products-step.component.html',
  styleUrls: ['./select-products-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SelectProductsStepComponent implements OnInit {
  @Input() arpOrder!: ArpOrder;
  @Input() form!: FormGroup;
  @Input() user!: UserInfo;
  @Output() onClickNextBtn = new EventEmitter<string>();

  public dialogContentMaxWidth = window.innerWidth - 300;

  /**
   * Vertical window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.addControls();
  }

  // add control "product" to add/edit arp order form
  private addControls(): void {
    let products: any[] = [];
    if(this.arpOrder && this.arpOrder.products){
      products = this.arpOrder.products;
    }
    this.form.addControl(
      'products',
      this.formBuilder.array(
        products.map((product) =>
          this.buildProductFormGroup(product)
        )
      )
    );
  }

  getProductsFormArray() {
    return this.form.controls['products'] as FormArray;
  }
  // add selected products to form fields
  public addSelectedProduct(product: any): void {
    this.getProductsFormArray()['push'](this.buildProductFormGroup(product));
    // need to call change detection in child datatable
    this.getProductsFormArray().controls =
      this.getProductsFormArray().controls.slice();
  }

  private buildProductFormGroup(product: any): FormGroup {
    return this.formBuilder.group({
      productName: product?.productName,
      itemNumber: product?.itemNumber,
      productId: product?.productId,
      price: product?.price,
      cartImage: product?.cartImage,
      quantity: [product?.quantity || 1, Validators.min(1)],
    });
  }

  // remove selected product
  public removeSelectedProduct(product: any): void {
    const removedProductIndex: number = this.form.controls[
      'products'
    ].value.findIndex((item: any) => item.productId === product.productId);
    this.getProductsFormArray()['removeAt'](removedProductIndex);
    // need to call change detection in child datatable
    this.getProductsFormArray().controls =
      this.getProductsFormArray().controls.slice();
  }

  // move to next wizard step
  public finishThisStep(): void {
    this.onClickNextBtn.emit('configureOrder');
  }
}
