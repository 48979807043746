<p-dialog
  header="Email Video Message"
  [blockScroll]="true"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [(visible)]="emailDialogIsVisible"
  (onHide)="onDialogHide.emit('emailDialogIsVisible')">

  <form
    *ngIf="messageForm"
    class="container"
    [formGroup]="messageForm"
    (ngSubmit)="onSubmit(messageForm.value)">

    <div class="row">
      <div
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        [ngClass]="{ 'wrong-input': showError(messageForm, 'firstName', messageFormIsSubmitted)}">
        <label class="col-12 panel-content-title">Send video to</label>
        <input
          class="col-12"
          type='text'
          placeholder='First Name'
          formControlName="firstName"/>
        <p *ngIf="showError(messageForm, 'firstName', messageFormIsSubmitted)">Please enter First Name.</p>
      </div>
      <div
        class="col-md-4 col-lg-4 col-sm-12 col-xs-12"
        [ngClass]="{ 'wrong-input': showError(messageForm, 'lastName', messageFormIsSubmitted)}">
        <label class="col-12 panel-content-title"></label>
        <input
          class="col-12"
          type='text'
          placeholder='Last Name'
          formControlName="lastName"/>
        <p *ngIf="showError(messageForm, 'lastName', messageFormIsSubmitted)">Please enter Last Name.</p>
      </div>
    </div>

    <div class="row">
      <div
        class="col-md-8 col-lg-8 col-sm-12 col-xs-12"
        [ngClass]="{ 'wrong-input': showError(messageForm, 'emailAddress', messageFormIsSubmitted)}">
        <input
          class="col-12"
          type='text'
          placeholder='Email'
          formControlName="emailAddress"/>
        <p *ngIf="showError(messageForm, 'emailAddress', messageFormIsSubmitted)">Please enter correct Email.</p>
      </div>
    </div>

    <br/>
    <div class="row">
      <div
        class="col-12"
        [ngClass]="{ 'wrong-input': showError(messageForm, 'subject', messageFormIsSubmitted)}">
        <label class="col-12 panel-content-title">
          Subject (edit if desired)
        </label>
        <input
          class="col-12"
          type='text'
          placeholder='Subject'
          formControlName="subject"/>
        <p *ngIf="showError(messageForm, 'subject', messageFormIsSubmitted)">Please enter message Subject.</p>
      </div>
    </div>

    <div class="row">
      <div
        class="col-12"
        [ngClass]="{ 'wrong-input': showError(messageForm, 'message', messageFormIsSubmitted)}">
          <textarea
            class="dialog-textarea"
            placeholder='Type your message'
            formControlName="message">
          </textarea>
        <p *ngIf="showError(messageForm, 'message', messageFormIsSubmitted)">Please enter your Message.</p>
      </div>
    </div>
    <div class="margin-bottom-30 row justify-content-end">
      <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6">
        <button class="submit-btn bg-color" type='submit'>Send</button>
      </div>
      <div class="col-md-3 col-lg-3 col-sm-6 col-xs-6">
        <button class="cancel-btn" type='button' (click)="onDialogHide.emit('emailDialogIsVisible')">Cancel</button>
      </div>
    </div>
  </form>
</p-dialog>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>

