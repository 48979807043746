import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CountryService } from '@services/country.service';
import { UserService } from '@services/user.service';
import { Country } from '@models/country.model';
import {
  Subject,
  combineLatest,
  concat,
  concatMap,
  filter,
  merge,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Patterns } from '@helpers/helper';
import { Address } from '@models/address.model';
import { AddressService } from '@services/address.service';
import { ContactInfoService } from '@services/contact-info.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { HttpService } from '@services/http.service';
import { UserInfo } from '@models/user-info.model';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
})
export class UpdateProfileComponent implements OnInit, OnDestroy {
  contactForm!: FormGroup;
  countries!: Country[];
  selectedShippingCountry!: Country;
  shippingPostalCodeMask!: string;

  selectedBillingCountry!: Country;
  billingPostalCodeMask!: string;

  destroy$ = new Subject<void>();
  isLoading = false;

  constructor(
    private formBuilder: FormBuilder,
    public userService: UserService,
    private countryService: CountryService,
    private addressService: AddressService,
    private contactInfoService: ContactInfoService,
    private router: Router,
    private http: HttpClient,
    private httpService: HttpService
  ) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phone: ['', Validators.required],
      email: [
        { value: this.userService.user.email, disabled: true },
        Validators.required,
      ],

      //Shipping Address Fields
      shippingFullName: ['', Validators.required],
      shippingAddressLine1: ['', Validators.required],
      shippingAddressLine2: [''],
      shippingCity: ['', Validators.required],
      shippingState: ['', Validators.required],
      shippingZip: ['', Validators.required],
      shippingCountry: [
        this.selectedShippingCountry?.value,
        Validators.required,
      ],

      //Billing Address Fields
      billingFullName: ['', Validators.required],
      billingAddressLine1: ['', Validators.required],
      billingAddressLine2: [''],
      billingCity: ['', Validators.required],
      billingState: ['', Validators.required],
      billingZip: ['', Validators.required],
      billingCountry: [this.selectedBillingCountry?.value, Validators.required],

      sameAsShipping: [false],
    });

    this.countryService
      .getCountriesWithStates()
      .pipe(
        takeUntil(this.destroy$),
        tap((countries: Country[]) => {
          console.log(countries);
          this.countries = countries.filter(
            (x) => x.value == 1 || x.value == 2
          );

          this.selectedShippingCountry = countries.find(
            (country: Country) => country.value === 1
          )!;
          this.selectedBillingCountry = this.selectedShippingCountry;
          this.contactForm.patchValue({
            shippingCountry: this.selectedShippingCountry?.value,
            billingCountry: this.selectedBillingCountry?.value,
          });
        })
      )
      .subscribe();

    this.contactForm.controls['shippingCountry'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selectedShippingCountry = this.countries.find(
          (x) => x.value == value
        )!;
        this.definePostalCodeMask();
      });

    this.contactForm.controls['billingCountry'].valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.selectedBillingCountry = this.countries.find(
          (x) => x.value == value
        )!;

        this.definePostalCodeMask();
      });
  }

  definePostalCodeMask() {
    this.shippingPostalCodeMask =
      this.selectedShippingCountry.value === 2
        ? Patterns.postalCode.mask.canada
        : Patterns.postalCode.mask.usa;
    this.billingPostalCodeMask =
      this.selectedBillingCountry.value === 2
        ? Patterns.postalCode.mask.canada
        : Patterns.postalCode.mask.usa;
  }
  onSubmit() {
    if (this.contactForm.valid) {
      let contact = {
        firstName: this.contactForm.get('firstName')?.value,
        lastName: this.contactForm.get('lastName')?.value,
        phone: this.contactForm.get('phone')?.value,
      };
      let shippingAddress = this.getSubmittedAddress('shipping');
      let billingAddress = this.getSubmittedAddress('billing');
      this.isLoading = true;
      this.contactInfoService
        .updateContactInfo(contact)
        .pipe(
          concatMap((data) => {
            return this.addressService.updateAddressList(shippingAddress);
          }),
          concatMap((data) => {
            return this.addressService.updateAddressList(billingAddress);
          }),
          concatMap((data) => {
            return this.http.get(environment.auth0.audience + '/customer', {
              headers: this.httpService.getHeaders(
                'application/json',
                `bearer ${this.userService.user.token}`
              ),
            });
          })
        )
        .subscribe({
          next: (user: any) => {
            this.userService.setCurrentUser({
              ...user,
              token: this.userService.user.token,
            });

            let referralString = localStorage.getItem('referral');
            if (referralString?.toLowerCase().includes('trivita.com')) {
              window.location.href =  environment.auth0.logoutReturnUS;
            } else if(referralString?.toLowerCase().includes('trivita.ca')) {
              //this.router.navigate(['main']);
              window.location.href =  environment.auth0.logoutReturnCA;
            }
            this.isLoading = false;
          },
          error: (err) => {
            console.log(err);
            this.isLoading = false;
          },
        });

      // Handle form submission logic (e.g., sending data to a server)
      console.log(this.contactForm.value);
      // Reset the form after successful submission
      this.contactForm.reset();
    }
  }

  copyShippingAddress(event: any) {
    if (event.target.checked) {
      const shippingAddress = this.contactForm?.get(
        'shippingAddressLine1'
      )?.value;
      const shippingLine2 = this.contactForm?.get(
        'shippingAddressLine2'
      )?.value;
      const shippingCity = this.contactForm?.get('shippingCity')?.value;
      const shippingState = this.contactForm?.get('shippingState')?.value;
      const shippingZip = this.contactForm?.get('shippingZip')?.value;
      const shippingCountry = this.contactForm?.get('shippingCountry')?.value;

      this.contactForm.patchValue({
        billingFullName: this.contactForm?.get('shippingFullName')?.value,
        billingAddressLine1: shippingAddress,
        billingAddressLine2: shippingLine2,
        billingCity: shippingCity,
        billingState: shippingState,
        billingZip: shippingZip,
        billingCountry: shippingCountry,
      });
    } else {
      // Clear the billing address fields if checkbox is unchecked
      this.contactForm.patchValue({
        billingFullName: '',
        billingAddressLine1: '',
        billingAddressLine2: '',
        billingCity: '',
        billingState: '',
        billingZip: '',
        billingCountry: '',
      });
    }
  }

  getSubmittedAddress(addressType: 'shipping' | 'billing') {
    if (addressType == 'shipping') {
      return {
        addressId: 0,
        addressName: this.contactForm.get('shippingFullName')?.value,
        address1: this.contactForm.get('shippingAddressLine1')?.value,
        address2: this.contactForm.get('shippingAddressLine2')?.value,
        state: this.contactForm.get('shippingState')?.value,
        postalCode: this.contactForm.get('shippingZip')?.value,
        city: this.contactForm.get('shippingCity')?.value,
        countryId: this.contactForm.get('shippingCountry')?.value,
        isDefaultBillingAddress: false,
        isDefaultShippingAddress: true,
      };
    } else {
      return {
        addressId: 0,
        addressName: this.contactForm.get('billingFullName')?.value,
        address1: this.contactForm.get('billingAddressLine1')?.value,
        address2: this.contactForm.get('billingAddressLine2')?.value,
        state: this.contactForm.get('billingState')?.value,
        postalCode: this.contactForm.get('billingZip')?.value,
        city: this.contactForm.get('billingCity')?.value,
        countryId: this.contactForm.get('billingCountry')?.value,
        isDefaultBillingAddress: true,
        isDefaultShippingAddress: false,
      };
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
