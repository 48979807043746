<div class="panel-wrapper">
  <p-panel header="Telehealth"     [toggler]="'header'"
   [collapsed]="panelCollapsed" [toggleable]="true">
    <div class="panel-description">
      <p>
        TriVita’s telehealth services, powered by Ocenture, are meant to empower
        you with confidence knowing you have access to state-licensed medical
        professionals across the U.S. by phone. Telehealth services are for
        acute care issues or symptoms of illness when you don’t know what to do.
      </p>
      <ul>
        <li>All doctors are board-certified and state-licensed</li>
        <li>
          Safe and HIPAA compliant with medical record access by patient and
          doctor only
        </li>
        <li>
          Expert medical care available 24/7/365, including times your
          healthcare provider may not be available, such as after hours,
          weekends and holidays
        </li>
      </ul>
      <p class="text-danger">
        <b
          >If you are experiencing a life-threatening emergency, call 911
          immediately.</b
        >
      </p>
      <div class="telehealth-portal">
        <div class="blocker" *ngIf="ocenture && ocenture?.acceptedPNP == 0">
          <div class="form-inline">
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  style="margin-right: 10px"
                  [(ngModel)]="isTersmCheckBoxChecked"
                />
                <span
                  >I agree to the
                  <a
                    target="_blank"
                    href="http://trivita.cdn.s3.amazonaws.com/US/files/Terms%20of%20Use%20TriVita%20Telehealth.pdf"
                  >
                    terms & conditions.
                  </a></span
                >
              </label>
            </div>
          </div>
          <button
            class="btn bg-color"
            [disabled]="!isTersmCheckBoxChecked || tersmSubmitted"
            (click)="onTermsAgree()"
          >
            Submit
          </button>
        </div>
        <div class="pmp-secondaries">
          <app-manage-secondary-accounts
            *ngIf="ocenture && ocenture?.flagID == 1520"
            [country]="country"
          ></app-manage-secondary-accounts>
        </div>
        <button
          class="submit-btn bg-color"
          [disabled]="expired ? true : false"

          (click)="telehealth.emit()"
        >
          Telehealth Portal
        </button>
      </div>
    </div>

    <div class="pmp__footer">
      <!-- <div
        *ngIf="expired"
        (click)="reactivate.emit()"
        class="pmp__reactivate-link"
      >
        Reactivate Premier Membership Plus
      </div> -->

      <div
        *ngIf="!(ocenture && ocenture.acceptedPNP == 0) && !expired"
        class="pmp__cancel-link"
        (click)="cancelSubscription.emit()"
      >
        Cancel
      </div>
      <div class="pmp__phone-number">
        To inquire about medical records or telehealth services, please contact
        Ocenture at (855) 359-2084, 9am-9pm Eastern. For questions about
        telehealth billing, contact Member Service at 1-877-850-2967, Monday
        through Friday, 8am-8pm Eastern, or email MemberService@TriVita.com
      </div>
    </div>
  </p-panel>
</div>
