import { catchError, tap } from "rxjs/operators";

import {
  ReferralModel,
  ReferralOrderDetailModel,
  ReferralOrderModel,
} from "./models/referral.model";
import { Component, OnInit } from "@angular/core";
import { EMPTY } from "rxjs";
import { map } from "lodash";
import { UserService } from "@services/user.service";
import { CompensationService } from "@services/compensation.service";

@Component({
  selector: "app-my-referrals",
  templateUrl: "./my-referrals.component.html",
  styleUrls: ["./my-referrals.component.css"],
})
export class MyReferralsComponent implements OnInit {
  isLoading = false;
  user: any;
  showFullScreen = false;
  panelCollapsed = true;
  showOrdersModal = false;
  showOrderDetailsModal = false;

  myReferrals: ReferralModel[] = [];

  referralOrders!: ReferralOrderModel[];
  referralOrderDetails!: ReferralOrderDetailModel[];

  selectedReferral!: ReferralModel;
  selectedOrder!: ReferralOrderModel;

  constructor(
    private authService: UserService,
    private referralService: CompensationService
  ) {
    this.user = authService.getCurrentUser();
  }

  ngOnInit(): void {
    //this.myReferrals = [];
    this.getReferrals();
  }

  getReferrals() {
    this.isLoading = true;

    this.referralService
      .getReferrals(this.user.memberId)
      .pipe(
        catchError((err, caught) => {
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((res) => {
        this.isLoading = false;
        this.myReferrals = res;
        console.log(res);
      });
  }

  getOrders(referralID: number) {
    this.isLoading = true;
    this.referralOrders = [];
    this.referralOrderDetails = [];
    this.referralService
      .getOrders(referralID)
      .pipe(
        catchError((err, caught) => {
          this.isLoading = false;

          return EMPTY;
        })
      )
      .subscribe((res) => {
        console.log(res);
        this.isLoading = false;
        if (res) {
          this.referralOrders = res;
        }
      });
  }
  showOrders(referral: ReferralModel) {
    this.selectedReferral = referral;
    this.showOrdersModal = true;
    this.getOrders(referral.Account);
  }

  showOrderDetails(order: ReferralOrderModel) {
    console.log(order);
    this.selectedOrder = order;
    this.showOrderDetailsModal = true;

    this.getOrderDetails(order.Order);
  }

  getOrderDetails(orderNumber: number) {
    this.isLoading = true;
    this.referralOrderDetails = [];
    this.referralService
      .getOrderDetails(orderNumber)
      .pipe(
        catchError((err, caught) => {
          this.isLoading = false;
          return EMPTY;
        })
      )
      .subscribe((res) => {
        console.log(res);
        this.isLoading = false;
        if (res) {
          this.referralOrderDetails = res.map((x: any) => {
            return { ...x, Price: +x.Price, Extended: +x.Extended };
          });
        }
      });
  }
}
