import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Address, ArpOrder } from '@models/arp-order.model';
import { Country } from '@models/country.model';
import { UserInfo } from '@models/user-info.model';
import { AddressService } from '@services/address.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-select-shipping-address-step',
  templateUrl: './select-shipping-address-step.component.html',
})
export class SelectShippingAddressStepComponent implements OnInit, OnDestroy {
  @Input() arpOrder!: ArpOrder;
  @Input() country!: Country;
  @Input() form!: FormGroup;
  @Input() user!: UserInfo;

  @Output() onClickNextBtn = new EventEmitter<string>();
  @Output() onSelectAddress = new EventEmitter<any>();

  public addressItems!: { address: Address; checked: boolean }[];
  public addresses!: Address[];
  public dialogContentMaxWidth = window.innerWidth - 300;
  public isLoading = false;
  public spinnerText!: string;

  private defaultAddressId!: number | null;
  private changeDetectionInterval: any;
  private sub!: Subscription;

  /**
   * Window resize handler for opened dialog
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.dialogContentMaxWidth = event.target.innerWidth - 300;
  }

  constructor(
    private addressService: AddressService,
    private ref: ChangeDetectorRef,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService
  ) {
    this.changeDetectionInterval = setInterval(() => {
      ref.markForCheck();
    }, 50);
  }

  ngOnInit(): void {
    this.getAddressList();
    if (!this.form.controls['shippingAddressId']) {
      this.addControl();
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // add "shippingAddressId" field to general form
  private addControl(): void {
    this.form.addControl(
      'shippingAddressId',
      new FormControl(this.defaultAddressId, Validators.required)
    );
  }

  /**
   * Start editing address
   */
  public addEditAddress(address: any): void {
    this.wizardHelperService.setAddressEditing(address);
    this.onClickNextBtn.emit('addEditShippingAddress');
  }

  // move to previous wizard step
  public backToPreviousStep(): void {
    this.onClickNextBtn.emit('configureOrder');
  }

  // handler toggle btn event
  public changeCheckedAddress(checked: any, address: any): void {
    if (checked) {
      this.form.controls['shippingAddressId'].setValue(
        address.address.addressId
      );
      this.addressItems.map((item) => {
        item.checked = false;
        if (item.address.addressId === address.address.addressId) {
          item.checked = true;
        }
      });
    } else {
      this.form.controls['shippingAddressId'].setValue('');
      this.addressItems.map((item) => {
        item.checked = false;
      });
    }
  }

  /**
   * Updarte component addresses list and set shipping address id
   * @param addresses - addresses list
   */
  private updateAddresses(addresses: Address[]): void {
    if (
      this.form.controls['shippingAddressId'] &&
      this.form.controls['shippingAddressId'].value
    ) {
      const defaultAddress = addresses.find(
        (item) =>
          item.addressId === this.form.controls['shippingAddressId'].value
      );
      this.defaultAddressId = defaultAddress ? defaultAddress.addressId : null;
    } else {
      const defaultAddress = addresses.find(
        (item) => item.isDefaultShippingAddress === true
      );
      this.defaultAddressId = defaultAddress ? defaultAddress.addressId : null;
    }

    const addressItems = addresses.map((address) => {
      if (address.addressId === this.defaultAddressId) {
        return { address, checked: true };
      } else {
        return { address, checked: false };
      }
    });
    this.addressItems = addressItems.slice();
  }

  // get address list from api
  private getAddressList(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe(
      (addresses: Address[]) => {
        this.addresses = addresses;
        if (!addresses.length) {
          this.addEditAddress(null);
        } else {
          this.updateAddresses(addresses);
        }
      }
    );
  }

  // remove address from address list by id
  public removeAddress(addressId: any): void {
    this.isLoading = true;
    this.spinnerText = 'Processing your request...';
    this.addressService.removeAddress(this.user.memberId, addressId).subscribe({
      next: (response) => {
        this.wizardHelperService.setAddressList(response.addresses);
        this.isLoading = false;
        this.spinnerText = '';
        this.messageService.add({
          severity: 'success',
          summary: 'Address was deleted',
        });
      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.spinnerText = '';
        this.messageService.add({
          severity: 'error',
          summary: error.message,
        });
      },
    });
  }

  // move to next wizard step
  public finishThisStep(): void {
    this.onClickNextBtn.emit('selectPaymentMethod');
  }
}
