import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpService } from './http.service';
import { ConfigService } from './config.service';

@Injectable()
export class AddressService {
  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private authService: UserService
  ) {}

  get userToken(): any {
    if (this.authService.getCurrentUser()) {
      return this.authService.user.token
    }

    return null;
  }

  // Get all addresses
  getAllAddresses(memberId: any) {
    return this.httpService.get(
      `address/getAddresses?id=${memberId}`,
      'global',
      this.userToken
    );
  }

  // Get address by id
  getAddressById(memberId: any, addressIds: number[], userToken: string) {
    const forkJoinArray: Observable<any>[] = [];
    for (const addressId of addressIds) {
      const observable = this.httpService.get(
        `address/getAddressById?id=${memberId}&addressId=${addressId}`,
        'global',
        userToken
      );
      forkJoinArray.push(observable);
    }
    return forkJoin(forkJoinArray);
  }

  // Remove address
  removeAddress(memberId: any, addressId: any) {
    return this.httpService.put(
      `address/${memberId}?addressId=${addressId}`,
      'global',
      null,
      this.userToken
    );
  }

  // Update exist or create new addresses
  updateAddressList(address: any) {
    const memberId = this.authService.getCurrentUser().memberId;
    address.addressId = address.addressId ? address.addressId : 0;
    return this.httpService.post(
      `address/UpsertAddress?id=${memberId}`,
      'global',
      address,
      this.userToken
    );
  }
}
