import { UserService } from './user.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import { CardModel } from '@models/card.model';


@Injectable()
export class PaymentService {
  userToken: string;

  constructor(private httpService: HttpService, private authService: UserService) {
    this.userToken = this.authService.user.token;
  }

  // get payment options from api
  getCardTypes(): Observable<CardModel> {
    return this.httpService.get(`cardhelper/`, 'cart');
  }

  getPaymentList(memberId: any) {
    return this.httpService.get(`card/GetCards?id=${memberId}`, 'global', this.userToken);
  }
}
