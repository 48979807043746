<p-table
  class="referral-table"
  [value]="referralOrders"
  [styleClass]="'p-datatable-sm'"
  [tableStyle]="{ 'min-width': '60rem' }"
>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="Order" >
        Order <p-sortIcon field="Order"></p-sortIcon>
      </th>
      <th pSortableColumn="Date" >
        Date <p-sortIcon field="Date"></p-sortIcon>
      </th>

      <th pSortableColumn="Type" >
        Type <p-sortIcon field="Type"></p-sortIcon>
      </th>
      <th pSortableColumn="Percentage" >
        Percentage <p-sortIcon field="Percentage"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="Retail" >
        Retail
        <i
          [pTooltip]="
            'Retail product value your Referral customer paid for the product.  The total does not include shipping costs, taxes, or the use of Vitapoints.'
          "
          tooltipPosition="top"
          class="fa fa-question-circle"
        ></i>
        <p-sortIcon field="Retail"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="Value" >
        Value <i
        [pTooltip]="
          'The product value that your compensation will be based off of.'
        "
        tooltipPosition="top"
        class="fa fa-question-circle"
      ></i><p-sortIcon field="Value"></p-sortIcon>
      </th>
      <th class="money" pSortableColumn="Paid" >
        Paid <i
        [pTooltip]="
          'The total amount you were paid based on product value and eligible compensation percentages.'
        "
        tooltipPosition="top"
        class="fa fa-question-circle"
      ></i><p-sortIcon field="Paid"></p-sortIcon>
      </th>
      <th ></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order>
    <tr>
      <td>{{ order.Order }}</td>
      <td>{{ order.Date | date : "MM/dd/yyyy" }}</td>
      <td>{{ order.Type }}</td>
      <td>{{ order.Percentage ? order.Percentage + "%" : "-" }}</td>
      <td class="money">
        {{ order.Retail ? "$" + order.Retail.toFixed(2) : "-" }}
      </td>
      <td class="money">
        {{ order.Value ? "$" + order.Value.toFixed(2) : "-" }}
      </td>
      <td class="money">
        {{ order.Paid ? "$" + order.Paid.toFixed(2) : "-" }}
      </td>
      <td>
        <button (click)="onShowDetail(order)" class="btn btn-primary btn-view">
          Detail
        </button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="footer">
    <tr class="total-row">
      <td colspan="4">Total</td>
      <td class="money">${{ retailTotal ? retailTotal.toFixed(2) : 0 }}</td>
      <td class="money">${{ valueTotal ? valueTotal.toFixed(2) : 0 }}</td>
      <td class="money">${{ paidTotal ? paidTotal.toFixed(2) : 0 }}</td>
      <td></td>
    </tr>
  </ng-template>
</p-table>
