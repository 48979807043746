import { UserService } from './user.service';
import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

interface IPaymentInfo {
  PaymentAccountID: string;
  CustID: string;
  CCNum: string;
  CCExp: string;
  CVV: string;
  BillAddID: string;
}

@Injectable()
export class SubscriptionService {
  constructor(
    private httpService: HttpService,
    private authService: UserService,
  ) {}

  get userToken(): string {
    return this.authService.user.token;
  }

  public updateStoreCardById(id: string, body: any) {
    return this.httpService.post(
      `/api/Store/${id}/SaveCard`,
      "wellness",
      body,
      this.userToken
    );
  }

  public paymentInfo(body: { CustID: string; PaymentUsageID: string }) {
    return this.httpService.post(
      "/api/Payment/Management",
      "subscription",
      body,
      this.userToken
    );
  }
}
