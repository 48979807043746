import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import {
  Subject,
  Subscription,
  combineLatest,
  combineLatestWith,
  concatMap,
  map,
  tap,
} from 'rxjs';
import { UserService } from '@services/user.service';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '@models/user-info.model';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from '@services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  isLoading = true;
  destroy$ = new Subject<void>();

  token!: string;

  constructor(
    private router: Router,
    public auth: AuthService,
    public userService: UserService,
    private http: HttpClient,
    private httpService: HttpService
  ) {}

  ngOnInit() {
    this.auth.isLoading$
      .pipe(
        takeUntil(this.destroy$),
        combineLatestWith(this.auth.isAuthenticated$)
      )
      .subscribe(([isLoading, isAuth]) => {
        this.isLoading = isLoading;
        if (!isLoading && !isAuth) {
          this.auth.loginWithRedirect();
        }
      });

    this.auth
      .getAccessTokenSilently()
      .pipe(
        takeUntil(this.destroy$),
        concatMap((token) => {
          this.token = token;
          return this.http.get(environment.auth0.audience+'/customer', {
            headers: this.httpService.getHeaders(
              'application/json',
              `bearer ${token}`
            ),
          });
        })
      )
      .subscribe((user: any) => {
        this.userService.setCurrentUser({
          ...user,
          token: this.token
        });
      });



    this.userService.userChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        if (user && user.memberId) {
          this.router.navigate(['main']);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
