<div
  #content
  class="container address-list-content"
  [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '610px', 'width': dialogContentMaxWidth }"
>
  <app-add-edit-address
    [country]="country"
    [currentAddress]="editableAddress"
    [isEditingOrder]="false"
    typeOfAddress="shipping"
    (onEndAddEditAddress)="endAddEditAddress()">
  </app-add-edit-address>
</div>
