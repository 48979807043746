<div class="container">
  <app-arp-order-personal-info
    [country]='country'
    [order]='order'
    [user]='user'
    (onOpenManagementAddress)="onClickNextBtn.emit('selectShippingAddress')"
    (onOpenManagementPayment)="onClickNextBtn.emit('selectPaymentMethod')"
  ></app-arp-order-personal-info>

  <app-arp-order-option
    [order]="order"
    [form]="editArpOrderForm"
    (onEditOrderClick)="openEditOrderOptions($event)"
  ></app-arp-order-option>
  <app-arp-order-products
    [memberId]="user.memberId"
    [form]="editArpOrderForm"
    [order]="order"
    wizardStepName="reviewYourOrderStep"
    [mode]="productsListMode"
    (onTotalChanged)="totalUpdated($event)"
  ></app-arp-order-products>
</div>

<div
  *ngIf="isCreditCardError"
  class="pointer credit-error-message">
  There was an error processing the credit card provided.
  <a (click)="goToSelectPaymentStep()"> Please update and try again. </a>
</div>

<div class='footer-fixed' *ngIf="!editArpOrderForm">
  <button class="cancel-btn pointer" (click)="backToPreviousStep()">Back</button>
  <button class="cancel-btn pointer" (click)="cancelSubmitOrder()">Сancel</button>
  <button class="submit-btn bg-color pointer" (click)="submitArpOrder(false)">Confirm</button>
</div>
<div class="footer-fixed" *ngIf="editArpOrderForm">
  <button class="cancel-btn pointer" (click)="resetArpOrderOptionsEditing()">Cancel</button>
  <button class="submit-btn bg-color pointer" (click)="saveArpOrderOptions()">Save</button>
</div>

<p-toast></p-toast>
<app-spinner *ngIf="isLoading" [spinnerText]="spinnerText" [disableFullScreen]="true"></app-spinner>
