<div
  class='wizard-step-content'
  [ngStyle]="{'height': '500px', 'overflow-y': 'auto', 'max-width': '900px', 'width': dialogContentMaxWidth}">
  <div *ngIf="form">

    <div [formGroup]="form" class="container configure-order-step">
      <div class="row w-100 margin-bottom-30 d-flex justify-content-center">
        <div class="col-xs-12 col-sm-7">
          <label>
            What day of the month would you like you order shipped?
          </label>
        </div>
        <div class="col-xs-12 col-sm-4" [ngClass]="{ 'selected': form.controls['dayOfMonthToShip'].valid }">
          <p-dropdown
            placeholder="Select day"
            [options]="dayOfMonthOptions"
            formControlName="dayOfMonthToShip">
          </p-dropdown>
        </div>
      </div>

      <div class="row w-100 margin-bottom-30 d-flex justify-content-center">
        <div class="col-xs-12 col-sm-7">
          <label>
            How often would you like your order sent?
          </label>
        </div>
        <div class="col-xs-12 col-sm-4" [ngClass]="{ 'selected': form.controls['interval'].valid }">
          <p-dropdown
            placeholder="Select frequency"
            [options]="frequencyOptions"
            formControlName="interval">
          </p-dropdown>
        </div>
      </div>

      <div class="row w-100 margin-bottom-30 d-flex justify-content-center">
        <div class="col-xs-12 col-sm-7">
          <label>Would you like your VitaPoints to automatically apply to your ARP order?</label>
        </div>
        <div class="col-xs-12 col-sm-4" [ngClass]="{ 'selected': form.controls['autoApplyVitaPoints'].valid }">
          <p-dropdown
            placeholder="Select"
            [options]="usingPointsOptions"
            formControlName="autoApplyVitaPoints">
          </p-dropdown>
        </div>
      </div>

    </div>

  </div>

  <div class='footer-fixed'>
    <button class="cancel-btn pointer" (click)="backToPreviousStep()">Back</button>
    &nbsp;
    &nbsp;
    &nbsp;
    <button
      class="submit-btn bg-color pointer"
      (click)="finishThisStep()"
      [disabled]="form.controls['autoApplyVitaPoints'].invalid || form.controls['dayOfMonthToShip'].invalid || form.controls['interval'].invalid">
      Next
    </button>
  </div>
</div>

