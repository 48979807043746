<div class="row w-100">
  <div class="col-7 header"></div>
  <div class="d-flex justify-content-end col-sm-5 col-12 add-btn-wrapper">
    <div class="pointer add-payment-btn bg-color" (click)="addPayment()">
      <i class="fa fa-plus" aria-hidden="true"></i>
      <span [ngClass]="{ 'no-display': dialogContentMaxWidth < 480 }"
        >Add payment</span
      >
    </div>
  </div>
</div>

<div
  class="wizard-step-content"
  #content
  [ngStyle]="{
    'max-height': 530 + 'px',
    'overflow-y': 'auto',
    'max-width': '800px',
    width: dialogContentMaxWidth,
    'min-width': '500px'
  }"
>
  <div
    *ngIf="paymentList && paymentList.length"
    class="row d-flex justify-content-around"
  >
    <div *ngFor="let payment of paymentList" class="col-11 payment-item">
      <div class="col-md-12 row card-details">
        <div class="col-6">
          <strong>{{ payment.card.cardNumber }}</strong>
        </div>
        <div class="col-4 edit-btn-container">
          Exp:{{ payment.card.cardExpMonth }}/{{ payment.card.cardExpYear }}
        </div>
      </div>

      <div class="col-md-12 row address-item">
        <div class="col-md-4 edit-btn-container">
          <strong>{{ billingAddress.addressName }}</strong>
          <!-- <div
              *ngIf="!isAddingPayment"
              class="change-btn text-color"
              (click)="isAddingPayment = true"
              disabled>
            Change
          </div> -->
        </div>
        <div class="col-md-12">{{ billingAddress.address1 }}</div>
        <div *ngIf="country" class="col-md-12">
          {{ billingAddress.city }},
          {{ billingAddress.state | stateName : country.states }},
          {{ billingAddress.postalCode }}
        </div>
      </div>

      <div class="action-btn-block col-12 d-flex justify-content-start">
        <div class="col-md-4 col-5 payment-toggle-btn">
          <p-toggleButton
            class="select-btn"
            [(ngModel)]="payment.checked"
            onLabel="Selected"
            offLabel="Select"
            [onIcon]="'pi pi-check'"
            [offIcon]="'fa fa-square-o'"
            (onChange)="changeCheckedPayment($event.checked, payment.card)"
          >
          </p-toggleButton>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-fixed">
    <button class="cancel-btn pointer" (click)="backToPreviousStep()">
      Back
    </button>
    <button
      class="submit-btn bg-color pointer"
      (click)="finishThisStep()"
      [disabled]="form.controls['cardId'] && form.controls['cardId'].invalid"
    >
      Next
    </button>
  </div>
</div>

<p-toast></p-toast>
<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
