<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
<div *ngIf="currentUser">
  <app-account-basic-info
    (linkToPanelClicked)="expandPanel($event)"
    [user]="currentUser"
  >
  </app-account-basic-info>

  <app-contact-information
    [country]="country!"
    [currentUser]="currentUser"
    [panelCollapsed]="panelsCollapsed.contactInformation"
  >
  </app-contact-information>

  <app-order-history
    [country]="country!"
    [memberId]="currentUser?.memberId"
    [panelCollapsed]="panelsCollapsed.orderHistory"
    (panelToggled)="updatePanelCollapsed($event, 'orderHistory')"
  >
  </app-order-history>

  <app-auto-replenishment-program
    [country]="country!"
    [panelCollapsed]="panelsCollapsed.autoReplenishmentProgram"
    [user]="currentUser"
  >
  </app-auto-replenishment-program>

  <app-premier-membership-plus
    *ngIf="ocenture && ocenture.expiryDate != null"
    [country]="country!"
    [ocenture]="ocenture"
    [expired]="ocentureExpired"
    (telehealth)="telehealth()"
    (prayer)="prayer()"
    (reactivate)="reactivate()"
    (cancelSubscription)="cancelPMPsubscription()"
  ></app-premier-membership-plus>
  <app-wecare></app-wecare>
  <app-refer-a-friend
    *ngIf="referAFriendIsVisible"
    [panelCollapsed]="panelsCollapsed.referAFriend"
    [currentUser]="currentUser"
  >
  </app-refer-a-friend>
  <app-become-affiliate *ngIf="!isAffiliate"></app-become-affiliate>
  <app-create-first-store *ngIf="isAffiliate && !hasStore"></app-create-first-store>
  <app-my-referrals *ngIf="isAffiliate && hasStore"></app-my-referrals>
  <app-my-compensations *ngIf="isAffiliate && hasStore"></app-my-compensations>
  <!-- <app-password
    [panelCollapsed]="panelsCollapsed.password"
    [memberId]="currentUser.memberId"
  >
  </app-password> -->
</div>
