import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ReferralModel } from "../models/referral.model";

@Component({
  selector: "app-referrals-table",
  templateUrl: "./referrals-table.component.html",
  styleUrls: ["./referrals-table.component.css"],
})
export class ReferralsTableComponent implements OnInit, OnChanges {
  @Input() myReferrals: ReferralModel[] = [];
  @Input() showOptionalColumn: boolean = false;
  @Output() handleShowOrder = new EventEmitter();
  retailTotal = 0;
  valueTotal = 0;
  paidTotal = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.myReferrals && this.myReferrals.length) {
      this.retailTotal = this.myReferrals.reduce(
        (partialSum, x) => partialSum + x.TotalRetail,
        0
      );
      this.valueTotal = this.myReferrals.reduce(
        (partialSum, x) => partialSum + x.TotalValue,
        0
      );
      this.paidTotal = this.myReferrals.reduce(
        (partialSum, x) => partialSum + x.TotalPaid,
        0
      );
    }
  }

  ngOnInit(): void {}

  onShowClick(referral: ReferralModel) {
    this.handleShowOrder.emit(referral);
  }
}
