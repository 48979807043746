<div class="panel-wrapper">
  <p-panel
    header="Password"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"
  >
    <button
      class="submit-btn bg-color"
      type="submit"
      id="btnReset"
      (click)="onResetPasswordClick()"
    >
      Reset your Password
    </button>
  </p-panel>
</div>
