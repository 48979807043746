<app-spinner
  *ngIf="isLoading"
  [spinnerText]="'Loading...'"
  [disableFullScreen]="true"
></app-spinner>

<div class="panel-wrapper">
  <p-panel
    class="become-affiliate"
    id="becomeAffiliate"
    header="Become a Trivita Affiliate"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="panel-description">
      <div>
        <div class="form-check" style="margin: 2rem auto">
          <input
            [(ngModel)]="isTermsAccepted"
            class="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"

          />
          <label class="form-check-label" for="flexCheckDefault">
            I agree to <a href="http://trivita.cdn.s3.amazonaws.com/US/files/MyWellnessStore%20P%20and%20P.pdf">terms and conditions</a>
          </label>
        </div>

        <button
          [disabled]="!isTermsAccepted"
          (click)="becomeAffiliate()"
          class="submit-btn bg-color"
        >
          Become a Trivita Affiliate
        </button>
      </div>
    </div>
  </p-panel>
</div>
