import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, tap } from 'rxjs';
import { Configuration } from '../models/configuration.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private _config!: Configuration;

  constructor(private http: HttpClient) {}

  get config(): Configuration {
    return this._config;
  }

  load(url: string) {
    return new Promise((resolve, reject) => {
      const value = firstValueFrom(
        this.http
          .get<Configuration>(url)
          .pipe(tap((config: Configuration) => {}))
      ).then((config: Configuration) => {
        this._config = config;
        console.log(this._config);
        resolve(this._config);
      }).catch((reason: any)=> {
        reject(reason);
      })
    });
  }

  getConfiguration(): Configuration {
    return this._config;
  }
}
