import { UserService } from '../../../services/user.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  flatMap,
  map,
} from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Country } from '@models/country.model';
import { UserInfo } from '@models/user-info.model';
import { Address } from '@models/arp-order.model';
import { Patterns } from '@helpers/helper';
import { showError } from '@helpers/form-helper';
import { AddressService } from '@services/address.service';
import { ContactInfoService } from '@services/contact-info.service';
import { WizardHelperService } from '@services/wizard-helper.service';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.scss'],
})
export class ContactInformationComponent implements OnInit, OnDestroy {
  @Input() country!: Country;
  @Input() currentUser!: UserInfo;
  @Input() panelCollapsed!: boolean;

  addresses: Address[] = [];
  contactInformationEditForm!: FormGroup;
  defaultBillingAddress!: Address;
  defaultShippingAddress!: Address;
  existingEmail!: boolean;
  isEditing = false;
  phoneEditing = false;
  emailEditing = false;
  isLoading = false;
  notChangedBody: any;
  phoneMask = Patterns.phone.mask;
  saveButtonDisabled!: boolean;
  showError: Function = showError;
  spinnerText!: string;
  sub!: Subscription;
  subControl!: Subscription;
  submitted = false;
  isEditingBillingAddress = false;
  isEditingShippingAddress = false;

  phoneMaskPattern = { '0': { pattern: new RegExp(Patterns.phone.pattern) } };
  data = 12345;
  constructor(
    private addressService: AddressService,
    private contactInfoService: ContactInfoService,
    private fb: FormBuilder,
    private userService: UserService,
    private wizardHelperService: WizardHelperService,
    private messageService: MessageService,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.getAddresses();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  // cancel editing some contact info
  cancelEditing(): void {
    this.subControl?.unsubscribe();
    this.existingEmail = false;
    this.phoneEditing = false;
    this.emailEditing = false;
    this.contactInformationEditForm.controls['email'].setValue(
      this.notChangedBody.email
    );
    this.contactInformationEditForm.controls['phone'].setValue(
      this.notChangedBody.phone
    );
    this.contactInformationEditForm.markAsPristine();
    this.isEditing = false;
    this.notChangedBody = null;
  }

  // finished changing addresses
  closeEditingAddressDialog() {
    this.isEditingBillingAddress = false;
    this.isEditingShippingAddress = false;
  }

  // get all addresses from api and set them to subject
  getAddresses(): void {
    this.sub = this.wizardHelperService.addressListSubject.subscribe(
      (addressList) => {
        if (!addressList?.length) {
          this.addressService
            .getAllAddresses(this.currentUser.memberId)
            .subscribe({
              next: (response: any) => {
                const { addresses } = JSON.parse(response);
                this.wizardHelperService.setAddressList(addresses);
                this.addresses = addresses;
                this.defineDefaultAddresses();
                this.isLoading = false;
                this.spinnerText = '';
              },
              error: (error: HttpErrorResponse) => {
                if ([401, 403].includes(error.status)) {
                  this.userService.logout();
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: error.message,
                  });
                }
              },
            });
        } else {
          this.addresses = addressList;
          this.defineDefaultAddresses();
          this.isLoading = false;
          this.spinnerText = '';
        }
      }
    );
  }

  defineDefaultAddresses(): void {
    this.addresses?.map((item) => {
      if (item.isDefaultBillingAddress) {
        this.defaultBillingAddress = item;
        this.contactInformationEditForm.controls['billingAddressId'].setValue(
          this.defaultBillingAddress.addressId
        );
      }
      if (item.isDefaultShippingAddress) {
        this.defaultShippingAddress = item;
        this.contactInformationEditForm.controls['shippingAddressId'].setValue(
          this.defaultShippingAddress.addressId
        );
      }
    });
  }

  /**
   * Build model driven contactInformationEditForm form
   */
  buildForm(): void {
    this.contactInformationEditForm = this.fb.group({
      email: [
        this.currentUser.email,
        [Validators.required, Validators.pattern(Patterns.email)],
      ],
      phone: [this.currentUser.phone, [Validators.required]],
      billingAddressId: ['', Validators.required],
      shippingAddressId: ['', Validators.required],
    });
  }

  /**
   * Define type of edit address
   */
  editAddress(typeOfEditAddress: string) {
    this.isEditingBillingAddress =
      typeOfEditAddress === 'isEditingBillingAddress';
    this.isEditingShippingAddress =
      typeOfEditAddress === 'isEditingShippingAddress';
  }

  /**
   * Contact Information panel toggle handler
   * @param event - dom event object
   */
  onPanelToggle(event: any): void {
    if (!event.collapsed) {
    }
  }

  // send updated info to service
  onSubmit(): void {
    this.submitted = true;
    if (
      this.contactInformationEditForm.valid &&
      this.contactInformationEditForm.dirty &&
      !this.existingEmail
    ) {
      this.isLoading = true;
      this.spinnerText = 'Processing your request...';
      this.contactInfoService
        .updateContactInfo(this.contactInformationEditForm.value)
        .subscribe({
          next: (response: any) => {
            console.log(response);
            this.currentUser.email = response.email;
            this.currentUser.phone = response.phone;
            this.subControl?.unsubscribe();
            this.contactInformationEditForm.controls['email'].setValue(
              this.currentUser.email
            );
            this.contactInformationEditForm.controls['email'].setValue(
              this.currentUser.email
            );
            this.userService.setCurrentUser(this.currentUser);
            this.isLoading = false;
            this.spinnerText = '';
            this.submitted = false;
            this.isEditing = false;
            this.phoneEditing = false;
            this.emailEditing = false;
            this.existingEmail = false;
            this.saveButtonDisabled = false;
            this.contactInformationEditForm.markAsPristine();
            this.messageService.add({
              severity: 'success',
              summary: 'Contact info was changed',
            });
          },
          error: (error: HttpErrorResponse) => {
            this.messageService.add({
              severity: 'error',
              summary: error.message,
            });
            this.isLoading = false;
            this.submitted = false;
            this.spinnerText = '';
          },
        });
    }
  }

  // start editing some contact info
  startEditing(): void {
    this.notChangedBody = this.contactInformationEditForm.value;
    this.isEditing = true;
    // Validation to check if email already exists in database
    // this.subControl = this.contactInformationEditForm.controls[
    //   'email'
    // ].valueChanges
    //   .pipe(
    //     map((value) => {
    //       this.saveButtonDisabled = true;
    //       return value;
    //     }),
    //     debounceTime(500), // Pause for 500ms
    //     distinctUntilChanged(), // Only if the value has changed
    //     filter(
    //       (value) => this.contactInformationEditForm.controls['email'].valid
    //     ),
    //     flatMap((value) =>
    //       this.contactInfoService.checkExistingEmail(
    //         this.contactInformationEditForm.value.email
    //       )
    //     )
    //   )
    //   .subscribe((response) => {
    //     this.existingEmail = response.existingCustomer;
    //     this.saveButtonDisabled = false;
    //   });
  }

  /**
   * Sets pattern validator for phone field when users types in this field
   * @param {string} controlName
   */
  setPhonePatternValidator(controlName: string): void {
    this.contactInformationEditForm.controls[controlName].setValidators(
      Validators.pattern(Patterns.phone.pattern)
    );
  }

  public showNotification({ severity, message }: any): void {
    this.messageService.add({ severity, summary: message });
  }
}
