<div class="container">
  <h3 class="telehealth-title">Family Members</h3>

  <div class="row w-100 d-flex justify-content-end mb-2">
    <div
      class="add-btn col-12 text-color pointer text-right"
      (click)="onAddSecondaryClick()"
    >
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      Add a Member
    </div>
  </div>
  <table class="table">
    <thead>
      <tr>
        <th>User Id</th>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Added on</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody *ngIf="secondaryAccounts && secondaryAccounts.length; else noData">
      <tr *ngFor="let account of secondaryAccounts">
        <td>{{ account.CustID }}</td>
        <td>{{ account.CustFName }} {{ account.CustLName }}</td>
        <td>{{ account.EmailAdd }}</td>
        <td>{{ account.Phone }}</td>
        <td>{{ account.FlagDate | date }}</td>
        <td>
          <i
            (click)="onSecondaryRemove(account.CustID)"
            class="fa fa-trash"
            aria-hidden="true"
          >
            Remove</i
          >
        </td>
      </tr>
    </tbody>

    <ng-template #noData>
      <tbody>
        <tr>
          <td colspan="5" rowspan="2" style="text-align: center; padding: 20px">
            No account added yet
          </td>
        </tr>
      </tbody>
    </ng-template>
  </table>
</div>

<p-confirmDialog
  [style]="{ width: '30vw' }"
  [position]="'top'"
  [baseZIndex]="10000"
></p-confirmDialog>

<div class="modal-wrapper bg-white">
  <p-dialog
    [(visible)]="showAddEditModal"
    [modal]="true"
    [resizable]="false"
    [responsive]="true"
    [draggable]="false"
    [blockScroll]="true"
    [style]="{ width: '50vw' }"
    header="Header"
  >
    <ng-template pTemplate="header">
      <div class="row w-100">
        <div class="col-12 col-sm-7 no-padding">
          <span> Add a member </span>
        </div>
      </div>
    </ng-template>

    <!-- <p-header>
      <div class="row w-100">
        <div class="col-12 col-sm-7 no-padding">
          <span> Add a member </span>
        </div>
      </div>
    </p-header> -->
    <app-add-member-form
      [country]="country"
      (onCancel)="onCancel()"
      (onSubmitForm)="onSubmit($event)"
    ></app-add-member-form>
  </p-dialog>
</div>

<app-spinner *ngIf="isLoading" [disableFullScreen]="true"></app-spinner>
