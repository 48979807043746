import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ReferralOrderModel } from "../models/referral.model";

@Component({
  selector: "app-orders-table",
  templateUrl: "./orders-table.component.html",
  styleUrls: ["./orders-table.component.css"],
})
export class OrdersTableComponent implements OnInit, OnChanges {
  @Input() referralOrders!: ReferralOrderModel[];
  @Output() handleOrderClick = new EventEmitter();

  retailTotal = 0;
  valueTotal = 0;
  paidTotal = 0;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.referralOrders && this.referralOrders.length) {
      this.retailTotal = this.referralOrders.reduce(
        (partialSum, x) => partialSum + x.Retail,
        0
      );
      this.valueTotal = this.referralOrders.reduce(
        (partialSum, x) => partialSum + x.Value,
        0
      );
      this.paidTotal = this.referralOrders.reduce(
        (partialSum, x) => partialSum + x.Paid,
        0
      );
    }
  }

  ngOnInit(): void {}

  onShowDetail(referralOrder: ReferralOrderModel) {
    this.handleOrderClick.emit(referralOrder);
  }
}
