<div class="container">
  <div
    *ngIf="mode === 'view' && order || mode === 'edit' && form && order?.orderId && form.value.orderId !== order?.orderId">

    <div class="order-products-list">
      <div class="order-products-list__header">PRODUCTS</div>
      <div class="order-products-list__header">PRICE</div>
      <div class="order-products-list__header">QUANTITY</div>
      <div class="order-products-list__header">TOTAL</div>

      <ng-container *ngFor="let product of order?.products">
        <div class="order-products-list__product">
          <img class="order-products-list__product-image"
               [src]="product.cartImage">
          <div class="order-products-list__desc">
            <div>ITEM#{{product.itemNumber}}</div>
            <div>{{product.productName}}</div>
          </div>
        </div>
        <div class="order-products-list__product-price">
          {{product.price | currency}}
        </div>
        <div class="order-products-list__product-quantity">
          {{product.quantity}}
        </div>
        <div class="order-products-list__product-total">
          {{product.price * product.quantity | currency}}
        </div>
      </ng-container>
    </div>

    <div class="subtotal-info">
      <div class="col-12 d-flex row justify-content-end">
        <div class="col-lg-8 col-12 row summary-block d-flex justify-content-end">
          <div class="col-sm-2 col-6">
            <div>Subtotal:</div>
            <div class="subtotal-number">{{ subtotal | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-4 col-6">
            <div>Estimated Shipping:</div>
            <div class="subtotal-number">{{ order?.estimatedShipping | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-3 col-6">
            <div>Estimated Tax:</div>
            <div class="subtotal-number">{{ order?.estimatedTax | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-3 col-6">
            <div>Estimated Total:</div>
            <div class="subtotal-number">{{ estimatedTotal | currency:'USD':true }}</div>
          </div>
          <div class="col">
            <div>Note: Monthly special will be automatically applied to your order</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="form && (!order?.orderId || form.value.orderId === order?.orderId)"
     class="container">
  <div [formGroup]="form">
    <div class="order-products-list-edit" formArrayName="products">
      <div class="order-products-list-edit__header">PRODUCTS</div>
      <div class="order-products-list-edit__header">PRICE</div>
      <div class="order-products-list-edit__header">QUANTITY</div>
      <div class="order-products-list-edit__header order-products-list-edit__total">TOTAL</div>

      <div *ngIf="mode === 'edit' && form && order?.orderId && form.value.orderId === order?.orderId"
           class="order-products-list-edit__addProduct text-color pointer" (click)="onAddProductClick.emit(order!)">
        <i class="fa fa-plus-circle" aria-hidden="true"></i>
        Add Product
      </div>

      <ng-container *ngFor="let product of getProductControls(); let i = index">
        <div class="order-products-list-edit__product">
          <img class="order-products-list-edit__product-image"
               [src]="product.value.cartImage">
          <div class="order-products-list-edit__desc">
            <div>ITEM#{{product.value.itemNumber}}</div>
            <div>{{product.value.productName}}</div>
          </div>
        </div>
        <div class="order-products-list-edit__product-price">
          {{product.value.price | currency}}
        </div>
        <div class="order-products-list-edit__product-quantity">
          <div [formGroupName]="i">
            <input
              *ngIf="wizardStepName != 'reviewYourOrderStep' || mode === 'edit'"
              class="qty-input"
              formControlName="quantity"
              type="number"
              min="1"
            />
            <div *ngIf="wizardStepName === 'reviewYourOrderStep' && mode === 'view'">
              {{ form.value.products[i].quantity }}
            </div>
          </div>
        </div>
        <div class="order-products-list-edit__product-total">
          {{product.value.price * product.value.quantity | currency}}
        </div>
        <div *ngIf="product.value.canSkip"
             class="order-products-list-edit__skip pointer text-color"
          (click)="skipOnNextOrder(product.value.productId, i, order?.orderId!)">
          Skip On Next Order
        </div>
        <div *ngIf="!product.value.canSkip"
             class="order-products-list-edit__skip disabled text-color" [pTooltip]="product.value.canSkipError"
             tooltipPosition="bottom">
          Skip On Next Order
        </div>
        <div class="order-products-list-edit__remove-product pointer" (click)="removeProduct(product.value, i)">
          <i class="fa fa-times fa-lg"></i>
        </div>
      </ng-container>
    </div>

    <div *ngIf='subtotal' class="subtotal-info">
      <div class="col-12 d-flex row w-100 justify-content-end">
        <div class="col-lg-8 col-12 row w-100 summary-block d-flex justify-content-end">
          <div class="col-sm-2 col-6 text-right">
            <div>Subtotal:</div>
            <div class="subtotal-number">{{ subtotal | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-4 col-6" *ngIf="mode !== 'add'">
            <div>Estimated Shipping:</div>
            <div *ngIf="order && order.estimatedShipping"
                 class="subtotal-number">{{ order.estimatedShipping | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-3 col-6" *ngIf="mode !== 'add'">
            <div>Estimated Tax:</div>
            <div *ngIf="order && order.estimatedTax"
                 class="subtotal-number">{{ order.estimatedTax | currency:'USD':true }}</div>
          </div>
          <div class="col-sm-3 col-6" *ngIf="mode !== 'add'">
            <div>Estimated Total:</div>
            <div *ngIf="order && order.estimatedTax && order.estimatedShipping"
                 class="subtotal-number">{{ estimatedTotal | currency:'USD':true }}</div>
          </div>
          <div *ngIf="order && order.estimatedTax && mode !== 'add'" class="col">
            <div>Note: Monthly special will be automatically applied to your order</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
<p-toast></p-toast>
