<div *ngIf="orderList" class="container">
  <div class="row w-100 d-flex justify-content-end">
    <div class="add-btn col-12 text-color pointer text-right" (click)="onAddOrderClick()">
      <i class="fa fa-plus-circle" aria-hidden="true"></i>
      Add Scheduled Order
    </div>
  </div>
  <div *ngFor='let orderItem of orderList' class="row w-100 order-item">
    <div class="panel-table-title row d-flex">
      <div class="col-6 col-sm-4 col-lg-6 text-left">Order ID: {{ orderItem.orderId }}</div>
      <div *ngIf="orderItem.canSkip" class="col-12 col-sm-4 col-lg-3 pointer text-right">
        <div class='text-color' (click)="isSkippingConfirmationShow(orderItem.orderId)">Skip Next Order</div>
      </div>
      <div *ngIf="!orderItem.canSkip" class="col-12 col-sm-4 col-lg-3 pointer text-right">
        <div
          class='disabled text-color'
          [pTooltip]="orderItem.canSkipError"
          tooltipPosition="bottom">
          Skip Next Order
        </div>
      </div>
      <div class="col-12 col-sm-4 col-lg-3 pointer text-right">
        <div class='text-color' (click)="isCancelingConfirmationShow(orderItem.orderId)">Cancel Order</div>
      </div>
    </div>
    <!-- Personal info section -->
    <app-arp-order-personal-info
      [country]="country"
      [order]="orderItem"
      [user]="user"
      (onOpenManagementAddress)="openAddressManagement($event)"
      (onOpenManagementPayment)="openPaymentManagement($event)"
    ></app-arp-order-personal-info>
    <div *ngIf="isCreditCardError" class="credit-error-message">
      There was an error processing the credit card provided. Please update and try again.
    </div>

    <!-- View/Edit order options (configuration) -->
    <app-arp-order-option
      class="col-12"
      [form]="editArpOrderForm!"
      [order]="orderItem"
      (onEditOrderClick)="openEditOrderOptions($event)"
    ></app-arp-order-option>

    <!-- View/Edit/Add order products list -->
    <app-arp-order-products
      class="col-12"
      [memberId]="user.memberId"
      [form]="editArpOrderForm!"
      [order]="orderItem"
      [wizardStepName]="null"
      [mode]="productsListMode"
      (onAddProductClick)="openProductManagement($event)"
    ></app-arp-order-products>

    <div class="edit-mode-buttons-wrapper"
         *ngIf="editArpOrderForm && orderItem &&  editArpOrderForm.value.orderId === orderItem.orderId">
      <button class="cancel-btn pointer" (click)="resetArpOrderEditing()">Cancel</button>
      <button class="submit-btn bg-color pointer" (click)="saveArpOrder()">Save</button>
    </div>
  </div>
</div>

<!-- Order Addresses editing dialog -->
<!-- TODO: rename component or remove commment -->
<app-arp-order-editing
  *ngIf="orderEditingDialogIsOpen"
  [country]="country"
  [user]="user"
  [form]="editArpOrderForm!"
  [visible]="orderEditingDialogIsOpen"
  [editableOrder]="editableOrder"
  [typeOfAddress]="typeOfAddress!"
  [isProductManagement]="isProductManagement"
  [isPaymentManagement]="isPaymentManagement"
  (onCloseEditingDialog)="onCloseDialog()"
  (onProductListUpdated)="onProductListApply($event)"
  (onShowNotification)="showNotification($event)"
></app-arp-order-editing>

<!-- Confirmation dialog -->
<p-dialog
  header="Confirmation"
  [blockScroll]="true"
  [visible]="isSkippingConfirmation || isCancelingConfirmation"
  [modal]=true
  width="500"
  [responsive]="true"
  (onHide)="closeConfirmationDialog()"
  (visibleChange)="closeConfirmationDialog()">
  <p *ngIf="isSkippingConfirmation"> Are you sure you would like to skip your next order? </p>
  <p *ngIf="isCancelingConfirmation"> Are you sure you would like to cancel your order? </p>
  <p-footer>
    <button
      *ngIf="isCancelingConfirmation"
      class="dialog-btn submit-btn bg-color pointer"
      type="button"
      (click)="cancelOrder()">
      Confirm
    </button>
    <button
      *ngIf="isSkippingConfirmation"
      class="dialog-btn submit-btn bg-color pointer"
      type="button"
      (click)="skipNextOrder()">
      Confirm
    </button>
    &nbsp;
    &nbsp;
    <button
      class="dialog-btn cancel-btn pointer"
      type="button"
      (click)="closeConfirmationDialog()">
      Cancel
    </button>
  </p-footer>
</p-dialog>

<app-spinner
  *ngIf="isLoading"
  [spinnerText]="spinnerText"
  [disableFullScreen]="true"
></app-spinner>
<p-toast></p-toast>
