import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";
import { OcentureSecondaryModel } from "@models/ocenture-secondary.model";
import { OcentureSecondaryAddParamsModel } from "@models/ocenture-secondary-add-params.model";

@Injectable({ providedIn: "root" })
export class OcentureService {
  constructor(
    private httpService: HttpService,
    private authService: UserService
  ) {}

  getAllSecondaryAccounts(): Observable<OcentureSecondaryModel[]> {
    return this.httpService.post(
      "/PMP/GetSecondaries",
      "trivita_ocenture",
      { CustID: this.authService.user?.memberId },
      this.authService.user.token
    );
  }
  addSecondaryAccount(payload: OcentureSecondaryAddParamsModel){
    return this.httpService.post(
      "/PMP/AddFamilyMember",
      "trivita_ocenture",
      payload,
      this.authService.user.token
    );
  }
  removeSecondaryAccount(CustID: string): Observable<any> {
    return this.httpService.post(
      "/PMP/RemoveFamilyMember",
      "trivita_ocenture",
      { CustID },
      this.authService.user.token
    );
  }
}
