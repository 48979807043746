import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { DashboardInfo } from '@models/dashboard.model';
import { UserInfo } from '@models/user-info.model';
import { AffiliateService } from '@services/affiliate.service';
import { UserService } from '@services/user.service';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-affiliate-basic-info',
  templateUrl: './affiliate-basic-info.component.html',
  styleUrls: ['./affiliate-basic-info.component.scss'],
})
export class AffiliateBasicInfoComponent implements OnInit, OnDestroy {
  @Input() currentUser!: UserInfo;

  public dashboardInfo!: DashboardInfo;
  public isLoading = false;

  private destroy$ = new Subject<void>();

  constructor(
    private affiliateService: AffiliateService,
    private userService: UserService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getDashboardInfo();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Get dashboard information from service
  private getDashboardInfo(): void {
    this.isLoading = true;
    this.affiliateService
      .getDashboardInfo(this.currentUser.memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dashboardInfo: DashboardInfo) => {
          this.dashboardInfo = dashboardInfo;
          this.isLoading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          if ([401, 403].includes(error.status)) {
            this.userService.logout();
          } else {
            this.messageService.add({
              severity: 'error',
              summary: error.message,
            });
          }
        },
      });
  }
}
