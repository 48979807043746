import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { UserInfo } from '@models/user-info.model';
import { UserService } from '@services/user.service';
import { ConfigService } from '@services/config.service';
import { IdleService } from '@services/idle.service';
import { ProfileService } from '@services/profile.service';
import { Subscription } from 'rxjs';

interface ITabPanel {
  header: string;
  route: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  currentRouteUrl!: string;
  currentUser!: UserInfo;
  iframeOrigin: string;
  isLoading!: boolean;
  isLogoutClick!: boolean;
  isAffiliate!: boolean;
  tabPanels: ITabPanel[] = [
    {
      header: "Account Managment",
      route: "main/account-management",
    },
    {
      header: "Affiliate Office",
      route: "main/affiliate-office",
    },
  ];
  spinnerText = "Processing your request...";
  sub!: Subscription;

  constructor(
    private configService: ConfigService,
    private idleService: IdleService,
    private profileService: ProfileService,
    private router: Router,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
    private authService: UserService
  ) {
    this.iframeOrigin = this.configService.getConfiguration().ssoDomain;
  }

  ngOnInit() {
    this.idleService.reset();
    this.getProfileInfo();
    this.currentRouteUrl = this.router.url.substring(1);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  /**
   * Defined affiliate user
   */
  getProfileInfo(): void {
    this.currentUser = this.authService.getCurrentUser();
    this.isAffiliate = this.authService.isAffiliate();
  }



  // redirect to clicked page
  redirectToUrl(event: any) {
    this.router.navigate([this.tabPanels[event.index].route]);
  }

  /**
   Allows to scroll pages to top after their refreshing
   */
  onRouterDeactivate(): void {
    this.renderer.setProperty(document.body, "scrollTop", 0);
  }
}
