import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import { Country } from '@models/country.model';
import { UserService } from '@services/user.service';

@Injectable()
export class CountryService {

  constructor(private httpService: HttpService, private userService: UserService) {
  }

  getCountriesWithStates(): Observable<Country[]> {
    return this.httpService.get(`postalhelper/GetStatesAndCountries`, 'global', this.userService.user.token).pipe(map((response: any) => {
      /**
       * Add label property for primeng dropdowns
       */
      let responseParsed = JSON.parse(response);
      return responseParsed.countries.map((country: any) => {
        country['label'] = country.displayName;
        country.states = country.states.map((state: any) => {
          state['label'] = state.displayName;
          return state;
        });
        return country;
      });
    }));
  }
}
