import { Injectable } from '@angular/core';
import { Address } from '@models/address.model';
import { ArpOrder, Payment, Product } from '@models/arp-order.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class WizardHelperService {
  private _addressEditing!: Address;
  private _removedProductSubject: Subject<Product> = new Subject();
  private _addressListSubject: BehaviorSubject<Address[]> = new BehaviorSubject<Address[]>([]);
  private _cardListSubject: BehaviorSubject<Payment[]> = new BehaviorSubject<Payment[]>([]);
  private _orderListSubject: BehaviorSubject<ArpOrder[]> = new BehaviorSubject<ArpOrder[]>([]);

  constructor() {}

  // to updating select product list in order
  public setDeletedProductFromSelectList(product: Product): void {
    this._removedProductSubject.next(product);
  }

  get removedProductSubject(): Subject<Product> {
    return this._removedProductSubject;
  }

  // to updating address list
  public setAddressList(addresses: Address[]): void {
    if(addresses && addresses.length)
      this._addressListSubject.next(addresses);
  }

  get addressListSubject(): Subject<Address[]> {
    return this._addressListSubject;
  }

  // to updating card list
  public setCardList(cardList: Payment[]): void {
    this._cardListSubject.next(cardList);
  }

  get cardListSubject(): Subject<Payment[]> {
    return this._cardListSubject;
  }

  // to updating order list
  public setOrderList(orders: ArpOrder[]): void {
    this._orderListSubject.next(orders);
  }

  get orderListSubject(): Subject<ArpOrder[]> {
    return this._orderListSubject;
  }

  // to controlling address editing
  public setAddressEditing(address?: Address): void {
    if(address)
      this._addressEditing = JSON.parse(JSON.stringify(address));
  }

  get addressEditing(): Address {
    return this._addressEditing;
  }
}
