<div class="panel-wrapper">
  <p-panel
    header="Payment Method"
    [collapsed]="panelCollapsed"
    [toggleable]="true"
    [toggler]="'header'"

  >
    <div class="container">
      <div *ngIf="!isEditing" class="row w-100">
        <div class="col-md-4 col-sm-12 row margin-bottom-30 align-content-start">
          <p class="col-12 panel-content-title">Credit Card</p>
          <p class="col-12 panel-content-value">Visa</p>
          <p class="col-12 panel-content-value">**98090</p>
        </div>

        <div class="col-md-4 col-sm-12 row margin-bottom-30 align-content-start">
          <p class="col-12 panel-content-title">Expiry date</p>
          <p class="col-12 panel-content-value">08/17</p>
        </div>

        <div class="col-md-4 col-sm-12 row margin-bottom-30 align-content-start">
          <p class="col-12 panel-content-title">Billing Address</p>
          <p class="col-12 panel-content-value"> {{ currentUser.billingAddress?.address1 }} </p>
          <p class="col-12 panel-content-value"> {{ currentUser.billingAddress?.city }} </p>
          <p class="col-12 panel-content-value"> {{ currentUser.billingAddress?.countryId}} </p>
        </div>
      </div>
      <div *ngIf="!isEditing" class="edit-btn text-color" (click)="isEditing = true">Edit</div>
    </div>

    <!-- <div *ngIf="!isAddingPayment" class="change-btn text-color" (click)="isAddingPayment = true">Change</div> -->

    <!-- Edit payments info -->
    <div *ngIf="paymentMethodEditForm && isEditing" class="form-container">
      <p class="edit-mode-text text-color">Edit mode. Please click save button to apply changes.</p>
      <form class="container" [formGroup]="paymentMethodEditForm" (ngSubmit)="onSubmit()">
        <div class="row w-100">
          <div class="col-md-3 col-lg-3 col-sm-12 margin-bottom-30"
               [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'creditCardType', submitted)}">
            <label class="col-12 panel-content-title">Credit Card</label>
            <p-dropdown
              placeholder="Type Of Card"
              [options]="creditCardTypesOptions"
              formControlName="creditCardType">
            </p-dropdown>
            <p *ngIf="showError(paymentMethodEditForm, 'creditCardType', submitted)">Please enter Type Of Card.</p>
          </div>
          <div class="col-md-3 col-sm-12 margin-bottom-30"
               [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'creditCardNumber', submitted)}">
            <label class="col-12 panel-content-title">Number Of Credit Card</label>
            <input
              class="col-12"
              type='text'
              placeholder='Number Of Credit Card'
              formControlName="creditCardNumber"/>
            <p *ngIf="showError(paymentMethodEditForm, 'creditCardNumber', submitted)">Please enter Number Of Credit
              Card.</p>
          </div>
          <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 margin-bottom-30"
               [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'creditCardExpiryDay', submitted)}">
            <label class="col-12 panel-content-title">Expiry Day</label>
            <input
              class="col-12"
              type='text'
              placeholder='Expiry Day'
              formControlName="creditCardExpiryDay"/>
            <p *ngIf="showError(paymentMethodEditForm, 'creditCardExpiryDay', submitted)">Please enter Expiry Day.</p>
          </div>

          <div class="col-md-3 col-sm-12">
            <div class='margin-bottom-30'
                 [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'address1', submitted)}">
              <label class="col-12 panel-content-title">Billing Address</label>
              <input
                class="col-12"
                type='text'
                placeholder='Address'
                formControlName="address1"/>
              <p *ngIf="showError(paymentMethodEditForm, 'address1', submitted)">Please enter Address.</p>
            </div>
            <div class='margin-bottom-30'
                 [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'city', submitted)}">
              <label class="col-12 panel-content-title">City</label>
              <input
                class="col-12"
                type='text'
                placeholder='City'
                formControlName="city"/>
              <p *ngIf="showError(paymentMethodEditForm, 'city', submitted)">Please enter City.</p>
            </div>
            <div class='margin-bottom-30'
                 [ngClass]="{ 'wrong-input': showError(paymentMethodEditForm, 'state', submitted)}">
              <label class="col-12 panel-content-title">State</label>
              <input
                class="col-12"
                type='text'
                placeholder='State'
                formControlName="state"/>
              <p *ngIf="showError(paymentMethodEditForm, 'state', submitted)">Please enter State.</p>
            </div>
          </div>
        </div>

        <div class="row w-100 justify-content-end">
          <div class="margin-bottom-30 col-md-3 col-lg-3 col-sm-12 col-xs-12">
            <button class="submit-btn bg-color" type='submit'>Save</button>
          </div>
          <div class="margin-bottom-30 col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
            <button class="cancel-btn" type='button' (click)="isEditing = false">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </p-panel>
</div>
