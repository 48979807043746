import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-embed-video-code-dialog',
  templateUrl: './embed-video-code-dialog.component.html',
  styleUrls: ['./embed-video-code-dialog.component.css']
})
export class EmbedVideoCodeDialogComponent {
  @Input() embedCodeDialogIsVisible!: boolean;
  @Input() embedVideoCode!: string;
  @Output() onDialogHide = new EventEmitter();

  constructor() {
  }
}
