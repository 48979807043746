<div
  class='container address-list-content'
  #content
  [ngStyle]="{ 'overflow-y': 'auto', 'max-width': '610px', 'width': dialogContentMaxWidth }">

  <app-add-edit-address
    [country]="country"
    [currentAddress]="editableAddress"
    typeOfAddress="billing"
    [isEditingOrder]="false"
    (onEndAddEditAddress)="endAddEditAddress()">
  </app-add-edit-address>

</div>
